import { DateTime, Duration } from 'luxon';
import * as React from 'react';

import { makeStylesHook } from 'navigader/styles';
import { CAISORate, DateTuple, MonthIndex } from 'navigader/types';
import { IntervalData } from 'navigader/util';

import { Alert } from '../../Alert';
import { Card } from '../../Card';
import { Typography } from '../../Typography';
import { ChartControls, ChartView, TimeDomainOption } from './ChartControls';
import { GHGCharts, ProcurementCharts } from './Charts';
import { IntervalDataTuple, IntervalGraph } from './IntervalGraph';

/** ============================ Types ===================================== */
type CurveProps = {
  preSimulationCurve: IntervalData;
  postSimulationCurve: IntervalData;
};

type CommonProps = Partial<{
  procurementRateId: CAISORate['id'];
  title: string;
}>;

type IntervalWidgetProps = CommonProps & Partial<CurveProps>;
type IntervalWidgetInnerProps = CommonProps & CurveProps & { selectableMonths: MonthIndex[] };

export type TimeDomainCallback = (domain: DateTuple) => void;
type ChartsProps = IntervalWidgetInnerProps & {
  chartView: ChartView;
  month: MonthIndex;
  setTimeDomain: TimeDomainCallback;
  timeDomain?: DateTuple;
};

/** ============================ Styles ==================================== */
const useNoDataAlertStyles = makeStylesHook(
  (theme) => ({ alert: { marginTop: theme.spacing(1.5) } }),
  'NoDataAlert'
);

const useIntervalChartStyles = makeStylesHook(
  (theme) => ({
    chartsContainer: { marginTop: theme.spacing(2) },
    loadGraphCard: {
      // Height of the circular progress component plus 5px padding
      minHeight: 50,
      overflow: 'visible',
      position: 'relative',
    },
  }),
  'IntervalChart'
);

/** ============================ Components ================================ */
export const IntervalWidget: React.FC<IntervalWidgetProps> = (props) => {
  const { postSimulationCurve, preSimulationCurve, ...rest } = props;
  const selectableMonths = React.useMemo(
    () => preSimulationCurve?.months ?? [],
    [preSimulationCurve]
  );

  if (!preSimulationCurve || !postSimulationCurve) return null;
  return (
    <IntervalWidgetInner
      postSimulationCurve={postSimulationCurve}
      preSimulationCurve={preSimulationCurve}
      selectableMonths={selectableMonths}
      {...rest}
    />
  );
};

const IntervalWidgetInner: React.FC<IntervalWidgetInnerProps> = (props) => {
  const { preSimulationCurve, selectableMonths, title } = props;
  const classes = useIntervalChartStyles();

  // State
  const [chartView, setChartView] = React.useState<ChartView>('usage');
  const [selectedMonth, setMonth] = React.useState<MonthIndex>(selectableMonths[0]);
  const [timeDomainOption, setTimeDomainOption] = React.useState<TimeDomainOption>('1m');
  const [timeDomain, setTimeDomain] = React.useState<DateTuple>();

  return (
    <div>
      {title && (
        <Typography useDiv variant="h6">
          {title}
        </Typography>
      )}
      <Card className={classes.loadGraphCard} raised>
        <ChartControls
          chartView={chartView}
          selectableMonths={selectableMonths}
          selectedMonth={selectedMonth}
          timeDomainOption={timeDomainOption}
          updateChartView={setChartView}
          updateMonth={handleMonthChange}
          updateTimeDomain={handleTimeDomainChange}
        />

        <div className={classes.chartsContainer}>
          <Charts
            chartView={chartView}
            month={selectedMonth}
            timeDomain={timeDomain}
            setTimeDomain={setTimeDomain}
            {...props}
          />
        </div>
      </Card>
    </div>
  );

  /** ========================== Callbacks ================================= */
  /**
   * Called when the month selector changes. This changes the selected month and resets the time
   * domain to a month
   *
   * @param {MonthIndex} month: the month now being shown
   */
  function handleMonthChange(month: MonthIndex) {
    setMonth(month);
    handleTimeDomainChange('1m', month);
  }

  /**
   * Called when the time domain selector changes. This changes the active time domain by setting
   * the domain start to the start of the given month, and the domain end to the appropriate
   * distance ahead of the start
   *
   * @param {TimeDomainOption} timeDomainOption: the timespan to set the domain to
   * @param {MonthIndex} month: the month to start at
   */
  function handleTimeDomainChange(
    timeDomainOption: TimeDomainOption,
    month: MonthIndex = selectedMonth
  ) {
    setTimeDomainOption(timeDomainOption);
    const monthStart = preSimulationCurve?.startOfMonth(month);
    if (!monthStart) return;

    const duration = (() => {
      switch (timeDomainOption) {
        case '1d':
          return Duration.fromObject({ days: 1 });
        case '2d':
          return Duration.fromObject({ days: 2 });
        case '1w':
          return Duration.fromObject({ week: 1 });
        case '1m':
          return Duration.fromObject({ month: 1 });
      }
    })();

    // Update the state variable
    setTimeDomain([monthStart, DateTime.fromJSDate(monthStart).plus(duration).toJSDate()]);
  }
};

const Charts: React.FC<ChartsProps> = (props) => {
  const {
    chartView,
    month,
    postSimulationCurve,
    preSimulationCurve,
    procurementRateId,
    setTimeDomain,
    timeDomain,
  } = props;

  switch (chartView) {
    case 'usage':
      return (
        <IntervalGraph
          axisLabel="Customer load"
          month={month}
          onTimeDomainChange={setTimeDomain}
          timeDomain={timeDomain}
          {...scaleLoadData([
            preSimulationCurve.rename('Initial load'),
            postSimulationCurve.rename('Simulated load'),
          ])}
        />
      );
    case 'ghg': {
      return (
        <GHGCharts
          preSimulationCurve={preSimulationCurve}
          postSimulationCurve={postSimulationCurve}
          selectedMonth={month}
          timeDomain={timeDomain}
          updateTimeDomain={setTimeDomain}
        />
      );
    }
    case 'procurement': {
      if (!procurementRateId) return <NoDataAlert cost_fn_type="procurement rate" />;
      return (
        <ProcurementCharts
          costFunctionId={procurementRateId}
          preSimulationCurve={preSimulationCurve}
          postSimulationCurve={postSimulationCurve}
          selectedMonth={month}
          timeDomain={timeDomain}
          updateTimeDomain={setTimeDomain}
        />
      );
    }
  }
};

const NoDataAlert: React.FC<{ cost_fn_type: string }> = ({ cost_fn_type }) => {
  const classes = useNoDataAlertStyles();
  return (
    <Alert className={classes.alert} type="warning">
      No {cost_fn_type} was selected for this scenario.
    </Alert>
  );
};

/** ============================ Helpers =================================== */
/**
 * Scales the data to show in kW, MW or GW depending on the extent of the interval's power values
 *
 * @param {IntervalDataTuple} intervals: the pre-DER and post-DER load interval data
 */
function scaleLoadData(intervals: IntervalDataTuple) {
  const [min, max] = intervals.reduce(
    ([curMin, curMax], interval) => {
      const [minInterval, maxInterval] = interval.valueDomain;
      return [Math.min(curMin, minInterval), Math.max(curMax, maxInterval)];
    },
    [Infinity, -Infinity]
  );

  const magnitude = Math.log10(Math.max(Math.abs(min), Math.abs(max)));
  const [divisor, units] = magnitude >= 6 ? [1e6, 'GW'] : magnitude >= 3 ? [1e3, 'MW'] : [1, 'kW'];

  return {
    data: intervals.map((interval) => interval.divide(divisor)) as IntervalDataTuple,
    units,
  };
}
