import * as React from 'react';
import { List } from './List';

import { Typography } from './Typography';

/** ============================ Types ===================================== */
type ValueType = number | string | React.ReactElement;
type StatisticProps = {
  className?: string;
  title?: React.ReactNode;
  value: ValueType | ValueType[];
};

/** ============================ Components ===================================== */
export const Statistic: React.FC<StatisticProps> = (props) => {
  const { className, title, value } = props;

  return (
    <div className={className}>
      <Typography emphasis="bold" useDiv variant="body2">
        {title}
      </Typography>

      {Array.isArray(value) ? (
        <List dense>
          {value.map((v, idx) => (
            <List.Item key={idx} button={false}>
              {v}
            </List.Item>
          ))}
        </List>
      ) : (
        <Typography useDiv variant="body2">
          {value}
        </Typography>
      )}
    </div>
  );
};
