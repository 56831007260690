import * as React from 'react';

import { GHGRate } from 'navigader/models';
import { DateTuple, MonthIndex } from 'navigader/types';
import { IntervalData } from 'navigader/util';
import { useGhgRate, useCAISORate } from 'navigader/util/hooks';

import { IntervalGraph } from './IntervalGraph';
import { TimeDomainCallback } from './IntervalWidget';
import { LoadingModal } from './LoadingModal';

/** ============================ Types ====================================== */
type ProcurementChartProps = ChartProps & { costFunctionId: number };
type ChartProps = {
  preSimulationCurve: IntervalData;
  postSimulationCurve: IntervalData;
  selectedMonth: MonthIndex;
  timeDomain?: DateTuple;
  updateTimeDomain: TimeDomainCallback;
};

/** ============================ Components ================================ */
export const GHGCharts: React.FC<ChartProps> = (props) => {
  const { preSimulationCurve, postSimulationCurve, selectedMonth, timeDomain, updateTimeDomain } =
    props;

  const rateData = useGhgRate({ year: GHGRate.DEFAULT_CSP_YEAR })?.data;
  return (
    <>
      <LoadingModal loading={!rateData} />
      {rateData && (
        <>
          <IntervalGraph
            axisLabel="GHG Emissions"
            data={[
              preSimulationCurve.multiply288(rateData).rename('Initial GHG emissions'),
              postSimulationCurve.multiply288(rateData).rename('Simulated GHG emissions'),
            ]}
            month={selectedMonth}
            timeDomain={timeDomain}
            onTimeDomainChange={updateTimeDomain}
            units="tCO2"
          />
          <IntervalGraph
            height={100}
            hideXAxis
            month={selectedMonth}
            timeDomain={timeDomain}
            onTimeDomainChange={updateTimeDomain}
            {...scaleInvertedData(preSimulationCurve.align288(rateData), 'tCO2')}
          />
        </>
      )}
    </>
  );
};

export const ProcurementCharts: React.FC<ProcurementChartProps> = (props) => {
  const {
    costFunctionId,
    preSimulationCurve,
    postSimulationCurve,
    selectedMonth,
    timeDomain,
    updateTimeDomain,
  } = props;
  const caisoRate = useCAISORate(costFunctionId, {
    data_types: 'default',
    period: 60,
  });

  const initialProcurement = React.useMemo(
    () =>
      caisoRate &&
      preSimulationCurve.multiply(caisoRate.data.default!).rename('Initial procurement cost'),
    [preSimulationCurve, caisoRate]
  );

  const simulatedProcurement = React.useMemo(
    () =>
      caisoRate &&
      postSimulationCurve.multiply(caisoRate.data.default!).rename('Simulated procurement cost'),
    [postSimulationCurve, caisoRate]
  );

  return (
    <>
      <LoadingModal loading={!(caisoRate && initialProcurement && simulatedProcurement)} />
      {caisoRate && initialProcurement && simulatedProcurement && (
        <>
          <IntervalGraph
            axisLabel="Procurement Costs"
            data={[initialProcurement, simulatedProcurement]}
            month={selectedMonth}
            timeDomain={timeDomain}
            onTimeDomainChange={updateTimeDomain}
            units="$"
          />
          <IntervalGraph
            height={100}
            hideXAxis
            month={selectedMonth}
            onTimeDomainChange={updateTimeDomain}
            precision={5}
            timeDomain={timeDomain}
            {...scaleInvertedData(caisoRate.data.default!, '$')}
          />
        </>
      )}
    </>
  );
};

/** ============================ Helpers =================================== */
/**
 * Scales the data to show in kW, MW or GW depending on the extent of the interval's values
 *
 * @param {IntervalData} interval: the load interval being scaled
 * @param {string} units: the units of the data being scaled
 */
function scaleInvertedData(interval: IntervalData, units: string) {
  const max = interval.valueDomain[1];
  const magnitude = Math.abs(Math.log10(max));
  const [scale, wattage] = magnitude >= 6 ? [1e6, 'GW'] : magnitude >= 3 ? [1e3, 'MW'] : [1, 'kW'];
  return {
    data: interval.multiply(scale),
    units: `${units}/${wattage}`,
  };
}
