import { MonthSeries } from 'navigader/types';

/**
 * Converts kW to MW
 *
 * @param {number} kw: the kW value to convert
 */
export function kwToMw(kw: number | undefined | null) {
  if (kw === undefined || kw === null) return undefined;
  return kw / 1000;
}

/** Returns a value repeated 12 times */
export function toMonthSeries<T>(v: T): MonthSeries<T> {
  return [v, v, v, v, v, v, v, v, v, v, v, v];
}
