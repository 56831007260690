import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { routes } from 'navigader/routes';

import { DetailsPage } from './DetailsPage';
import { RFPPortfolioDashboard } from './RFPPortfolioDashboard';

export const RFPValidationsPage: React.FC = () => (
  <Switch>
    <Route path={routes.rfp.portfolio(':id')} component={DetailsPage} />
    <Route path={routes.rfp.base} component={RFPPortfolioDashboard} />
  </Switch>
);
