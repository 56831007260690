import * as React from 'react';

import { AlertType } from 'navigader/components';
import { GHGRate } from 'navigader/models';
import { makeStylesHook } from 'navigader/styles';
import { CostFunctions, Maybe, NullableMaybe, ProgressFields } from 'navigader/types';
import { omitFalsey } from 'navigader/util';

import { Flex } from '../Flex';
import { HoverText } from '../HoverText';
import { InfoIcon } from '../Icon';
import { Table, TableCellProps } from '../Table';
import { Typography } from '../Typography';

/** ============================ Types ===================================== */
type Simulation = {
  cost_functions: CostFunctions.ObjectShort;
  progress: ProgressFields;
};

type ImpactColumnProps = React.PropsWithChildren<{
  children: (n: NullableMaybe<number>) => React.ReactNode;
  costCalculation: NullableMaybe<number>;
  costFnClass: CostFunctions.Key;
  simulation: Simulation;
}>;

type ImpactColumnHeaderProps = TableCellProps & {
  averaged?: boolean;
  column: string;
  info: {
    measuresImpact: string;
    negativeMeans: string;
    positiveMeans: string;
  };
  units: React.ReactNode;
};

/** ============================ Styles ==================================== */
const useImpactColumnHeaderStyles = makeStylesHook(
  (theme) => ({
    header: {
      whiteSpace: 'nowrap',
    },
    // This is needed to compensate for the subscript 2 in CO2 for the GHG column
    units: {
      height: `calc(${theme.typography.body2.lineHeight}rem + 3px)`,
    },
  }),
  'ImpactColumnHeader'
);

/** ============================ Components ================================ */
const ImpactColumnHeader: React.FC<ImpactColumnHeaderProps> = (props) => {
  const { averaged = false, column, info, units, ...rest } = props;
  const classes = useImpactColumnHeaderStyles();
  const infoString = omitFalsey([
    `Measures net impact ${info.measuresImpact}`,
    `A negative value indicates ${info.negativeMeans}`,
    `A positive value indicates ${info.positiveMeans}.`,
  ]).join('. ');

  return (
    <Table.Cell align="right" {...rest}>
      <div>
        <Flex.Container alignItems="center" justifyContent="flex-end" wrap={false}>
          <div className={classes.header}>{column}</div>
          <InfoIcon text={infoString} />
        </Flex.Container>
        <Typography className={classes.units} color="textSecondary" useDiv variant="body2">
          ({units}/year{averaged && '/SAID'})
        </Typography>
      </div>
    </Table.Cell>
  );
};

export const ImpactColumn = Object.assign(
  function ImpactColumn(props: ImpactColumnProps) {
    const { children, costCalculation, costFnClass, simulation } = props;
    const costFn = simulation.cost_functions[costFnClass];

    const costFnClassName = {
      ghg_rate: 'GHG rate',
      procurement_rate: 'procurement rate',
      rate_plan: 'rate plan',
      system_profile: 'system profile',
    }[costFnClass];

    // Determine the text to show in the popover
    const [hoverText, alertType] = ((): [string, Maybe<AlertType>] => {
      if (!simulation.progress.is_complete) return ['Scenario is processing', 'info'];
      if (costCalculation === undefined) return [`No ${costFnClassName} selected`, undefined];
      if (costFn) return [`Calculated with ${costFn.name}`, undefined];

      // If there's a cost calculation but no cost function, that implies the cost function was
      // deleted--unless it's a rate plan or a GHG rate. For rate plans this situation implies the
      // rate calculations were performed with automatically-assigned rate plans. For GHG rates this
      // is the norm.
      switch (costFnClass) {
        case 'rate_plan':
          return ['Calculated with automatically assigned rate plans', undefined];
        case 'ghg_rate':
          return [`Calculated with ${GHGRate.cspName(GHGRate.DEFAULT_CSP_YEAR)}`, undefined];
        default:
          return ['Cost function has been deleted', 'warning'];
      }
    })();

    return (
      <Table.Cell align="right">
        <HoverText text={hoverText} type={alertType}>
          {children(costCalculation)}
        </HoverText>
      </Table.Cell>
    );
  },
  { Header: ImpactColumnHeader }
);
