import { useDispatch, useSelector } from 'react-redux';

import * as api from 'navigader/api';
import { slices } from 'navigader/store';
import { DataTypeParams, Loader, MeterGroup, OriginFile } from 'navigader/types';
import { models } from 'navigader/util/data';
import { useAsync } from './util';

/**
 * Retrieves a meter group from the store that matches the given ID and data filters, and fetches
 * it from the backend if it's not found in the store.
 *
 * @param {string|undefined} meterGroupId: the ID of the meter group to get. This is allowed to be
 *   `undefined` to simplify cases in which the ID may not be ready at component mount time
 * @param {DataTypeParams} [params]: any data type filters to apply to the meter group
 */
export function useMeterGroup(meterGroupId: MeterGroup['id'], params?: DataTypeParams) {
  const dispatch = useDispatch();

  // Check the store for meter group that matches the provided filters
  const meterGroup = useSelector(slices.models.selectMeterGroup(meterGroupId, params));
  const loading = useAsync(
    async () => {
      // If we've already got the meter group which passes the filters, no need to fetch
      if (!meterGroupId || meterGroup) return;
      return api.getMeterGroup(meterGroupId, params);
    },
    (meterGroup) => dispatch(slices.models.updateModels([meterGroup])),
    [meterGroupId]
  );

  return { loading, meterGroup };
}

/** ============================ Origin Files ============================== */
export function useOriginFiles(params: api.OriginFilesQueryParams): Loader<OriginFile[]> {
  const dispatch = useDispatch();

  // Fetch the meter groups
  const loading = useAsync(
    () => api.getOriginFiles(params),
    ({ data }) => {
      // Add data to the store and continue polling for meter groups that haven't finished ingesting
      dispatch(slices.models.updateModels(data));
      models.polling.addMeterGroups(data, params);
    }
  );

  const originFiles = useSelector(slices.models.selectOriginFiles);
  return Object.assign(originFiles, { loading });
}

export function useOriginFile(originFileId: OriginFile['id'], params?: api.OriginFileQueryParams) {
  const dispatch = useDispatch();

  // Check the store for meter group that matches the provided filters
  const originFile = useSelector(slices.models.selectOriginFile(originFileId, params));
  const loading = useAsync(
    async () => {
      // If we've already got the meter group which passes the filters, no need to fetch
      if (!originFileId || originFile) return;
      return api.getOriginFile(originFileId, params);
    },
    (originFile) => dispatch(slices.models.updateModels([originFile])),
    [originFileId]
  );

  return { loading, originFile };
}
