import { DateTime } from 'luxon';

import store, { slices } from 'navigader/store';
import {
  DataTypeMap,
  DataTypeParams,
  DateRange,
  DynamicRestParams,
  IdType,
  LoadServingEntity,
  PaginationQueryParams,
  RawDataObject,
  RawDateRangeObject,
  RawPaginationSet,
} from 'navigader/types';
import { serializers } from 'navigader/util';

import { BaseAPI, ProgressCallback } from '../base';
import _ from 'lodash';

/** ============================ Types ===================================== */
export declare namespace SystemProfile {
  type IncludeFields = 'load_serving_entity.*';

  type Raw = RawDataObject<'index', 'kw'> &
    RawDateRangeObject & {
      created_at: string;
      id: number;
      load_serving_entity?: LoadServingEntity;
      name: string;
      object_type: 'SystemProfile';
      resource_adequacy_rates: number[];
    };

  namespace API {
    type RequiredFields = 'resource_adequacy_rates' | 'name';
    type CreateParams = UpdateParams & Pick<SystemProfile, RequiredFields>;
    type CreateResponse = RetrieveResponse;
    type ListParams = RetrieveParams & PaginationQueryParams;
    type ListResponse = RawPaginationSet<{ system_profiles: SystemProfile.Raw[] }>;
    type RetrieveParams = DynamicRestParams<IncludeFields> & DataTypeParams;
    type RetrieveResponse = { system_profile: SystemProfile.Raw };
    type UpdateParams = { file: File };
    type UpdateResponse = RetrieveResponse;
  }
}

/** ============================ API ======================================= */
class SystemProfileAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.cost.system_profile;

  static create = async (params: SystemProfile.API.CreateParams) => {
    const result = await this.postForm<SystemProfile.API.CreateResponse>(this.route, params);
    return result.map(({ system_profile }) => {
      // Add model to the store and return
      const systemProfile = new SystemProfile(system_profile);
      store.dispatch(slices.models.updateModel(systemProfile));
      return systemProfile;
    });
  };

  static destroy = (id: IdType) => this.delete(this.route(id));

  static download = (id: IdType, onProgress?: ProgressCallback) => {
    const url = this.route(id).download;
    return this.downloadFile(url, 'system-profile-data.csv', onProgress);
  };

  static list = async (params: SystemProfile.API.ListParams) => {
    const result = await this.get<SystemProfile.API.ListResponse>(this.route, params);
    return result.map((json) => {
      // Parse the results
      const paginationSet = this.parsePaginationSet(json, ({ system_profiles }) =>
        system_profiles.map(SystemProfile.fromObject)
      );

      // Add models to the store and return
      store.dispatch(slices.models.updateModels(paginationSet.data));
      return paginationSet;
    });
  };

  static retrieve = async (id: IdType, params?: SystemProfile.API.RetrieveParams) => {
    const result = await this.get<SystemProfile.API.RetrieveResponse>(this.route(id), params);
    return result.map((json) => {
      // Add model to the store and return
      const systemProfile = new SystemProfile(json.system_profile);
      store.dispatch(slices.models.updateModel(systemProfile));
      return systemProfile;
    });
  };

  static update = async (id: IdType, params: SystemProfile.API.UpdateParams) => {
    const result = await this.patchForm<SystemProfile.API.UpdateResponse>(this.route(id), params);
    return result.map(({ system_profile }) => {
      // Add model to the store and return
      const systemProfile = new SystemProfile(system_profile);
      store.dispatch(slices.models.updateModel(systemProfile));
      return systemProfile;
    });
  };
}

/** ============================ Model ===================================== */
export class SystemProfile {
  readonly created_at: DateTime;
  readonly data: DataTypeMap;
  readonly date_range: DateRange;
  readonly id: number;
  readonly load_serving_entity?: LoadServingEntity;
  readonly name: string;
  readonly object_type = 'SystemProfile';
  readonly period?: number;
  readonly resource_adequacy_rates: number[];

  static api = SystemProfileAPI;
  static fromObject = (raw: SystemProfile.Raw) => new SystemProfile(raw);

  constructor(raw: SystemProfile.Raw) {
    this.created_at = serializers.parseDateTime(raw.created_at);
    this.data = serializers.parseDataField(raw.data, raw.name, 'index', 'kw');
    this.date_range = serializers.parseDateRange(raw.date_range);
    this.id = raw.id;
    this.name = raw.name;
    this.period = raw.period;
    this.resource_adequacy_rates = raw.resource_adequacy_rates;
  }

  serialize(): SystemProfile.Raw {
    const fields = _.pick(this, ['id', 'name', 'object_type', 'period', 'resource_adequacy_rates']);
    return {
      ...fields,
      created_at: serializers.serializeDateTime(this.created_at),
      data: serializers.serializeDataField(this.data, 'index', 'kw'),
      date_range: serializers.serializeDateRange(this.date_range),
    };
  }
}
