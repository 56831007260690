import store, { slices } from 'navigader/store';
import { IdType, PaginationQueryParams, RawPaginationSet } from 'navigader/types';

import { BaseAPI } from './base';

/** ============================ Types ===================================== */
export declare namespace Workspace {
  type Raw = {
    id: IdType;
    name: string;
    object_type: 'Workspace';
  };

  namespace API {
    type CreateParams = Pick<Workspace, 'name'>;
    type CreateResponse = { workspace: Workspace.Raw };
    type ListResponse = RawPaginationSet<{ workspaces: Workspace.Raw[] }>;
  }
}

/** ============================ API ======================================= */
class WorkspaceAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.user.workspace;

  /** ========================== Profile endpoints ========================= */
  static create = async (params: Workspace.API.CreateParams) => {
    const result = await this.post<Workspace.API.CreateResponse>(this.route, params);
    return result.map((json) => {
      const workspace = Workspace.fromObject(json.workspace);
      store.dispatch(slices.models.updateModel(workspace));
      return workspace;
    });
  };

  static list = async () => {
    const params: PaginationQueryParams = { page: 0, pageSize: 100 };
    const result = await this.get<Workspace.API.ListResponse>(this.route, params);
    return result.map((json) => {
      // Parse the results
      const paginationSet = this.parsePaginationSet(json, ({ workspaces }) =>
        workspaces.map(Workspace.fromObject)
      );

      // Add models to the store and return
      store.dispatch(slices.models.updateModels(paginationSet.data));
      return paginationSet;
    });
  };
}

/** ============================ Model ===================================== */
export class Workspace {
  readonly id: IdType;
  readonly name: string;
  readonly object_type = 'Workspace';

  static api = WorkspaceAPI;
  static fromObject = (raw: Workspace.Raw) => new Workspace(raw);

  constructor(raw: Workspace.Raw) {
    this.id = raw.id;
    this.name = raw.name;
  }

  serialize(): Workspace.Raw {
    return {
      id: this.id,
      name: this.name,
      object_type: 'Workspace',
    };
  }
}
