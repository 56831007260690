import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import * as api from 'navigader/api';
import { slices, useMemoizedSelector } from 'navigader/store';
import { Loader, Scenario } from 'navigader/types';
import { models } from 'navigader/util/data';
import { omitFalsey } from 'navigader/util/omitFalsey';
import { useAsync } from './util';

/**
 * Loads a scenario given its ID and params for querying
 *
 * @param {string} scenarioId: the ID of the scenario to get
 * @param {GetScenarioQueryParams} [params]: additional params for querying
 */
export function useScenario(scenarioId: string, params?: api.GetScenarioQueryParams) {
  const dispatch = useDispatch();

  // Check the store for scenarios that match the provided filters
  const scenario = useSelector(slices.models.selectScenario(scenarioId, params));
  const loading = useAsync(
    async () => {
      // If we've already loaded the scenario (with all required data), we don't need to do so again
      if (scenario) return;
      return api.getScenario(scenarioId, params);
    },
    (scenario) => {
      // Add the data to the store and continue polling for unfinished meter groups
      const meterGroups = omitFalsey([scenario, scenario.meter_group]);
      models.polling.addMeterGroups(meterGroups);
      dispatch(slices.models.updateModels(meterGroups));
    },
    [scenarioId]
  );

  // Look up the scenario in the store
  return { scenario, loading };
}

/**
 * Loads scenarios, using the provided query params
 *
 * @param {GetScenariosQueryParams} [params]: additional params for querying
 */
export function useScenarios(params: api.GetScenariosQueryParams): Loader<Scenario[]> {
  const dispatch = useDispatch();

  // Fetch the scenarios
  const loading = useAsync(
    () => api.getScenarios(params),
    ({ data: scenarios }) => {
      // Add the data to the store and continue polling for unfinished meter groups
      const meterGroups = omitFalsey(_.map(scenarios, 'meter_group'));
      const allMeterGroups = [...scenarios, ...meterGroups];
      models.polling.addMeterGroups(allMeterGroups);
      dispatch(slices.models.updateModels(allMeterGroups));
    }
  );

  // Retrieve scenarios that match the provided filters from the store
  const scenarios = useMemoizedSelector(slices.models.selectScenariosWithFilters, params);
  return Object.assign(scenarios, { loading });
}
