import _ from 'lodash';
import * as React from 'react';

import { MonthIndex, MonthsOption } from 'navigader/types';
import { formatters, MONTHS } from 'navigader/util';
import { Toggle } from '../ToggleButton';

/** ============================ Types ===================================== */
type MonthSelectorProps = {
  exclusive?: false;
  onChange: (months: MonthsOption) => void;
  selectable?: MonthIndex[];
  selected: MonthsOption;
};

type MonthSelectorExclusiveProps = {
  exclusive: true;
  onChange: (newMonth: MonthIndex) => void;
  selectable?: MonthIndex[];
  selected: MonthIndex | undefined;
};

/** ============================ Components ================================ */
export const MonthSelector: React.FC<MonthSelectorProps | MonthSelectorExclusiveProps> = (
  props
) => {
  const { exclusive, onChange, selectable, selected } = props;
  return (
    // The `Toggle.Group` is marked as exclusive even if the `MonthSelector` isn't because it's
    // easier to type check this way
    <Toggle.Group exclusive onChange={toggleMonth} size="small" value={selected}>
      {MONTHS.map((n) => {
        const monthName = formatters.getMonthName(n);
        const disabled = selectable && !_.includes(selectable, n);
        return (
          <Toggle.Button key={n} disabled={disabled} value={n} aria-label={monthName}>
            {monthName.slice(0, 3)}
          </Toggle.Button>
        );
      })}
      {!exclusive && (
        <Toggle.Button value="all" aria-label="All">
          All
        </Toggle.Button>
      )}
    </Toggle.Group>
  );

  function toggleMonth(month: MonthIndex | 'all') {
    if (exclusive) {
      // The `month` argument should never be "all" when the selector is `exclusive`, but TypeScript
      // doesn't know that
      if (month !== 'all') onChange(month);
      return;
    }

    if (month === 'all') {
      onChange(month);
      return;
    }

    if (selected === 'all') {
      onChange([month]);
      return;
    }

    if (selected.includes(month)) {
      // Remove the month from the selected list
      onChange(_.without(selected, month));
    } else {
      // Add to the list
      onChange([...selected, month]);
    }
  }
};
