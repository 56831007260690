import { SystemProfile } from 'navigader/models';
import store, { slices } from 'navigader/store';
import {
  CAISORate,
  DataTypeParams,
  DynamicRestParams,
  IdType,
  Nullable,
  PaginationQueryParams,
  RateCollection,
  RatePlan,
  RawCAISORate,
  RawMeterGroup,
  RawOriginFile,
  RawRatePlan,
  RawScenario,
  Scenario,
} from 'navigader/types';
import { appendQueryString, Err, Ok, omitFalsey, ResultAsync, serializers } from 'navigader/util';
import {
  appendId,
  beoRoute,
  deleteRequest,
  downloadFile,
  extractError,
  getRequest,
  makeFormPatch,
  makeFormPost,
  makeFormXhrPost,
  parsePaginationSet,
  patchRequest,
  postRequest,
  ProgressCallback,
} from './util';

/** ============================ Types ===================================== */
type DerSelection = {
  configurationId: string;
  strategyId: string;
};

export type CostFunctionSelections = Partial<{
  ratePlan: RatePlan['id'] | 'auto';
  caisoRate: CAISORate['id'];
  systemProfile: SystemProfile['id'];
}>;

/** Query params */
export type GetScenarioQueryParams = ScenarioDynamicRestParams & DataTypeParams;
export type GetScenariosQueryParams = GetScenarioQueryParams & PaginationQueryParams;
export type ScenarioDynamicRestParams = DynamicRestParams<
  'der_stack' | 'meter_group' | 'meter_group.*' | 'report' | 'report_summary'
>;

type GetCAISORateQueryOptions = DynamicRestParams & DataTypeParams;
export type GetCAISORatesQueryOptions = GetCAISORateQueryOptions & PaginationQueryParams;

type RatePlanIncludeFields = 'rate_collections.*';
export type GetRatePlanQueryOptions = DynamicRestParams<RatePlanIncludeFields>;
export type GetRatePlansQueryOptions = GetRatePlanQueryOptions & PaginationQueryParams;
type UpdateRatePlanParams = Required<Pick<RatePlan, 'associated_rs_names'>>;
export type CreateRatePlanParams = Required<Pick<RatePlan, 'name' | 'sector'>>;
export type CreateRateCollectionParams = {
  rate_data_csv: File;
  rate_plan: string;
};

export type UpdateCostFunctionParams = { file: File };
export type CreateCAISORateParams = UpdateCostFunctionParams & Pick<CAISORate, 'name'>;

/** Responses */
type GetScenariosResponse = { meter_groups?: RawMeterGroup[]; scenarios: RawScenario[] };
type GetScenarioResponse = {
  origin_files?: RawOriginFile[];
  scenarios?: RawScenario[];
  scenario: RawScenario;
};
type GetCAISORatesResponse = { caiso_rates: RawCAISORate[] };
type GetRatePlanResponse = { rate_plan: RawRatePlan };
type GetRatePlansResponse = { rate_plans: RawRatePlan[] };
type CreateRatePlanResponse = { rate_plan: RawRatePlan };

/** ============================ Scenarios =============================== */
export async function postScenario(
  scenarioName: string,
  meterGroupIds: string[],
  ders: DerSelection[],
  costFunctions: CostFunctionSelections
) {
  return await postRequest(routes.scenarios(), {
    cost_functions: {
      procurement_rate: costFunctions.caisoRate,
      rate_plan: costFunctions.ratePlan,
      system_profile: costFunctions.systemProfile,
    },
    name: scenarioName,
    meter_group_ids: meterGroupIds,
    ders: ders.map(({ configurationId, strategyId }) => ({
      der_configuration_id: configurationId,
      der_strategy_id: strategyId,
    })),
  });
}

/**
 * Loads scenario objects
 *
 * @param {GetScenariosQueryParams} queryParams: parameters for filtering the result set
 */
export async function getScenarios(queryParams: GetScenariosQueryParams) {
  const response = await getRequest(routes.scenarios(), queryParams).then((res) => res.json());

  // Parse the meter results
  return parsePaginationSet<GetScenariosResponse, Scenario>(
    response,
    ({ meter_groups = [], scenarios }) =>
      scenarios.map((scenario) => serializers.parseScenario(scenario, meter_groups))
  );
}

export async function patchScenario(scenarioId: string, updates: Partial<Scenario>) {
  return await patchRequest(routes.scenarios(scenarioId), updates);
}

export async function getScenario(id: string, queryParams?: GetScenarioQueryParams) {
  const {
    origin_files = [],
    scenarios = [],
    scenario,
  }: GetScenarioResponse = await getRequest(routes.scenarios(id), queryParams).then((res) =>
    res.json()
  );

  return serializers.parseScenario(scenario, [...origin_files, ...scenarios]);
}

/**
 * Deletes a scenario given the ID
 *
 * @param {string} id: the ID of the scenario
 */
export async function deleteScenario(id: string) {
  return await deleteRequest(routes.scenarios(id));
}

/**
 * Downloads a CSV of customer-level scenario data
 *
 * @param {Scenario} scenario: the scenario to fetch customer data from
 * @param {ProgressCallback} onProgress: callback to execute when the download progresses
 */
export async function downloadReport(scenario: Scenario, onProgress?: ProgressCallback) {
  const url = routes.scenarios.downloadReport(scenario.id);
  return downloadFile(url, `${scenario.name}_report.csv`, onProgress);
}

export function downloadIntervals(
  scenario: Scenario,
  meterId: Nullable<string>,
  onProgress?: ProgressCallback
) {
  const params = omitFalsey({ customer_id: meterId });
  const url = appendQueryString(routes.scenarios.downloadIntervals(scenario.id), params);
  return downloadFile(url, `${scenario.name}_interval_data`, onProgress);
}

/** ============================ Procurement =============================== */
export async function getCAISORates(options?: GetCAISORatesQueryOptions) {
  const response = await getRequest(routes.caiso_rate(), options).then((res) => res.json());

  // Parse the CAISO rate results into full-fledged `NavigaderObjects`
  const paginationSet = parsePaginationSet<GetCAISORatesResponse, CAISORate>(
    response,
    ({ caiso_rates }) => caiso_rates.map(serializers.parseCAISORate)
  );

  // Add models to the store and return
  store.dispatch(slices.models.updateModels(paginationSet.data));
  return paginationSet;
}

export async function getCAISORate(id: IdType, options?: GetCAISORatesQueryOptions) {
  const response = await getRequest(routes.caiso_rate(id), options).then((res) => res.json());

  // Parse the CAISO rate result into a full-fledged `NavigaderObject`
  return serializers.parseCAISORate({ ...response.caiso_rate, object_type: 'CAISORate' });
}

export function createCAISORate(params: CreateCAISORateParams) {
  return makeFormPost(routes.caiso_rate(), params);
}

export function updateCAISORate(id: CAISORate['id'], params: UpdateCostFunctionParams) {
  return makeFormPatch(routes.caiso_rate(id), params);
}

export async function deleteCAISORate(id: IdType) {
  return await deleteRequest(routes.caiso_rate(id));
}

export function downloadCAISORate(id: IdType, onProgress?: ProgressCallback) {
  const url = routes.caiso_rate.download(id);
  return downloadFile(url, 'procurement-rate-data.csv', onProgress);
}

/** ============================ Rate plans ================================ */
export async function getRatePlans(params?: GetRatePlansQueryOptions) {
  const response = await getRequest(routes.rate_plans(), params).then((res) => res.json());

  // Parse the rate plan results into full-fledged `NavigaderObjects`, nesting the rate collections
  // under the plan
  const paginationSet = parsePaginationSet<GetRatePlansResponse, RatePlan>(
    response,
    ({ rate_plans }) => rate_plans.map(serializers.parseRatePlan)
  );

  // Add models to the store and return
  store.dispatch(slices.models.updateModels(paginationSet.data));
  return paginationSet;
}

export async function getRatePlan(
  id: RatePlan['id'],
  params?: DynamicRestParams<RatePlanIncludeFields>
): Promise<RatePlan> {
  const response: GetRatePlanResponse = await getRequest(routes.rate_plans(id), params).then(
    (res) => res.json()
  );

  return serializers.parseRatePlan(response.rate_plan);
}

export async function createRatePlan(params: CreateRatePlanParams): ResultAsync<RatePlan> {
  let response: Response;
  try {
    response = await postRequest(routes.rate_plans(), params);
  } catch (e) {
    // If the request failed, yield the failure message
    return Err('Request failed');
  }

  // If the request returned a non-200 code, yield the error
  if (!response.ok) {
    return Err(extractError(await response.json()));
  }

  const json: CreateRatePlanResponse = await response.json();
  return Ok({
    ...json.rate_plan,
    rate_collections: [], // New Rate Plans always have empty rate_collection sets
    start_date: null, // New Rate Plans always have no start date (no rate collections)
    object_type: 'RatePlan',
  });
}

export async function deleteRatePlan(id: string) {
  return await deleteRequest(routes.rate_plans(id));
}

export async function updateRatePlan(
  id: RatePlan['id'],
  params: UpdateRatePlanParams
): ResultAsync<RatePlan> {
  let response: Response;
  try {
    response = await patchRequest(routes.rate_plans(id), params);
  } catch (e: any) {
    return Err(e);
  }

  const json: CreateRatePlanResponse = await response.json();
  const ratePlan = serializers.parseRatePlan(json.rate_plan);
  return Ok(ratePlan);
}

/** ============================= Rate Collections ========================= */
export function createRateCollection(
  params: CreateRateCollectionParams,
  callback: (response: XMLHttpRequest) => void
) {
  const xhr = makeFormXhrPost(routes.rate_collections(), params);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      callback(xhr);
    }
  };
}

export async function deleteRateCollection(id: RateCollection['id']) {
  return await deleteRequest(routes.rate_collections(id));
}

export function downloadRateCollectionData(id: RatePlan['id'], onProgress?: ProgressCallback) {
  const url = routes.rate_collections.download(id);
  return downloadFile(url, 'rate-collection-data.csv', onProgress);
}

/** ============================ Helpers =================================== */
const baseRoute = (rest: string) => beoRoute.v1(`cost/${rest}`);
const routes = {
  caiso_rate: Object.assign(appendId(baseRoute('caiso_rate')), {
    download: (id: IdType) => routes.caiso_rate(id) + 'download/',
  }),
  ghg_rate: appendId(baseRoute('ghg_rate')),
  system_profile: Object.assign(appendId(baseRoute('system_profile')), {
    download: (id: IdType) => routes.system_profile(id) + 'download/',
  }),
  scenarios: Object.assign(appendId(baseRoute('scenario')), {
    downloadReport: (id: IdType) => routes.scenarios(id) + 'download_report/',
    downloadIntervals: (id: IdType) => routes.scenarios(id) + 'download_intervals/',
  }),
  rate_plans: appendId(baseRoute('rate_plan')),
  rate_collections: Object.assign(appendId(baseRoute('rate_collection')), {
    download: (id: IdType) => routes.rate_collections(id) + 'download/',
  }),
};
