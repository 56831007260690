import * as React from 'react';

import { IntervalWidget, ScenariosTable } from 'navigader/components';
import { makeStylesHook } from 'navigader/styles';
import { Scenario } from 'navigader/types';
import { useMeterGroup } from 'navigader/util/hooks';

/** ============================ Types ===================================== */
type ScenarioProp = {
  scenario: Scenario;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  (theme) => ({
    table: {
      marginTop: theme.spacing(2),
    },
  }),
  'AggregateImpactsTab'
);

/** ============================ Components ================================ */
export const AggregateImpactsTab: React.FC<ScenarioProp> = ({ scenario }) => {
  const classes = useStyles();
  const { meterGroup } = useMeterGroup(scenario.meter_group!.id, {
    data_types: 'default',
    period: 60,
  });

  return (
    <>
      <IntervalWidget
        procurementRateId={scenario.cost_functions.procurement_rate?.id}
        postSimulationCurve={scenario.data.default}
        preSimulationCurve={meterGroup?.data.default}
        title="Simulation Results"
      />
      <div className={classes.table}>
        <ScenariosTable
          scenarios={[scenario]}
          title="DER Program Scenario Impacts: Delta between Initial Load and Simulated Load"
        />
      </div>
    </>
  );
};
