import store, { slices } from 'navigader/store';
import {
  DynamicRestParams,
  Frame288NumericType,
  PaginationQueryParams,
  RawPaginationSet,
} from 'navigader/types';
import { Frame288Numeric, ResultAsync, serializers } from 'navigader/util';

import { BaseAPI } from '../base';

/** ============================ Types ===================================== */
export declare namespace GHGRate {
  export type CSPYear = 2018 | 2022 | 2026 | 2030;

  type Raw = {
    data?: Frame288NumericType;
    effective: string;
    id: number;
    name: string;
    object_type: 'GHGRate';
    rate_unit: number;
    source: string;
  };

  namespace API {
    type ListResponse = RawPaginationSet<{ ghg_rates: GHGRate.Raw[] }>;
    type ListParams = RetrieveParams & PaginationQueryParams;
    type RetrieveResponse = { ghg_rate: GHGRate.Raw };
    type RetrieveParams = DynamicRestParams &
      (
        | { data_format: '288' }
        | { data_format: 'interval'; period: '1H' | '15M'; start: string; end_limit: string }
      );
  }
}

/** ============================ API ======================================= */
class GHGRateAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.cost.ghg_rate;

  /** Helper method for handling responses containing a single GHGRate object */
  private static async handleRetrieval(result: ResultAsync<GHGRate.API.RetrieveResponse>) {
    return (await result).map((json) => {
      const ghgRate = GHGRate.fromObject(json.ghg_rate);
      store.dispatch(slices.models.updateModel(ghgRate));
      return ghgRate;
    });
  }

  static list = async (params?: GHGRate.API.ListParams) => {
    const result = await this.get<GHGRate.API.ListResponse>(this.route, params);
    return result.map((json) => {
      // Parse the GHG rate results
      const paginationSet = this.parsePaginationSet(json, ({ ghg_rates }) =>
        ghg_rates.map(GHGRate.fromObject)
      );

      // Add models to the store and return
      store.dispatch(slices.models.updateModels(paginationSet.data));
      return paginationSet;
    });
  };

  static retrieve = (id: GHGRate['id'], params?: GHGRate.API.RetrieveParams) => {
    return this.handleRetrieval(this.get<GHGRate.API.RetrieveResponse>(this.route(id), params));
  };

  static retrieveByYear = (year: GHGRate.CSPYear, params?: GHGRate.API.RetrieveParams) => {
    return this.handleRetrieval(
      this.get<GHGRate.API.RetrieveResponse>(this.route.by_year, { ...params, year })
    );
  };
}

/** ============================ Model ===================================== */
export class GHGRate {
  readonly data?: Frame288Numeric;
  readonly effective: Date;
  readonly id: number;
  readonly name: string;
  readonly object_type = 'GHGRate';
  readonly rate_unit: number;
  readonly source: string;

  static DEFAULT_CSP_YEAR: GHGRate.CSPYear = 2022;
  static api = GHGRateAPI;
  static fromObject = (raw: GHGRate.Raw) => new GHGRate(raw);
  static cspName = (year: GHGRate.CSPYear) => `Clean System Power ${year}`;

  constructor(raw: GHGRate.Raw) {
    this.data = Frame288Numeric.fromRaw(raw.data, { name: raw.name, units: 'tCO2/kW' });
    this.effective = serializers.parseDate(raw.effective);
    this.id = raw.id;
    this.name = raw.name;
    this.rate_unit = raw.rate_unit;
    this.source = raw.source;
  }

  serialize(): GHGRate.Raw {
    return {
      data: this.data?.frame,
      effective: serializers.serializeDate(this.effective),
      id: this.id,
      name: this.name,
      object_type: this.object_type,
      rate_unit: this.rate_unit,
      source: this.source,
    };
  }
}
