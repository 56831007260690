import * as React from 'react';
import _ from 'lodash';

import { Button, Dialog, FileSelector, Grid, TextField } from 'navigader/components';
import { SystemProfile } from 'navigader/models';
import { usePushRouter } from 'navigader/routes';
import { Err, Ok, Result } from 'navigader/util';
import { useSnackbar } from 'navigader/util/hooks';

import { CostFunctionFileUpload, DialogProps } from '../common/CreateDialog';
const { useFileSelectorState } = FileSelector;

/** ============================== Types =================================== */
type UpdateSystemProfileDialogProps = DialogProps & { systemProfile?: SystemProfile };

/** ============================ Components ================================ */
export const CreateSystemProfile: React.FC<DialogProps> = ({ open, onClose }) => {
  const snackbar = useSnackbar();
  const routeTo = usePushRouter();

  const url = (m: 15 | 60) => `/downloads/system_profile/system_profile_${m}_min_template.csv`;

  // State
  const [state, setState] = useFileSelectorState();
  const { file, name } = state;
  const [raRate, updateRaRate] = React.useState<string[]>([]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Dialog.Title>Create System Profile</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <CostFunctionFileUpload url={url} setState={setState} state={state} />
          <Grid.Item span={12}>
            <TextField
              id="name"
              label="Name"
              onChange={(name) => setState({ name })}
              value={name || ''}
            />
          </Grid.Item>
          <Grid.Item span={12}>
            <TextField.List
              id="resource_adequacy_rates"
              label="Resource Adequacy Rates"
              onChange={updateRaRate}
              total={12}
              value={raRate}
            />
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!validate().ok} onClick={onSubmit}>
          Submit
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ============================== Callbacks =============================== */
  async function onSubmit() {
    const result = validate();
    if (!result.ok) return;

    const apiResult = await SystemProfile.api.create(result.val);
    if (apiResult.ok) {
      // Navigate to the details page
      onClose();
      routeTo.cost.system_profiles.profile(apiResult.val)();
    } else {
      snackbar.error();
    }
  }

  /** ========================== Helpers =================================== */
  function validate(): Result<SystemProfile.API.CreateParams> {
    if (!file) return Err('file is required');
    if (raRate.length !== 12) return Err('RA must have 12 values');

    const numericRA = raRate.map(parseFloat);
    if (_.some(numericRA.map(_.isNaN))) return Err('RA must contain only numeric values');

    return Ok({
      file,
      name: name || '',
      resource_adequacy_rates: numericRA,
    });
  }
};

export const UpdateSystemProfile: React.FC<UpdateSystemProfileDialogProps> = ({
  systemProfile,
  open,
  onClose,
}) => {
  const snackbar = useSnackbar();
  const url = (m: 15 | 60) => `/downloads/system_profile/system_profile_${m}_min_template.csv`;

  // State
  const [state, setState] = useFileSelectorState();
  const { file } = state;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Dialog.Title>Update {systemProfile?.name}</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <CostFunctionFileUpload url={url} setState={setState} state={state} />
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!file} onClick={onSubmit}>
          Submit
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ============================== Callbacks =============================== */
  async function onSubmit() {
    if (!file || !systemProfile) return;

    const result = await SystemProfile.api.update(systemProfile.id, { file });
    if (result.ok) {
      onClose();
    } else {
      snackbar.error(result.err);
    }
  }
};
