import * as React from 'react';

import * as api from 'navigader/api';
import { Maybe } from 'navigader/types';
import { useSnackbar } from 'navigader/util/hooks';

/** ============================ Types ===================================== */
type AsyncFunction<T> = () => Promise<T>;

/** ============================ Hooks ===================================== */
/**
 * Hook for calling an asynchronous method
 *
 * @param {function} fn: the asynchronous function to call
 * @param {function} [callback]: a callback to execute once the function has completed
 * @param {any[]} dependencies: the dependency array
 */
export function useAsync<T>(
  fn: AsyncFunction<Maybe<T>>,
  callback?: (response: T) => void,
  dependencies: any[] = []
) {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let shouldUpdate = true;

    // If another request is already underway, return
    if (loading) return;

    // Update loading state and begin the async call
    setLoading(true);
    fn().then((res) => {
      // If the component has not unmounted, reset the loading state and trigger the callback
      if (shouldUpdate) {
        res && callback && callback(res);
        setLoading(false);
      }
    });

    // Cleanup function that will be called on
    // 1. Unmount
    // 2. Dependency array change
    return () => {
      shouldUpdate = false;
    };
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps

  return loading;
}

/**
 * Hook for generating a callback to download a file
 *
 * @param {string} url: The file URL, typically something like `/downloads/foo/bar.csv`
 */
export function useDownloadCallback(url: string) {
  const snackbar = useSnackbar();
  const fileName = url.split('/').pop();
  return () => api.util.downloadFile(url, fileName!).catch(snackbar.error);
}
