import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModelClassExterior, ModelClassInterior, ModelsSlice, UserProfile } from 'navigader/types';
import { serializers } from 'navigader/util';

/** ============================ Actions =================================== */
/** Payloads */
type UpdateHasMeterGroupsAction = PayloadAction<boolean>;
type SetUserProfileAction = PayloadAction<UserProfile>;

/** Prepared Payloads */
type ModelAction = PayloadAction<{ model: ModelClassInterior; options?: UpdateOptions }>;
type ModelsAction = PayloadAction<ModelClassInterior[]>;

type UpdateOptions = { force?: string[] };

/** ============================ Slice ===================================== */
const initialState: ModelsSlice = {
  caisoRates: [],
  derConfigurations: [],
  derStrategies: [],
  ghgRates: [],
  hasMeterGroups: null,
  meterGroups: [],
  meters: [],
  ratePlans: [],
  systemProfiles: [],
  userProfile: null,
  rfpPortfolios: [],
  rfpMeters: [],
  workspaces: [],
};

/**
 * Global UI slice. This will hold state for certain global UI state parameters, such as whether a
 * snackbar message or modal is open
 */
const slice = createSlice({
  name: 'models',
  initialState,
  reducers: {
    removeModel: {
      prepare: (model: ModelClassExterior) => ({ payload: { model: prepareModel(model) } }),
      reducer: (state, action: ModelAction) => {
        const { model } = action.payload;
        const slice = getSliceForModel(state, model);

        // If we find the model in the slice, splice it out
        const modelIndex = _.findIndex(slice, ['id', model.id]);
        if (modelIndex !== -1) {
          slice.splice(modelIndex, 1);
        }
      },
    },
    setProfile: (state, action: SetUserProfileAction) => {
      state.userProfile = action.payload;
    },
    updateHasMeterGroups: (state, action: UpdateHasMeterGroupsAction) => {
      state.hasMeterGroups = action.payload;
    },
    updateModels: {
      prepare: (models: ModelClassExterior[]) => ({ payload: models.map(prepareModel) }),
      reducer: (state, action: ModelsAction) => {
        action.payload.forEach((model) => addOrUpdateModel(state, model));
      },
    },
    updateModel: {
      prepare: (model: ModelClassExterior, options?: UpdateOptions) => ({
        payload: { model: prepareModel(model), options },
      }),
      reducer: (state, action: ModelAction) => {
        const { options, model } = action.payload;
        addOrUpdateModel(state, model, options);
      },
    },
  },
});

export const { reducer } = slice;
export const { removeModel, setProfile, updateHasMeterGroups, updateModels, updateModel } =
  slice.actions;

/** ============================ Reducer methods =========================== */
/**
 * Updates a model in state if it is already present, or adds it to state if it is not. The model's
 * `id` and `object_type` are used to access the model within the slice
 *
 * @param {ModelsSlice} state: the current state of the `models` slice
 * @param {ModelClassInterior} model: the model to add or update to the store
 * @param {UpdateOptions} options: options to customize the update behavior
 */
function addOrUpdateModel(state: ModelsSlice, model: ModelClassInterior, options?: UpdateOptions) {
  const slice = getSliceForModel(state, model);
  const modelIndex = _.findIndex(slice, ['id', model.id]);

  if (modelIndex === -1) {
    // Add it to the store
    slice.push(model);
    return;
  }

  // Merge the existing data with the updates. The last argument is a "customizer" which allows for
  // custom merging behavior. If the function returns `undefined`, lodash's default merging behavior
  // is utilized.
  const force = options?.force || [];
  const merged = _.mergeWith({}, slice[modelIndex], model, (objValue, srcValue, key) => {
    // If the key is in the `force` list, the source's value is taken instead of a merged version
    if (force.includes(key)) {
      return srcValue;
    }
  });

  // Splice it into the slice
  slice.splice(modelIndex, 1, merged);
}

/** ============================ Helpers =================================== */
function getSliceForModel(
  state: ModelsSlice,
  model: Pick<ModelClassExterior, 'object_type'>
): Array<ModelClassInterior> {
  switch (model.object_type) {
    case 'BatteryConfiguration':
    case 'EVSEConfiguration':
    case 'SolarPVConfiguration':
    case 'FuelSwitchingConfiguration':
      return state.derConfigurations;
    case 'BatteryStrategy':
    case 'EVSEStrategy':
    case 'SolarPVStrategy':
    case 'FuelSwitchingStrategy':
      return state.derStrategies;
    case 'CAISORate':
      return state.caisoRates;
    case 'OriginFile':
    case 'Scenario':
      return state.meterGroups;
    case 'CustomerMeter':
      return state.meters;
    case 'GHGRate':
      return state.ghgRates;
    case 'RatePlan':
      return state.ratePlans;
    case 'RFPMeter':
      return state.rfpMeters;
    case 'RFPPortfolio':
      return state.rfpPortfolios;
    case 'SystemProfile':
      return state.systemProfiles;
    case 'Workspace':
      return state.workspaces;
  }
}

/**
 * Converts a `ModelClassExterior` object to a `ModelClassInterior` object
 *
 * @param {ModelClassExterior} model: the model to prepare
 */
function prepareModel(model: ModelClassExterior): ModelClassInterior {
  switch (model.object_type) {
    case 'BatteryStrategy':
    case 'BatteryConfiguration':
    case 'EVSEConfiguration':
    case 'EVSEStrategy':
    case 'SolarPVConfiguration':
    case 'SolarPVStrategy':
    case 'FuelSwitchingConfiguration':
    case 'FuelSwitchingStrategy':
    case 'Workspace':
      return model;
    case 'RatePlan':
      return serializers.serializeRatePlan(model);
    case 'CAISORate':
      return serializers.serializeCAISORate(model);
    case 'OriginFile':
      return serializers.serializeOriginFile(model);
    case 'CustomerMeter':
      return serializers.serializeMeter(model);
    case 'GHGRate':
    case 'RFPMeter':
    case 'RFPPortfolio':
    case 'SystemProfile':
      return model.serialize();
    case 'Scenario':
      return serializers.serializeScenario(model);
  }
}
