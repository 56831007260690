import * as React from 'react';

import * as api from 'navigader/api';
import {
  Button,
  Dialog,
  Grid,
  List,
  Menu,
  TableFactory,
  TextField,
  Typography,
} from 'navigader/components';
import { routes, usePushRouter } from 'navigader/routes';
import { slices } from 'navigader/store';
import { makeStylesHook } from 'navigader/styles';
import { Meter, OriginFile } from 'navigader/types';
import { formatters } from 'navigader/util';
import { useSnackbar } from 'navigader/util/hooks';

/** ============================ Types ===================================== */
type MetersTableProps = {
  originFile: OriginFile;
};

type MeterSubsetDialogProps = MetersTableProps & {
  open: boolean;
  onClose: () => void;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  () => ({
    tableContainer: {
      maxHeight: 500,
    },
  }),
  'MetersTable'
);

/** ============================ Components ================================ */
const MeterSubsetDialog: React.FC<MeterSubsetDialogProps> = ({ open, onClose, originFile }) => {
  const [SAIDs, setSAIDs] = React.useState<Array<Meter['metadata']['sa_id']>>([]);
  const [name, setName] = React.useState(originFile.name + '-subset');
  const routeTo = usePushRouter();
  const snackbar = useSnackbar();

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Content>
        <Grid>
          <Grid.Item span={12}>
            <Typography variant="h4">Paste SA IDs for Subset</Typography>
          </Grid.Item>
          <Grid.Item span={12}>
            <TextField.List label="SA IDs" onChange={setSAIDs} value={SAIDs} />
          </Grid.Item>
          <Grid.Item span={12}>
            <TextField label="Name" value={name} onChange={setName} />
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button _variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!SAIDs.length}
          _variant="text"
          color="primary"
          onClick={submitCreateSubset}
        >
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  );

  /** ============================== Callbacks =============================== */
  async function submitCreateSubset() {
    const resp = await api.createOriginFileSubset(originFile, SAIDs, name);
    if (resp.ok) {
      routeTo.page(routes.library.base)();
      const newOriginFile = await resp.json();
      snackbar.success(`${newOriginFile.name} was created successfully`);
    } else {
      const errorMsg = await resp.json();
      snackbar.error(errorMsg);
    }
  }
};

const Table = TableFactory<Meter>();
const MetersTable: React.FC<MetersTableProps> = ({ originFile }) => {
  const [subsetDialogOpen, setSubsetDialogOpen] = React.useState<boolean>(false);
  return (
    <>
      <Table
        aria-label="meter table"
        dataFn={(params) => api.getMeters({ meterGroupId: originFile.id, ...params })}
        dataSelector={slices.models.selectMeters}
        containerClassName={useStyles().tableContainer}
        title="Meters"
        raised
        headerActions={
          <Menu icon="verticalDots">
            <List>
              <List.Item onClick={clickDownloadReport}>
                <List.Item.Text>Download Meter Report</List.Item.Text>
              </List.Item>
              <List.Item onClick={() => setSubsetDialogOpen(true)}>
                <List.Item.Text>Create a Subset</List.Item.Text>
              </List.Item>
            </List>
          </Menu>
        }
      >
        {(meters) => (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell>SA ID</Table.Cell>
                <Table.Cell>Address</Table.Cell>
                <Table.Cell>Rate Plan</Table.Cell>
                <Table.Cell align="right">Maximum Monthly Demand (kW)</Table.Cell>
                <Table.Cell align="right">Total kWh</Table.Cell>
                {originFile.has_gas && <Table.Cell align="right">Total Therms</Table.Cell>}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {meters.map((meter) => (
                <Table.Row key={meter.id}>
                  <Table.Cell>{meter.metadata.sa_id}</Table.Cell>
                  <Table.Cell>{meter.metadata.address}</Table.Cell>
                  <Table.Cell>{meter.metadata.rate_plan_name}</Table.Cell>
                  <Table.Cell align="right">
                    {formatters.commas(formatters.maxDecimals(meter.max_monthly_demand, 2))}
                  </Table.Cell>
                  <Table.Cell align="right">
                    {formatters.commas(formatters.maxDecimals(meter.total_kwh, 2))}
                  </Table.Cell>
                  {originFile.has_gas && (
                    <Table.Cell align="right">
                      {formatters.commas(formatters.maxDecimals(meter.total_therms, 2)) ?? '-'}
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </>
        )}
      </Table>
      <MeterSubsetDialog
        open={subsetDialogOpen}
        onClose={closeSubsetDialog}
        originFile={originFile}
      />
    </>
  );
  /** ============================ Callbacks =============================== */
  async function clickDownloadReport() {
    api.downloadMeterReport(originFile);
  }
  function closeSubsetDialog() {
    setSubsetDialogOpen(false);
  }
};

/** ============================ Exports =================================== */
export default MetersTable;
