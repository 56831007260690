import { RawUserProfile, UserProfile } from 'navigader/types';
import { serializers } from 'navigader/util';
import { appendId, beoRoute, getRequest, parsePaginationSet, patchRequest } from './util';

/** ============================ Types ===================================== */
type GetUserProfilesResponse = { profiles: RawUserProfile[] };
type UpdateUserProfileParams = Pick<UserProfile, 'active_workspace'>;
type UpdateUserProfileResponse = { profile: RawUserProfile };

/** ============================ API Methods =============================== */
export async function getUserProfile() {
  const response = await getRequest(routes.profile()).then((res) => res.json());
  const parsed = parsePaginationSet<GetUserProfilesResponse, UserProfile>(
    response,
    ({ profiles }) => profiles.map((p) => ({ ...p, object_type: 'UserProfile' }))
  );
  return parsed && parsed.count === 1 ? parsed.data[0] : undefined;
}

export async function updateUserProfile(id: UserProfile['id'], params: UpdateUserProfileParams) {
  const response: UpdateUserProfileResponse = await patchRequest(
    routes.profile(id),
    params
  ).then((res) => res.json());
  return serializers.parseUserProfile(response.profile);
}

/** ============================ Helpers =================================== */
const baseRoute = (rest: string) => beoRoute.v1(`user/${rest}`);
const routes = {
  profile: appendId(baseRoute('profile')),
};
