import * as React from 'react';

import { Toggle } from '../ToggleButton';

/** ============================ Types ===================================== */
type YearSelectorExclusiveProps = {
  years: number[];
  selected: number | undefined;
  onChange: (newYear: number) => void;
};

/** ============================ Components ================================ */
export const YearSelectorExclusive: React.FC<YearSelectorExclusiveProps> = (props) => {
  const { years, onChange, selected } = props;
  return (
    <Toggle.Group exclusive onChange={onChange} size="small" value={selected}>
      {years.map((year) => (
        <Toggle.Button key={year} value={year} aria-label={year}>
          {year}
        </Toggle.Button>
      ))}
    </Toggle.Group>
  );
};
