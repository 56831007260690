import _ from 'lodash';

import {
  GetScenarioQueryParams,
  OriginFileDynamicRestParams,
  OriginFileQueryParams,
  ScenarioDynamicRestParams,
} from 'navigader/api';
import { GHGRate, SystemProfile, RFPPortfolio, RFPMeter, Workspace } from 'navigader/models';
import { createSelector } from 'navigader/store/selectors';
import {
  DataObject,
  DataTypeFilters,
  DataTypeParams,
  DynamicRestParams,
  isOriginFile,
  isScenario,
  Maybe,
  MeterGroup,
  OriginFile,
  RootState,
  Scenario,
} from 'navigader/types';
import { omitFalsey, serializers } from 'navigader/util';

/** ======================== Simple retrievals ============================== */
export const selectDERConfigurations = (state: RootState) => state.models.derConfigurations;
export const selectDERStrategies = (state: RootState) => state.models.derStrategies;
export const selectGHGRates = (state: RootState) => state.models.ghgRates.map(GHGRate.fromObject);
export const selectMeters = (state: RootState) => state.models.meters.map(serializers.parseMeter);
export const selectHasMeterGroups = (state: RootState) => state.models.hasMeterGroups;
export const selectRatePlans = (state: RootState) =>
  state.models.ratePlans.map(serializers.parseRatePlan);
export const selectSystemProfiles = (state: RootState) =>
  state.models.systemProfiles.map(SystemProfile.fromObject);
export const selectUserProfile = (state: RootState) => state.models.userProfile;
export const selectWorkspaces = (state: RootState) =>
  state.models.workspaces.map(Workspace.fromObject);

/** ======================== CAISO Rates ==================================== */
export const selectCAISORates = (state: RootState) =>
  state.models.caisoRates.map(serializers.parseCAISORate);
export const selectCAISORatesWithFilters = createSelector(
  selectCAISORates,
  (_, filters?: DataTypeFilters) => filters,
  (caisoRates, filters) => caisoRates.filter((rate) => passesDataFilters(rate, filters))
);

/** ======================== Origin files =================================== */
export const selectMeterGroup =
  (id?: MeterGroup['id'], params?: DataTypeParams) => (state: RootState) => {
    const raw = _.find(state.models.meterGroups, { id });
    const meterGroup = raw && serializers.parseMeterGroup(raw);
    if (passesDataFilters(meterGroup, params)) return meterGroup;
  };

export const selectOriginFile =
  (id?: OriginFile['id'], params?: OriginFileQueryParams) =>
  (state: RootState): Maybe<OriginFile> => {
    const originFiles = state.models.meterGroups.filter(isOriginFile);
    const raw = _.find(originFiles, { id });
    const originFile = raw && serializers.parseOriginFile(raw);
    if (
      passesOriginFileDynamicRestFilters(originFile, params) &&
      passesDataFilters(originFile, params)
    )
      return originFile;
  };

export const selectOriginFiles = (state: RootState) => {
  const originFiles = _.filter(state.models.meterGroups, isOriginFile);
  return originFiles.map(serializers.parseOriginFile);
};

/** ======================== Scenarios ====================================== */
export const selectScenario =
  (id: Scenario['id'], params?: GetScenarioQueryParams) => (state: RootState) => {
    const scenarios = _.filter(state.models.meterGroups, isScenario);
    const raw = _.find(scenarios, { id });
    const scenario = raw && serializers.parseScenario(raw, state.models.meterGroups);
    if (passesDataFilters(scenario, params) && passesScenarioDynamicRestFilters(scenario, params))
      return scenario;
  };

export const selectScenarios = (state: RootState) => {
  const scenarios = _.filter(state.models.meterGroups, isScenario);
  return scenarios.map((scenario) => serializers.parseScenario(scenario, state.models.meterGroups));
};

export const selectScenariosWithFilters = createSelector(
  selectScenarios,
  (_, filters: ScenarioDynamicRestParams & DataTypeFilters) => filters,
  (scenarios, filters) =>
    scenarios.filter(
      (scenario) =>
        passesDataFilters(scenario, filters) && passesDynamicRestFilters(scenario, filters)
    )
);

/** ======================== RFP Validations ================================ */
type SelectPortfolioParams = readonly [string, Maybe<DataTypeFilters>];

// Portfolios
export const selectRFPPortfolios = (state: RootState) =>
  state.models.rfpPortfolios.map(RFPPortfolio.fromObject);
export const selectRFPPortfolio = createSelector(
  selectRFPPortfolios,
  (_, params: SelectPortfolioParams) => params,
  (portfolios, params) => {
    const [id, dataFilters] = params;
    const portfolio = _.find(portfolios, { id });
    if (passesDataFilters(portfolio, dataFilters)) return portfolio;
  }
);

// Meters
export const selectRFPMeters = (state: RootState) =>
  state.models.rfpMeters.map(RFPMeter.fromObject);

/** ======================== Filter functions =============================== */
/**
 * Applies common data filters to a given model. If the model is not undefined and passes the
 * filters, returns `true`. Otherwise, returns `false`.
 *
 * @param {DataObject|undefined} model: the model to apply the filters to, if any
 * @param {DataTypeFilters|undefined} filters: the data filters, if any
 */
export function passesDataFilters(model: Maybe<DataObject>, filters: Maybe<DataTypeFilters>) {
  if (!model) return false;
  if (!filters) return true;

  if (filters.data_types) {
    const typesNeeded = _.isArray(filters.data_types) ? filters.data_types : [filters.data_types];
    const typesPresent = Object.keys(omitFalsey(model.data));
    if (!_.every(typesNeeded.map((type) => typesPresent.includes(type)))) {
      return false;
    }
  }

  if (filters.period) {
    const intervalData = model.data.default;
    if (!intervalData) return false;
    if (intervalData.period !== filters.period) return false;
  }

  return true;
}

/**
 * Applies a set of dynamic rest filters to a model, returning `true` if the model meets all the
 * filters and `false` if any do not pass. Note that `_.every` will return `true` if an empty filter
 * object is passed in.
 *
 * @param {object} model: the model to apply the filters to
 * @param {DynamicRestParams} [params]: the dynamic rest filters to apply
 */
export function passesDynamicRestFilters(model: Maybe<object>, params?: DynamicRestParams) {
  return _.every(params?.filter, (clause, field) => {
    const value = _.get(model, field);
    switch (clause.operation) {
      case 'in':
        return _.includes(clause.value, value);
      case 'equals':
        return value === clause.value;
    }
  });
}

/**
 * Applies origin file-specific dynamic rest filters to an origin file, returning `true` if the
 * meter group meets all the filters and `false` if any do not pass.
 *
 * @param {OriginFile} originFile: the origin file to apply the filters to
 * @param {OriginFileDynamicRestParams} [params]: the origin file-specific dynamic rest params to apply
 */
function passesOriginFileDynamicRestFilters(
  originFile?: OriginFile,
  params?: OriginFileDynamicRestParams
) {
  if (!params?.include) return true;
  const includeParams = typeof params.include === 'string' ? [params.include] : params.include;
  return _.every(includeParams, (param) => {
    switch (param) {
      case 'total_therms':
        // Only applies to origin files. If `total_therms` is undefined and `has_gas` is falsey,
        // we return `true` because we can be certain there's nothing to gain from re-querying.
        if (isOriginFile(originFile) && originFile.has_gas) {
          return !_.isUndefined(originFile.total_therms);
        } else {
          return true;
        }
    }
  });
}

/**
 * Applies scenario-specific dynamic rest filters to a scenario, returning `true` if the scenario
 * meets all the filters and `false` if any do not pass
 *
 * @param {Scenario} scenario: the scenario to apply the filters to
 * @param {ScenarioDynamicRestParams} [params]: the scenario-specific dynamic rest params to apply
 */
function passesScenarioDynamicRestFilters(scenario?: Scenario, params?: ScenarioDynamicRestParams) {
  if (!scenario) return false;
  if (!passesDynamicRestFilters(scenario, params)) return false;

  // Check for each of the `include` params
  if (!params?.include) return true;
  const includeParams = typeof params.include === 'string' ? [params.include] : params.include;
  return _.every(includeParams, (param) => {
    switch (param) {
      case 'der_stack':
        return !_.isUndefined(scenario.der_stack);
      case 'meter_group':
        return !_.isUndefined(scenario.meter_group_id);
      case 'meter_group.*':
        return !_.isUndefined(scenario.meter_group);
      case 'report':
        return !_.isUndefined(scenario.report);
      case 'report_summary':
        return !_.isUndefined(scenario.report_summary);
    }
  });
}
