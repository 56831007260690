import _ from 'lodash';

import store, { slices } from 'navigader/store';
import {
  DataTypeParams,
  DynamicRestParams,
  IdType,
  Meter,
  MeterGroup,
  OriginFile,
  PaginationQueryParams,
  RawMeter,
  RawMeterGroup,
  RawOriginFile,
  RawPaginationSet,
} from 'navigader/types';
import { filterClause, serializers } from 'navigader/util';
import {
  appendId,
  beoRoute,
  deleteRequest,
  downloadFile,
  getRequest,
  postRequest,
  makeFormXhrPost,
  parsePaginationSet,
} from './util';

/** ============================ Types ===================================== */
/** Query params */
type MeterQueryParams = PaginationQueryParams &
  DataTypeParams & {
    meterGroupId: MeterGroup['id'];
  };

export type OriginFilesQueryParams = OriginFileQueryParams & PaginationQueryParams;
export type OriginFileQueryParams = OriginFileDynamicRestParams & DataTypeParams;
export type OriginFileDynamicRestParams = DynamicRestParams<'total_therms'>;

/** Responses */
type GetOriginFilesResponse = { origin_files: RawOriginFile[] };
type GetOriginFileResponse = { origin_file: RawOriginFile };
type GetMeterGroupResponse = { meter_group: RawMeterGroup };
type GetMetersResponse = RawPaginationSet<{ customer_meters: RawMeter[] }>;

/** ============================ API Methods =============================== */
export async function getOriginFiles(queryParams: OriginFilesQueryParams) {
  const response: RawPaginationSet<GetOriginFilesResponse> = await getRequest(
    routes.originFile(),
    queryParams
  ).then((res) => res.json());

  return parsePaginationSet(response, ({ origin_files }) =>
    origin_files.map(serializers.parseOriginFile)
  );
}

export async function getMeterGroup(uuid: string, queryParams?: DataTypeParams) {
  const response: GetMeterGroupResponse = await getRequest(
    routes.meterGroup(uuid),
    queryParams
  ).then((res) => res.json());

  // Parse the meter group results
  return serializers.parseMeterGroup(response.meter_group);
}

export async function getOriginFile(uuid: string, queryParams?: OriginFileQueryParams) {
  const response: GetOriginFileResponse = await getRequest(
    routes.originFile(uuid),
    queryParams
  ).then((res) => res.json());

  // Parse the meter group results
  return serializers.parseOriginFile(response.origin_file);
}

/**
 * Downloads a CSV report of meter data for MeterGroup
 *
 * @param {MeterGroup} meterGroup: The meterGroup to fetch report data from
 */
export async function downloadMeterReport(meterGroup: MeterGroup) {
  const url = routes.originFile.downloadReport(meterGroup.id);
  return downloadFile(url, `${meterGroup.name}-meter_report.csv`);
}

/**
 * Creates a new OriginFile from this OriginFile and a subset of SA IDs
 *
 * @param {OriginFile} originFile: The originFile to create subset from
 * @param {Meter.metadata.sa_id[]} SAIDs: the list of SA IDs
 * @param {string} name: the name of the subset
 */
export async function createOriginFileSubset(
  originFile: OriginFile,
  SAIDs: Array<Meter['metadata']['sa_id']>,
  name: string
) {
  const url = routes.originFile.createSubset(originFile.id);
  return await postRequest(url, { sa_ids: SAIDs, name });
}

export function postOriginFile(file: File, name: string) {
  return makeFormXhrPost(routes.originFile(), { file, name });
}

export async function getMeters(queryParams: MeterQueryParams) {
  const response: GetMetersResponse = await getRequest(routes.meter(), {
    ..._.omit(queryParams, 'meterGroupId'),
    filter: {
      meter_groups: filterClause.equals(queryParams.meterGroupId),
    },
  }).then((res) => res.json());

  // Parse the meter results
  const paginationSet = parsePaginationSet(response, ({ customer_meters: meters }) =>
    meters.map(serializers.parseMeter)
  );

  // Add models to the store and return
  store.dispatch(slices.models.updateModels(paginationSet.data));
  return paginationSet;
}

/**
 * Deletes a meter group given the ID
 *
 * @param {string} id: the ID of the meter group
 */
export async function deleteOriginFile(id: string) {
  return await deleteRequest(routes.originFile(id));
}

/** ============================ Helpers =================================== */
const baseRoute = (rest: string) => beoRoute.v1(`load/${rest}`);
const routes = {
  meter: appendId(baseRoute('meter')),
  meterGroup: appendId(baseRoute('meter_group')),
  originFile: Object.assign(appendId(baseRoute('origin_file')), {
    createSubset: (id: IdType) => routes.originFile(id) + 'create_subset/',
    downloadReport: (id: IdType) => routes.originFile(id) + 'download_report/',
  }),
};
