import _ from 'lodash';
import * as React from 'react';

import * as api from 'navigader/api';
import { HoverText, Table } from 'navigader/components';
import { SolarModuleConfigParams } from 'navigader/types';
import { models } from 'navigader/util';

import { ConfigurationsTable } from '../common';
import { SolarConfigurationDialog } from './ConfigurationDialog';

/** ============================== Types =================================== */
type MultipleModuleTableCellProps = {
  params: SolarModuleConfigParams[];
  field: keyof SolarModuleConfigParams;
  render?: (value: any) => string | React.ReactElement;
};

/** ============================ Components ================================ */
const MultipleModuleTableCell: React.FC<MultipleModuleTableCellProps> = (props) => {
  const { params, field, render = (value) => value } = props;
  const values = _.uniq(_.reverse(_.sortBy(params, 'weight')).map((param) => param[field]));
  const weightiest = render(values[0]);
  return (
    <Table.Cell align="right">
      {values.length > 1 ? (
        <HoverText
          text={values
            .map((v) => `${render(v)} (${_.sumBy(_.filter(params, { [field]: v }), 'weight')}%)`)
            .join(', ')}
        >
          <div>{weightiest}</div>
        </HoverText>
      ) : (
        <div>{weightiest}</div>
      )}
    </Table.Cell>
  );
};

export const SolarConfigurationsTable: React.FC = () => {
  return (
    <ConfigurationsTable
      dataFn={(params) =>
        api.getDerConfigurations({ ...params, der_type: 'solarpv', include: 'data' })
      }
      Dialog={SolarConfigurationDialog}
      configurationHeaders={
        <>
          <Table.Cell align="right">ZIP</Table.Cell>
          <Table.Cell align="right">Array Type</Table.Cell>
          <Table.Cell align="right">Azimuth</Table.Cell>
          <Table.Cell align="right">Tilt</Table.Cell>
        </>
      }
      configurationData={(configuration) => {
        const params = configuration.data!.parameters;
        return (
          <>
            <MultipleModuleTableCell params={params} field="address" />
            <MultipleModuleTableCell
              params={params}
              field="array_type"
              render={(value) => models.der.renderSolarArrayType(value)}
            />
            <MultipleModuleTableCell
              params={params}
              field="azimuth"
              render={(value) => `${value}°`}
            />
            <MultipleModuleTableCell params={params} field="tilt" render={(value) => `${value}°`} />
          </>
        );
      }}
    />
  );
};
