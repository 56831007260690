import * as React from 'react';

import {
  Button,
  Divider,
  FileDownload,
  ImpactColumn,
  Link,
  List,
  Menu,
  PageHeader,
  StandardDate,
  StatusIndicator,
  TableFactory,
} from 'navigader/components';
import { RFPPortfolio } from 'navigader/models';
import { routes, usePushRouter } from 'navigader/routes';
import { slices } from 'navigader/store';
import { formatters } from 'navigader/util';
import { useTableRef } from 'navigader/util/hooks';

import { DeleteRFPPortfolioDialog, NewRFPPortfolioDialog } from './Dialogs';

/** ============================ Components ================================ */
const Table = TableFactory<RFPPortfolio>();
export const RFPPortfolioDashboard = () => {
  const [newDialogOpenState, setNewDialogOpenState] = React.useState(false);
  const [deletePortfolio, setDeletePortfolio] = React.useState<RFPPortfolio>();
  const routeTo = usePushRouter();
  const tableRef = useTableRef<RFPPortfolio>();
  return (
    <>
      <PageHeader
        actions={
          <Button color="secondary" onClick={openNewRFPPortfolioDialog}>
            New RFP Portfolio
          </Button>
        }
        title="RFP Validations"
      />

      <Table
        aria-label="scenarios table"
        dataFn={RFPPortfolio.api.list}
        dataSelector={slices.models.selectRFPPortfolios}
        initialSorting={{ dir: 'desc', key: 'created_at' }}
        raised
        ref={tableRef}
      >
        {(portfolios, EmptyRow) => (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell />
                <Table.Cell sortBy="name">Name</Table.Cell>
                <Table.Cell sortBy="created_at" sortDir="desc">
                  Created
                </Table.Cell>
                <ImpactColumn.Header
                  column="Usage Impact"
                  info={{
                    measuresImpact: 'in customer electricity usage',
                    negativeMeans: 'electricity consumption from the grid has gone down',
                    positiveMeans: 'electricity consumption from the grid has gone up',
                  }}
                  units="kWh"
                />
                <ImpactColumn.Header
                  column="GHG Impact"
                  info={{
                    measuresImpact: 'in GHG emissions',
                    negativeMeans: 'GHG emissions have gone down',
                    positiveMeans: 'GHG emissions have gone up',
                  }}
                  units={
                    <>
                      tCO<sub>2</sub>
                    </>
                  }
                />
                <ImpactColumn.Header
                  column="RA Impact"
                  info={{
                    measuresImpact: 'to resource adequacy requirements',
                    negativeMeans: 'resource adequacy requirements have gone down',
                    positiveMeans: 'resource adequacy requirements have gone up',
                  }}
                  units="$"
                />
                <ImpactColumn.Header
                  column="Procurement Impact"
                  info={{
                    measuresImpact: 'to expenses incurred procuring electricity',
                    negativeMeans: 'CCA procurement expenses have gone down',
                    positiveMeans: 'CCA procurement expenses have gone up',
                  }}
                  units="$"
                />
                <ImpactColumn.Header
                  column="Revenue Impact"
                  info={{
                    measuresImpact: "to CCA's electricity sales",
                    negativeMeans: 'revenues from electricity sales have gone down',
                    positiveMeans: 'revenues from electricity sales have gone up',
                  }}
                  units="$"
                />
                <ImpactColumn.Header
                  column="Expenses Impact"
                  info={{
                    measuresImpact:
                      'to overall expenses. Calculated as procurement expenses plus $/kW RA impacts',
                    negativeMeans: 'overall expenses have gone down',
                    positiveMeans: 'overall expenses have gone up',
                  }}
                  units="$"
                />
                <ImpactColumn.Header
                  column="Profits Impact"
                  info={{
                    measuresImpact: 'to overall profits. Calculated as revenues minus expenses',
                    negativeMeans: 'overall profits have gone down',
                    positiveMeans: 'overall profits have gone up',
                  }}
                  units="$"
                />
                <Table.Cell>Menu</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {/** Only renders if there's no data */}
              <EmptyRow>
                None created.{' '}
                <Button.Text
                  color="primary"
                  icon="plus"
                  onClick={openNewRFPPortfolioDialog}
                  size="small"
                >
                  Upload RFP Response.
                </Button.Text>
              </EmptyRow>

              {portfolios.map((portfolio) => (
                <Table.Row key={portfolio.id}>
                  <Table.Cell>
                    <StatusIndicator object={portfolio} />
                  </Table.Cell>
                  <Table.Cell>
                    {portfolio.progress.is_complete ? (
                      <Link to={routes.rfp.portfolio(portfolio.id)}>{portfolio.name}</Link>
                    ) : (
                      portfolio.name
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <StandardDate date={portfolio.created_at} />
                  </Table.Cell>
                  <Table.Cell align="right">
                    {formatters.commas(formatters.maxDecimals(portfolio.report.UsageDelta, 2)) ??
                      '--'}
                  </Table.Cell>
                  <ImpactColumn
                    costCalculation={portfolio.report.CSP2022Delta}
                    costFnClass="ghg_rate"
                    simulation={portfolio}
                  >
                    {(n) => formatters.commas(formatters.maxDecimals(n, 2)) ?? '--'}
                  </ImpactColumn>
                  <ImpactColumn
                    costCalculation={portfolio.report.RACostDelta}
                    costFnClass="system_profile"
                    simulation={portfolio}
                  >
                    {(n) => formatters.dollars(n) ?? '--'}
                  </ImpactColumn>
                  <ImpactColumn
                    costCalculation={portfolio.report.ProcurementCostDelta}
                    costFnClass="procurement_rate"
                    simulation={portfolio}
                  >
                    {(n) => formatters.dollars(n) ?? '--'}
                  </ImpactColumn>
                  <ImpactColumn
                    costCalculation={portfolio.report.BillRevenueDelta?.at(0)}
                    costFnClass="rate_plan"
                    simulation={portfolio}
                  >
                    {(n) => formatters.dollars(n) ?? '--'}
                  </ImpactColumn>
                  <Table.Cell align="right">
                    {formatters.dollars(portfolio.report.ExpenseDelta) ?? '-'}
                  </Table.Cell>
                  <Table.Cell align="right">
                    {formatters.dollars(portfolio.report.ProfitDelta?.at(0)) ?? '-'}
                  </Table.Cell>
                  <Table.Cell>
                    <Menu
                      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                      icon="verticalDots"
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <List.Item onClick={() => routeTo.rfp.portfolio(portfolio.id)}>
                        <List.Item.Icon icon="launch" />
                        <List.Item.Text>View</List.Item.Text>
                      </List.Item>

                      <FileDownload
                        disabled={!portfolio.progress.is_complete}
                        downloadFn={(cb) => RFPPortfolio.api.downloadReport(portfolio, cb)}
                        tooltipText={null}
                      >
                        <List.Item>
                          <List.Item.Icon icon="download" />
                          <List.Item.Text>Download Results</List.Item.Text>
                        </List.Item>
                      </FileDownload>

                      <Divider />

                      <List.Item onClick={() => setDeletePortfolio(portfolio)}>
                        <List.Item.Icon icon="trash" />
                        <List.Item.Text>Delete</List.Item.Text>
                      </List.Item>
                    </Menu>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </>
        )}
      </Table>

      <NewRFPPortfolioDialog
        onClose={() => setNewDialogOpenState(false)}
        open={newDialogOpenState}
        tableRef={tableRef}
      />

      <DeleteRFPPortfolioDialog
        onClose={() => setDeletePortfolio(undefined)}
        portfolio={deletePortfolio}
      />
    </>
  );

  /** ========================== Callbacks ================================= */
  function openNewRFPPortfolioDialog() {
    setNewDialogOpenState(true);
  }
};
