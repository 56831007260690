import _ from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as api from 'navigader/api';
import {
  Alert,
  Button,
  Dialog,
  Grid,
  List,
  Progress,
  Radio,
  Select,
  Tabs,
  Toggle,
  Typography,
} from 'navigader/components';
import { SystemProfile } from 'navigader/models';
import { makeStylesHook } from 'navigader/styles';
import {
  BatteryStrategy,
  ChargeSource,
  CostFunction,
  DERStrategyObjective,
  Maybe,
  MonthSeries,
} from 'navigader/types';
import { models, omitFalsey, toMonthSeries } from 'navigader/util';
import { useCostFunctions, useDownloadCallback, useMergeState } from 'navigader/util/hooks';

import {
  BooleanField,
  createDERStrategy,
  DescriptionField,
  DialogContext,
  DialogContextType,
  DialogProps,
  DialogState,
  FileField,
  NameField,
  NonFieldError,
  TextField,
} from '../common';

/** ============================ Constants ================================= */
const caLaw =
  'CA law prohibits a battery from discharging to the grid if it also charges from the grid.';
const chargeDischargeText = {
  charging: 'Changing the charging source has implications for the discharging strategy. ' + caLaw,
  discharging: 'Changing the discharge strategy has implications for the charging source. ' + caLaw,
};

/** ============================ Types ===================================== */
type BatteryStrategyFields = {
  charge_schedule: File;
  charge_source: ChargeSource;
  cost_function: CostFunction;
  description: string;
  discharge_schedule: File;
  discharge_to_grid: boolean;
  name: string;
  objective: Maybe<DERStrategyObjective>;
  ra_discharge_schedule: File;
  ra_discharge_system_profile: SystemProfile;
  reserve_percentages: Maybe<string>;
};

type DialogTab = 'From File Upload' | 'From Parameters';
type CostFunctionClass = 'ghgRate' | 'systemProfile';
type BatteryStrategyDialogProps = DialogProps<BatteryStrategy>;
type BatteryStrategyDialogState = DialogState<BatteryStrategyFields> & {
  cost_function_class?: CostFunctionClass;
  tab: DialogTab;
};

type TemplateMenuProps = { ra?: boolean };
type RAScheduleType = 'file' | 'systemProfile';

/** ============================ Styles ==================================== */
const useFromFileUploadStyles = makeStylesHook(
  (theme) => ({
    list: { '& li': { marginBottom: theme.spacing(1) } },
    grid: { '& > .centered': { textAlign: 'center' } },
    raSelector: { paddingTop: 10 },
  }),
  'FromFileUpload'
);

/** ============================ Components ================================ */
export const BatteryStrategyDialog: React.FC<BatteryStrategyDialogProps> = (props) => {
  const { closeDialog, open, tableRef } = props;
  const dispatch = useDispatch();

  // State
  const initialState: BatteryStrategyDialogState = {
    charge_schedule: undefined,
    charge_source: 'grid',
    cost_function: undefined,
    cost_function_class: 'ghgRate',
    creating: false,
    description: undefined,
    discharge_schedule: undefined,
    discharge_to_grid: false,
    errors: {},
    objective: undefined,
    ra_discharge_schedule: undefined,
    ra_discharge_system_profile: undefined,
    reserve_percentages: undefined,
    tab: 'From File Upload',
  };

  const [state, setState] = useMergeState(initialState);
  const { creating } = state;
  const canSubmit = stateIsValid(state) && !creating;

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={closeDialog}>
      <Dialog.Title>Create Battery Strategy</Dialog.Title>
      <Dialog.Content>
        <DialogContext.Provider value={{ setState, state }}>
          <Grid>
            <Grid.Item span={12}>
              <NameField />
            </Grid.Item>

            <Grid.Item span={12}>
              <Tabs initialTab={initialState.tab} onChange={handleTabChange}>
                <Tabs.Tab title="From File Upload">
                  <FromFileUpload />
                </Tabs.Tab>
                <Tabs.Tab title="From Parameters">
                  <FromParameters />
                </Tabs.Tab>
              </Tabs>
            </Grid.Item>

            <Grid.Item span={12}>
              <DescriptionField />
            </Grid.Item>

            <NonFieldError />
          </Grid>
        </DialogContext.Provider>
      </Dialog.Content>
      {creating && <Progress />}
      <Dialog.Actions>
        <Button.Text onClick={cancel}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={create}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ========================== Callbacks ================================= */
  function handleTabChange(tab: string) {
    setState({ tab: tab as DialogTab });
  }

  function cancel() {
    closeDialog();
    setState(initialState);
  }

  async function create() {
    // The "Create" button should not allow submitting unless the state is valid, so this validation
    // is redundant but also solves type-checking issues.
    if (!stateIsValid(state)) return;

    // Compile the request parameters
    const fields = ((): api.CreateDERStrategyParams => {
      const {
        charge_schedule,
        charge_source,
        cost_function,
        description,
        discharge_schedule,
        discharge_to_grid,
        name,
        objective,
        ra_discharge_schedule,
        ra_discharge_system_profile,
        reserve_percentages,
        tab,
      } = state;

      const commonParams = { charge_source, description, name, der_type: 'battery' as const };
      switch (tab) {
        case 'From File Upload':
          return {
            ...commonParams,
            charge_schedule,
            discharge_schedule,
            objective,
            ra_discharge_schedule,
            ra_discharge_system_profile: ra_discharge_system_profile?.id,
            reserve_percentages: formatReservePercentage(reserve_percentages),
          };
        case 'From Parameters':
          return {
            ...commonParams,
            discharge_to_grid,
            cost_function: _.pick(cost_function, ['id', 'object_type']),
          };
      }
    })();

    // Attempt to create the strategy
    const success = await createDERStrategy(fields, setState, dispatch);
    if (!success) return;

    // If the request succeeds, close the dialog, re-fetch the table and reset the state
    closeDialog();
    tableRef.current?.fetch();
    setState(initialState);
  }

  /** ========================== Helpers =================================== */
  function getEmptyFieldsForTab(state: BatteryStrategyDialogState, tab: DialogTab) {
    const requiredFields = ((): Array<keyof BatteryStrategyDialogState> => {
      switch (tab) {
        case 'From File Upload':
          return ['charge_schedule', 'charge_source', 'discharge_schedule', 'name'];
        case 'From Parameters':
          return ['charge_source', 'cost_function', 'discharge_to_grid', 'name'];
      }
    })();

    return requiredFields.filter((field) => _.isUndefined(state[field]));
  }

  function stateIsValid(
    state: BatteryStrategyDialogState
  ): state is Required<BatteryStrategyDialogState> {
    const noErrors = _.isEmpty(omitFalsey(state.errors));
    const hasRequiredProps = _.isEmpty(getEmptyFieldsForTab(state, state.tab));
    return noErrors && hasRequiredProps;
  }

  function formatReservePercentage(percentageStr: Maybe<string>) {
    if (!percentageStr) return;
    const monthValues = percentageStr.replace(/^,+|,+$/g, '').split(',');
    const numValues = monthValues.length;

    switch (numValues) {
      case 1:
        return toMonthSeries(parseFloat(monthValues[0]));
      case 12:
        return monthValues.map(parseFloat) as MonthSeries<number>;
    }
  }
};

const FromFileUpload: React.FC = () => {
  const classes = useFromFileUploadStyles();
  const { setState, ra_discharge_system_profile } = useDialogState();
  const [raScheduleType, setRAScheduleType] = React.useState<RAScheduleType>('file');

  // Get System Profiles for RA Schedule
  const { systemProfiles } = useCostFunctions();
  const objectives = [
    'load_flattening',
    'reduce_bill',
    'reduce_ghg',
    'reduce_cca_finance',
  ] as DERStrategyObjective[];

  return (
    <Grid className={classes.grid}>
      <Grid.Item span={4}>
        <ChargeSourceField />
      </Grid.Item>
      <Grid.Item span={4}>
        {/** Pass `value={undefined}` so the input remains uncontrolled */}
        <Radio.Group label="Objective" onChange={updateObjective} value={undefined}>
          {objectives.map((objective) => (
            <Radio
              key={objective}
              label={models.der.formatStrategyType(objective)}
              value={objective}
            />
          ))}
        </Radio.Group>
      </Grid.Item>
      <Grid.Item span={4}>
        <TextField
          field="reserve_percentages"
          label="Reserve Percentage"
          extraValidations={validateReservePercentage}
        />
      </Grid.Item>

      <Grid.Item span={4}>
        <FileField
          field="charge_schedule"
          label="Charge schedule"
          required
          templateMenu={<TemplateMenu />}
        />
      </Grid.Item>

      <Grid.Item span={4}>
        <FileField
          field="discharge_schedule"
          label="Discharge schedule"
          required
          templateMenu={<TemplateMenu />}
        />
      </Grid.Item>

      <Grid.Item span={4}>
        <Toggle.Group size="small" exclusive onChange={updateRAScheduleType} value={raScheduleType}>
          <Toggle.Button value="file">Static RA</Toggle.Button>
          <Toggle.Button value="systemProfile">Dynamic RA</Toggle.Button>
        </Toggle.Group>
        <div className={classes.raSelector}>
          {raScheduleType === 'file' ? (
            <FileField
              field="ra_discharge_schedule"
              label="RA Schedule"
              templateMenu={<TemplateMenu ra />}
            />
          ) : (
            <Select
              label="System Profile"
              minWidth="90%"
              onChange={updateRASchedule}
              options={systemProfiles}
              renderOption="name"
              sorted
              value={ra_discharge_system_profile}
            />
          )}
        </div>
      </Grid.Item>

      <Grid.Item span={12}>
        <Alert title="How to format the 288 CSV" type="info">
          Month-hour values in the battery strategy's charge/discharge schedules constrain battery
          operations based on meter load. The values should be numeric, or the special strings{' '}
          <Inf /> or <NegInf />. In any given interval, the battery strategy will determine its
          operational mode (charging, discharging or neither) by comparing the meter load to the
          appropriate month-hour value in each 288:
          <ul className={classes.list}>
            <li>
              When the value in the <em>charge schedule</em> is greater than the meter load, the
              battery strategy is in charging mode. Typical values include <Inf /> (meaning the
              battery should be in charging mode unconditionally), <Zero /> (meaning the battery
              should be in charging mode only if the meter load is negative, e.g. for NEM exports)
              and <NegInf /> (meaning the battery should not be in charging mode). When creating a
              strategy to charge from solar production, use <Inf /> instead of <Zero /> to indicate
              charging hours.
            </li>
            <li>
              When the value in the <em>discharge schedule</em> is less than the meter load, the
              battery strategy is in discharging mode. Typical values include <Inf /> (meaning the
              battery should not be in discharging mode), <Zero /> (meaning the battery should be in
              discharging mode only if the meter load is positive, e.g. for offsetting site load)
              and <NegInf /> (meaning the battery be in discharging mode unconditionally).
            </li>
            <li>
              When the meter load is greater than the discharge schedule's value and less than the
              charge schedule's value, the battery is in charging mode. The battery cannot charge
              and discharge in the same interval.
            </li>
          </ul>
        </Alert>
      </Grid.Item>
    </Grid>
  );

  /** ========================== Callbacks ================================= */
  function updateObjective(objective: DERStrategyObjective) {
    setState({ objective });
  }

  function updateRAScheduleType(type: RAScheduleType) {
    setState({ ra_discharge_schedule: undefined, ra_discharge_system_profile: undefined });
    setRAScheduleType(type);
  }

  function updateRASchedule(profile: SystemProfile) {
    setState({ ra_discharge_system_profile: profile });
  }

  /** ========================== Helpers =================================== */
  /** Validates the "Reserve Percentage" input */
  function validateReservePercentage(value: string) {
    const label = 'Reserve Percentage';

    // Remove commas at the front and back
    const monthValues = value.replace(/^,+|,+$/g, '').split(',');
    const numValues = monthValues.length;
    switch (numValues) {
      case 1:
        return validateElement(value);
      case 12:
        return _.first(monthValues.map(validateElement));
      default:
        return `${label} has an invalid number of entries: ${numValues}`;
    }

    function validateElement(percentStr: string) {
      const percent = parseFloat(percentStr);
      if (_.isNaN(percent)) return `${label} must be numeric`;
      if (percent < 0 || percent > 100) return `${label} must be in the range [0, 100]`;
    }
  }
};

const TemplateMenu: React.FC<TemplateMenuProps> = ({ ra = false }) => {
  const intervalFile = ra ? 'ra' : 'interval';
  const getPath = (name: string) => `/downloads/der/${name}_template.csv`;
  const download288Template = useDownloadCallback(getPath('288'));
  const downloadIntervalTemplate = useDownloadCallback(getPath(intervalFile));
  return (
    <>
      <List.Item onClick={download288Template}>
        <List.Item.Text>Download 288 Template</List.Item.Text>
      </List.Item>
      <List.Item onClick={downloadIntervalTemplate}>
        <List.Item.Text>Download Interval Template</List.Item.Text>
      </List.Item>
    </>
  );
};

// Shorthands for use in the explainer
const Inf: React.FC = () => <Typography.Code>inf</Typography.Code>;
const NegInf: React.FC = () => <Typography.Code>-inf</Typography.Code>;
const Zero: React.FC = () => <Typography.Code>0</Typography.Code>;

const FromParameters: React.FC = () => {
  const { setState, charge_source, cost_function, cost_function_class } = useDialogState();

  // Organize the cost functions into sections
  const costFunctions = useCostFunctions();
  const costFunctionsOfClass = cost_function_class && costFunctions[`${cost_function_class}s`];

  return (
    <Grid>
      <Grid.Item span={6}>
        <ChargeSourceField />
      </Grid.Item>
      <Grid.Item span={6}>
        <BooleanField
          extraStateChanges={(dischargeToGrid) => {
            // Toggle the charging state if battery is now discharging to grid and charging
            // from grid
            if (dischargeToGrid && charge_source === 'grid') {
              return { charge_source: 'nem' };
            }
          }}
          field="discharge_to_grid"
          label="Discharging Strategy"
          infoText={chargeDischargeText.discharging}
          options={{ n: 'Offset Load', y: 'Discharge to Grid' }}
        />
      </Grid.Item>
      <Grid.Item span={5}>
        <Radio.Group
          label="Strategy class"
          onChange={updateCostFunctionClass}
          value={cost_function_class}
        >
          <Radio label="GHG Reduction" value="ghgRate" />
          <Radio label="RA Cost Reduction" value="systemProfile" />
        </Radio.Group>
      </Grid.Item>
      <Grid.Item span={7}>
        {costFunctionsOfClass && (
          <Select
            label="Strategy"
            onChange={updateCostFunction}
            options={costFunctionsOfClass}
            renderOption="name"
            sorted
            value={cost_function}
          />
        )}
      </Grid.Item>
    </Grid>
  );

  /** ========================== Callbacks ================================= */
  function updateCostFunctionClass(cfClass: CostFunctionClass) {
    setState({ cost_function: undefined, cost_function_class: cfClass });
  }

  function updateCostFunction(costFunction: CostFunction) {
    setState({ cost_function: costFunction });
  }
};

const ChargeSourceField: React.FC = () => {
  const { setState, charge_source, discharge_to_grid } = useDialogState();
  return (
    <Radio.Group label="Charging Source" onChange={updateChargeSource} value={charge_source}>
      <Radio label="Grid" value="grid" />
      <Radio label="Net Exports" value="nem" />
      <Radio label="Solar Production" value="solar" />
    </Radio.Group>
  );

  /** ========================== Callbacks ================================= */
  function updateChargeSource(chargeSource: ChargeSource) {
    setState({
      charge_source: chargeSource,
      discharge_to_grid: chargeSource === 'grid' ? false : discharge_to_grid,
    });
  }
};

/** ============================ Hooks ===================================== */
function useDialogState() {
  type DialogState = DialogContextType<BatteryStrategyDialogState>;
  const { setState, state } = React.useContext<DialogState>(DialogContext);
  return { ...state, state, setState };
}
