import { DERStrategy, DERStrategyObjective, DERType, SolarArrayType } from 'navigader/types';

/**
 * Retrieves and formats the strategy's description. The automated descriptions list their entire
 * DER schedules for every month, beginning with January. We remove that because it makes the text
 * overly-complex to read
 *
 * @param {DERStrategy} strategy: the strategy to retrieve and format the description of
 */
export function getStrategyDescription(strategy: DERStrategy) {
  if (!strategy.description) return;
  const contentEnd = strategy.description.indexOf('January');
  return contentEnd === -1
    ? strategy.description
    : strategy.description.slice(0, contentEnd).trim();
}

export function renderDERType(option: DERType) {
  switch (option) {
    case 'battery':
      return 'Battery';
    case 'evse':
      return 'EV Supply Equipment';
    case 'fuelswitching':
      return 'Fuel Switching';
    case 'solarpv':
      return 'Solar';
  }
}

export function renderSolarArrayType(type: SolarArrayType) {
  switch (type) {
    case 0:
      return 'Open Rack';
    case 1:
      return 'Roof Mounted';
    case 2:
      return 'Single-Axis Tracker';
  }
}

export function formatStrategyType(type: DERStrategyObjective) {
  switch (type) {
    case 'load_flattening':
      return 'Load Flattening';
    case 'reduce_bill':
      return 'Bill Reduction';
    case 'reduce_ghg':
      return 'GHG Reduction';
    case 'reduce_cca_finance':
      return 'Minimize CCA Financial Impacts';
    default:
      return 'Uncategorized';
  }
}
