import _ from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as api from 'navigader/api';
import {
  Alert,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  List,
  Progress,
  TextField,
  Typography,
} from 'navigader/components';
import { slices } from 'navigader/store';
import { makeStylesHook } from 'navigader/styles';
import { RateCollection, RatePlan } from 'navigader/types';
import { formatters } from 'navigader/util';
import { useRatePlan, useSnackbar } from 'navigader/util/hooks';

import { CreateRateCollectionForm, FormErrorObject } from './CreateRateCollectionForm';
import { RateCollectionView } from './RateCollectionView';

/** ============================ Types ===================================== */
type AssociatedRsNamesProps = { ratePlan: RatePlan };

/** ============================ Styles ==================================== */
const useAssociatedRsNamesStyles = makeStylesHook(
  (theme) => ({
    textField: { width: '100%' },
    root: { marginTop: theme.spacing(2) },
  }),
  'AssociatedRsNames'
);

/** ============================ Components ================================ */
const AssociatedRsNames: React.FC<AssociatedRsNamesProps> = ({ ratePlan }) => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const [addingName, setAddingName] = React.useState(false);
  const [newName, setNewName] = React.useState('');
  const classes = useAssociatedRsNamesStyles();
  const { associated_rs_names: names } = ratePlan;

  return (
    <div className={classes.root}>
      <Typography variant="h5">Associated RS Names</Typography>
      <List>
        {names.map((rsName, i) => (
          <List.Item button={false} key={rsName}>
            <Typography>{rsName}</Typography>
            <List.Item.Action icon="trash" onClick={() => deleteAssociation(i)} />
          </List.Item>
        ))}
        {names.length > 0 ? <Divider /> : null}
        {addingName ? (
          <List.Item button={false}>
            <TextField
              autoFocus
              className={classes.textField}
              onChange={(name) => setNewName(name.trim())}
              onEnter={addAssociation}
              placeholder="Add RS Association"
              value={newName}
            />
            <List.Item.Action disabled={newName === ''} icon="save" onClick={addAssociation} />
          </List.Item>
        ) : (
          <List.Item onClick={() => setAddingName(true)}>
            <Typography color="textSecondary">Add RS association</Typography>
          </List.Item>
        )}
      </List>
      <Alert type="info">
        When a rate plan is associated with a rate schedule name, meters with that exact name in
        their <Typography.Code>RS</Typography.Code> column (from the Item 17 upload file) will
        automatically receive cost calculations using the rate plan.
      </Alert>
    </div>
  );

  /** ========================== Callbacks ================================= */
  async function addAssociation() {
    // Shouldn't be possible as the save button is disabled when the field is empty
    if (newName === '') return;
    const associations = [...names, newName];

    try {
      await updateRatePlan(associations, 'Rate schedule association added');
    } catch (e) {
      snackbar.error();
      return;
    }

    // Clear the field
    setAddingName(false);
    setNewName('');
  }

  function deleteAssociation(i: number) {
    const deletedName = names[i];
    const associations = [...names.slice(0, i), ...names.slice(i + 1)];
    const msg = `Rate schedule association "${deletedName}" removed`;
    updateRatePlan(associations, msg).catch(snackbar.error);
  }

  async function updateRatePlan(associations: string[], successMessage: string) {
    const result = await api.updateRatePlan(ratePlan.id, {
      associated_rs_names: associations,
    });

    if (result.ok) {
      // The `associated_rs_names` update needs to be forced because the default state merging
      // mechanism can't handle array element deletion
      dispatch(slices.models.updateModel(result.val, { force: ['associated_rs_names'] }));
      snackbar.success(successMessage);
    } else {
      snackbar.error();
    }
  }
};

export const RatePlanDetails: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const { id } = useParams<{ id: string }>();

  // State
  const [createFormOpen, setCreateFormOpen] = React.useState(false);
  const [errors, setErrors] = React.useState<FormErrorObject>();
  const { loading, ratePlan } = useRatePlan(+id, { include: 'rate_collections.*' });

  const [selectedCollection, setSelectedCollection] = React.useState<RateCollection>();
  const collections = _.sortBy(ratePlan?.rate_collections, 'effective_date').reverse();

  React.useEffect(() => {
    const loadedCollections = ratePlan?.rate_collections;
    if (loadedCollections && loadedCollections.length === 0) setCreateFormOpen(true);
    setSelectedCollection((curr) => (curr && collections.includes(curr) ? curr : collections[0]));
  }, [collections, ratePlan]);

  if (loading) return <Progress />;

  return (
    <Grid>
      <Grid.Item span={3}>
        <Flex.Container>
          <Flex.Item grow>
            <Typography variant="h5">Rate Changes</Typography>
          </Flex.Item>
          <Flex.Item>
            {collections.length > 0 && (
              <Button
                onClick={() => setCreateFormOpen((o) => !o)}
                icon={createFormOpen ? 'close' : 'plus'}
              />
            )}
          </Flex.Item>
        </Flex.Container>
        <CreateRateCollectionForm
          open={createFormOpen}
          onSubmit={createRateCollection}
          errors={errors}
        />
        <List>
          {collections?.map((rate_collection, idx) => (
            <List.Item
              selected={
                selectedCollection ? selectedCollection.id === rate_collection.id : idx === 0
              }
              key={rate_collection.id}
              onClick={() => setSelectedCollection(rate_collection)}
            >
              {formatters.date.standard(rate_collection.effective_date)}
            </List.Item>
          ))}
        </List>
        {ratePlan && <AssociatedRsNames ratePlan={ratePlan} />}
      </Grid.Item>
      <Grid.Item span={9}>
        {selectedCollection && selectedCollection.rate_data ? (
          <RateCollectionView rateCollection={selectedCollection} onDelete={deleteRateCollection} />
        ) : (
          <Card>
            <Card.Content>
              <Typography variant="h5">No Rate Data</Typography>
            </Card.Content>
          </Card>
        )}
      </Grid.Item>
    </Grid>
  );

  /** ========================== Callbacks ================================= */
  function createRateCollection(params: api.CreateRateCollectionParams) {
    api.createRateCollection(params, (xhr) => {
      if (ratePlan && xhr.status === 201) {
        const rateCollection = JSON.parse(xhr.response).rate_collection;
        dispatch(
          slices.models.updateModel({
            ...ratePlan,
            rate_collections: [rateCollection].concat(ratePlan.rate_collections),
          })
        );
        setCreateFormOpen(false);
      } else {
        setErrors(JSON.parse(xhr.response));
      }
    });
  }

  async function deleteRateCollection(collectionId: RateCollection['id']) {
    const response = await api.deleteRateCollection(collectionId);

    if (ratePlan && response.ok) {
      dispatch(slices.models.removeModel(ratePlan));
      dispatch(
        slices.models.updateModel({
          ...ratePlan,
          rate_collections: _.without(collections, selectedCollection!),
        })
      );

      setSelectedCollection(collections[0]);
      snackbar.success('Rate data deleted.');
    } else if (response.status === 403) {
      snackbar.error('You do not have permission to delete this rate data!');
    }
  }
};
