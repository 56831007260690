import _ from 'lodash';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  FileDownload,
  HoverText,
  ImpactColumn,
  IntervalWidget,
  PageHeader,
  Progress,
  TableFactory,
} from 'navigader/components';
import { RFPPortfolio, RFPMeter } from 'navigader/models';
import { routes } from 'navigader/routes';
import { slices } from 'navigader/store';
import { makeStylesHook } from 'navigader/styles';
import { Maybe } from 'navigader/types';
import { formatters } from 'navigader/util';
import { useRFPPortfolio } from 'navigader/util/hooks';

/** ============================ Types ===================================== */
type MetersTableProps = {
  portfolio: Maybe<RFPPortfolio>;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  (theme) => ({ graphWrapper: { marginTop: theme.spacing(2) } }),
  'DetailsPage'
);

/** ============================ Components ================================ */
export const DetailsPage = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { portfolio } = useRFPPortfolio(id, { data_types: 'default', period: 60 });
  if (!portfolio) return <Progress />;
  return (
    <>
      <PageHeader
        breadcrumbs={[['RFP Validations', routes.rfp.base], 'Details']}
        title={`RFP Portfolio: ${portfolio?.name}`}
      />
      <MetersTable portfolio={portfolio} />

      <div className={classes.graphWrapper}>
        <IntervalWidget
          procurementRateId={portfolio.cost_functions.procurement_rate?.id}
          postSimulationCurve={portfolio.data?.default}
          preSimulationCurve={portfolio.data?.preSolar}
        />
      </div>
    </>
  );

  /** ========================== Callbacks ================================= */
};

const Table = TableFactory<RFPMeter>();
const MetersTable: React.FC<MetersTableProps> = ({ portfolio }) => {
  if (!portfolio) return null;

  return (
    <Table
      aria-label="meters table"
      dataFn={(params) => RFPMeter.api.list({ ...params, portfolio_id: portfolio.id })}
      dataSelector={slices.models.selectRFPMeters}
      headerActions={
        <FileDownload
          downloadFn={(cb) => RFPPortfolio.api.downloadReport(portfolio, cb)}
          tooltipText="Download report"
        />
      }
      initialSorting={{
        dir: 'desc',
        key: 'total_kwh',
      }}
      raised
      title="Meters"
    >
      {(meters) => (
        <>
          <Table.Head>
            <Table.Row>
              <Table.Cell sortBy="said">SA ID</Table.Cell>
              <Table.Cell sortBy="site_name">Site Name</Table.Cell>
              <Table.Cell sortBy="rate_plan_name">Rate Plan</Table.Cell>
              <Table.Cell sortBy="total_kwh" sortDir="desc" align="right">
                Total kWh
              </Table.Cell>
              <ImpactColumn.Header
                column="Usage Impact"
                info={{
                  measuresImpact: 'in customer electricity usage',
                  negativeMeans: 'electricity consumption from the grid has gone down',
                  positiveMeans: 'electricity consumption from the grid has gone up',
                }}
                units="kWh"
              />
              <ImpactColumn.Header
                column="GHG Impact"
                info={{
                  measuresImpact: 'in GHG emissions',
                  negativeMeans: 'GHG emissions have gone down',
                  positiveMeans: 'GHG emissions have gone up',
                }}
                units={
                  <>
                    tCO<sub>2</sub>
                  </>
                }
              />
              <ImpactColumn.Header
                column="RA Impact"
                info={{
                  measuresImpact: 'to resource adequacy requirements',
                  negativeMeans: 'resource adequacy costs have gone down',
                  positiveMeans: 'resource adequacy costs have gone up',
                }}
                units="$"
              />
              <ImpactColumn.Header
                column="Procurement Impact"
                info={{
                  measuresImpact: 'to expenses incurred procuring electricity',
                  negativeMeans: 'CCA procurement expenses have gone down',
                  positiveMeans: 'CCA procurement expenses have gone up',
                }}
                units="$"
              />
              <ImpactColumn.Header
                column="Revenue Impact"
                info={{
                  measuresImpact: "to CCA's electricity sales",
                  negativeMeans: 'revenues from electricity sales have gone down',
                  positiveMeans: 'revenues from electricity sales have gone up',
                }}
                units="$"
              />
              <ImpactColumn.Header
                column="Expenses Impact"
                info={{
                  measuresImpact:
                    'to overall expenses. Calculated as procurement expenses plus $/kW RA impacts',
                  negativeMeans: 'overall expenses have gone down',
                  positiveMeans: 'overall expenses have gone up',
                }}
                units="$"
              />
              <ImpactColumn.Header
                column="Profits Impact"
                info={{
                  measuresImpact: 'to overall profits. Calculated as revenues minus expenses',
                  negativeMeans: 'overall profits have gone down',
                  positiveMeans: 'overall profits have gone up',
                }}
                units="$"
              />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {meters.map(({ id, rate_plan_name, report, said, site_name, total_kwh }) => (
              <Table.Row key={id}>
                <Table.Cell>{said}</Table.Cell>
                <Table.Cell>{site_name}</Table.Cell>
                <Table.Cell>{rate_plan_name}</Table.Cell>
                <Table.Cell>
                  {formatters.commas(formatters.maxDecimals(total_kwh, 2)) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.commas(formatters.maxDecimals(report.UsageDelta, 2)) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.commas(formatters.maxDecimals(report.CSP2022Delta, 2)) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.dollars(report.RACostDelta) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.dollars(report.ProcurementCostDelta) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  <BillRevenueDelta report={report} />
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.dollars(report.ExpenseDelta) ?? '-'}
                </Table.Cell>
                <Table.Cell align="right">
                  {formatters.dollars(report.ProfitDelta?.at(0)) ?? '-'}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </>
      )}
    </Table>
  );
};

const BillRevenueDelta: React.FC<{ report: RFPPortfolio.Reporting.Report }> = ({ report }) => {
  const { BillRevenueDelta, RatePlan } = report;
  if (!BillRevenueDelta || _.isNull(BillRevenueDelta[0])) return <span>-</span>;

  const hoverText = RatePlan?.length
    ? `Calculated with ${RatePlan[0]}`
    : 'Cost function has been deleted';

  return <HoverText text={hoverText}>{formatters.dollars(BillRevenueDelta[0])}</HoverText>;
};
