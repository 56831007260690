import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as api from 'navigader/api';
import { Dialog, Link, Menu, List, TableFactory, StandardDate, Button } from 'navigader/components';
import { routes, usePushRouter } from 'navigader/routes';
import { CAISORate } from 'navigader/types';
import { slices } from 'navigader/store';
import { formatters } from 'navigader/util';
import { useSnackbar } from 'navigader/util/hooks';
import { CAISORateDetails } from './CAISORateDetails';
import { CreateCAISORate, UpdateCAISORate } from './CAISORateDialogs';

/** ============================ Components ================================ */
const Table = TableFactory<CAISORate>();
export const CAISORateList = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const routeTo = usePushRouter();
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [caisoRateToUpdate, setCaisoRateToUpdate] = React.useState<CAISORate>();
  const [caisoRateToDelete, setCaisoRateToDelete] = React.useState<CAISORate>();

  return (
    <>
      <Table
        aria-label="Procurement rates table"
        dataFn={(params) => api.getCAISORates({ ...params, data_types: 'default' })}
        dataSelector={slices.models.selectCAISORates}
        initialSorting={{ dir: 'desc', key: 'created_at' }}
        onFabClick={() => setCreateDialogOpen(true)}
        raised
        title="Procurement Rates"
      >
        {(caisoRates, EmptyRow) => (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell sortBy="name">Name</Table.Cell>
                <Table.Cell sortBy="created_at">Created</Table.Cell>
                <Table.Cell>Min $/kWh</Table.Cell>
                <Table.Cell>Max $/kWh</Table.Cell>
                <Table.Cell>Average $/kWh</Table.Cell>
                <Table.Cell>Years</Table.Cell>
                <Table.Cell align="right">Menu</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {/** Only renders if there's no data */}
              <EmptyRow>
                None created.
                <Button.Text
                  color="primary"
                  icon="plus"
                  onClick={() => setCreateDialogOpen(true)}
                  size="small"
                >
                  Upload procurement rate
                </Button.Text>
              </EmptyRow>

              {caisoRates.map((caisoRate) => (
                <Table.Row key={caisoRate.id}>
                  <Table.Cell>
                    <Link to={routes.cost.procurement.caisoRate(caisoRate.id)}>
                      {caisoRate.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <StandardDate date={caisoRate.created_at} />
                  </Table.Cell>
                  <Table.Cell>
                    {formatters.commas(
                      formatters.maxDecimals(caisoRate.data.default?.valueDomain[0], 4)
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatters.commas(
                      formatters.maxDecimals(caisoRate.data.default?.valueDomain[1], 4)
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {caisoRate.data.default &&
                      formatters.commas(formatters.maxDecimals(caisoRate.data.default.average, 4))}
                  </Table.Cell>
                  <Table.Cell>{caisoRate.data.default?.years.join(', ')}</Table.Cell>
                  <Table.Cell align="right">
                    <Menu
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      icon="verticalDots"
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List.Item onClick={routeTo.cost.procurement.caisoRate(caisoRate)}>
                        <List.Item.Icon icon="launch" />
                        <List.Item.Text>View</List.Item.Text>
                      </List.Item>
                      <List.Item
                        onClick={() => {
                          setCaisoRateToUpdate(caisoRate);
                        }}
                      >
                        <List.Item.Icon icon="upload" />
                        <List.Item.Text>Update</List.Item.Text>
                      </List.Item>
                      <List.Item
                        onClick={() => {
                          setCaisoRateToDelete(caisoRate);
                        }}
                      >
                        <List.Item.Icon icon="trash" />
                        <List.Item.Text>Delete</List.Item.Text>
                      </List.Item>
                    </Menu>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </>
        )}
      </Table>

      <CreateCAISORate open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
      <UpdateCAISORate
        caisoRate={caisoRateToUpdate}
        open={!!caisoRateToUpdate}
        onClose={() => setCaisoRateToUpdate(undefined)}
      />
      <Dialog.Delete
        onClose={() => setCaisoRateToDelete(undefined)}
        onClickDelete={deleteCAISORate}
        title="Delete Procurement Rate"
        message="This will permanently delete the Procurement Rate and cannot be undone."
        open={caisoRateToDelete !== undefined}
      />
    </>
  );

  /** ========================== Callbacks ================================= */
  async function deleteCAISORate() {
    if (caisoRateToDelete) {
      const response = await api.deleteCAISORate(caisoRateToDelete.id);
      if (response.ok) {
        dispatch(slices.models.removeModel(caisoRateToDelete));
        snackbar.success('Procurement rate deleted.');
      } else if (response.status === 403) {
        snackbar.error('You do not have permission to delete this procurement rate!');
      } else {
        const errorMsg = await response.json();
        snackbar.error(errorMsg);
      }
    }
  }
};

export const Procurement = () => (
  <Switch>
    <Route exact path={routes.cost.procurement.base} component={CAISORateList} />
    <Route path={routes.cost.procurement.caisoRate(':id')} component={CAISORateDetails} />
  </Switch>
);
