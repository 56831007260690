import classNames from 'classnames';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Flex,
  Grid,
  IntervalGraph,
  MonthSelector,
  PrefetchedTable,
  Progress,
  Table,
  Typography,
  YearSelectorExclusive,
} from 'navigader/components';
import { SystemProfile } from 'navigader/models';
import { makeStylesHook } from 'navigader/styles';
import { MonthIndex } from 'navigader/types';
import { formatters, MONTHS } from 'navigader/util';
import { useSystemProfile } from 'navigader/util/hooks';

import { UpdateSystemProfile } from './SystemProfileDialogs';

/** ============================ Types ====================================== */
type RatesTableProps = {
  selectedMonth?: MonthIndex;
  showHeader?: boolean;
  systemProfile: SystemProfile;
};

/** ============================ Styles ===================================== */
const useStyles = makeStylesHook(
  (theme) => ({
    chart: { padding: theme.spacing(2) },
    downloadButton: { float: 'right' },
    uploadButton: { float: 'right', marginRight: 10 },
    yearSelector: { marginBottom: theme.spacing(2) },
  }),
  'SystemProfileDetails'
);

const padding = '2px 5px';
const useRatesTableClasses = makeStylesHook(
  (theme) => ({
    header: { padding, textAlign: 'center' },
    ratesTable: { width: 'auto' },
    ratesTableCell: { padding, '&:last-child': { padding } },
    selectedMonth: { backgroundColor: theme.palette.divider },
  }),
  'RatesTable'
);

/** ============================ Components ================================ */
export const RatesTable: React.FC<RatesTableProps> = (props) => {
  const { selectedMonth, showHeader = true, systemProfile } = props;
  const classes = useRatesTableClasses();
  return (
    <PrefetchedTable className={classes.ratesTable} data={[]} hover={false} size="small">
      {() => (
        <>
          <Table.Head>
            {showHeader && (
              <Table.Row>
                <Table.Cell colSpan={12} className={classes.header}>
                  <Typography emphasis="bold" variant="body2">
                    Rates ($/kW)
                  </Typography>
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              {MONTHS.map((n) => (
                <Table.Cell className={tableCellClassName(n)} key={n}>
                  <Typography variant="body2">
                    {formatters
                      .getMonthName(n as MonthIndex)
                      .slice(0, 3)
                      .toUpperCase()}
                  </Typography>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            <Table.Row>
              {systemProfile.resource_adequacy_rates.map((n, i) => (
                <Table.Cell className={tableCellClassName(i + 1)} key={i}>
                  <Typography variant="body2">{formatters.dollars(n)}</Typography>
                </Table.Cell>
              ))}
            </Table.Row>
          </Table.Body>
        </>
      )}
    </PrefetchedTable>
  );

  function tableCellClassName(n: number) {
    return classNames(classes.ratesTableCell, {
      [classes.selectedMonth]: n === selectedMonth,
    });
  }
};

export const SystemProfileDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const { loading, systemProfile } = useSystemProfile(+id, { data_types: ['default'] });
  const [selectedMonth, setSelectedMonth] = React.useState<MonthIndex>(
    DateTime.local().month as MonthIndex
  );
  const [selectedYear, setSelectedYear] = React.useState(systemProfile?.data.default?.years[0]);
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);

  // Set selected year if the systemProfile hadn't been loaded yet
  React.useEffect(() => {
    setSelectedYear(systemProfile?.data.default?.years[0]);
  }, [loading, systemProfile]);

  if (!systemProfile || loading) return <Progress />;

  return (
    <Grid>
      <Grid.Item span={6}>
        <Typography variant="h6">{systemProfile?.name || ''}</Typography>
      </Grid.Item>
      <Grid.Item span={6}>
        <Button
          icon="download"
          color="primary"
          className={classes.downloadButton}
          onClick={download}
        >
          Download
        </Button>
        <Button
          color="primary"
          icon="upload"
          className={classes.uploadButton}
          onClick={() => setUpdateDialogOpen(true)}
        >
          Update
        </Button>
      </Grid.Item>
      {systemProfile.data && systemProfile.data.default && (
        <>
          <Grid.Item span={12}>
            <Flex.Container justifyContent="space-around">
              <Flex.Item>
                <RatesTable selectedMonth={selectedMonth} systemProfile={systemProfile} />
              </Flex.Item>
              <Flex.Item>
                <div className={classes.yearSelector}>
                  <YearSelectorExclusive
                    years={systemProfile.data.default.years}
                    selected={selectedYear}
                    onChange={setSelectedYear}
                  />
                </div>
                <MonthSelector exclusive selected={selectedMonth} onChange={setSelectedMonth} />
              </Flex.Item>
            </Flex.Container>
          </Grid.Item>
          <Grid.Item span={12}>
            <IntervalGraph
              axisLabel="System Load"
              className={classes.chart}
              data={systemProfile.data.default}
              month={selectedMonth}
              year={selectedYear}
              units="kW"
            />
          </Grid.Item>
        </>
      )}
      <UpdateSystemProfile
        systemProfile={systemProfile}
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      />
    </Grid>
  );

  /** ========================== Callbacks ================================= */
  async function download() {
    await SystemProfile.api.download(systemProfile!.id);
  }
};
