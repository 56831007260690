import * as React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import * as api from 'navigader/api';
import { Workspace } from 'navigader/models';
import { routes, usePushRouter } from 'navigader/routes';
import { slices } from 'navigader/store';
import { makeStylesHook, materialColors, white } from 'navigader/styles';
import { cookieManager, HELP_PAGE_URI, models, sendSupportEmail } from 'navigader/util';
import { useSnackbar, useUserProfile, useWorkspaces } from 'navigader/util/hooks';

import { Button } from '../Button';
import { Dialog } from '../Dialog';
import { Divider } from '../Divider';
import { Flex } from '../Flex';
import { Link } from '../Link';
import { Menu } from '../Menu';
import { TextField } from '../TextField';
import { Tooltip } from '../Tooltip';

/** ============================ Styles ==================================== */
const activeWorkspaceColor = materialColors.amber[100];
const useHelpStyles = makeStylesHook(() => ({ helpButton: { color: white } }), 'Help');
const useWorkspaceStyles = makeStylesHook(
  () => ({
    activeWorkspace: {
      'backgroundColor': activeWorkspaceColor,
      '&:hover': {
        backgroundColor: activeWorkspaceColor,
        cursor: 'default',
      },
    },
  }),
  'WorkspacePicker'
);
const useStyles = makeStylesHook(
  (theme) => ({
    actions: {
      'color': white,
      '& > *': {
        color: 'inherit',
        marginLeft: theme.spacing(1),
      },
    },
  }),
  'AppBarActions'
);

/** ============================ Components ================================ */
const Feedback: React.FC = () => {
  const snackbar = useSnackbar();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState<string>('');
  const dialogId = 'feedback-dialog-title';
  return (
    <>
      <Tooltip title="Submit Feedback">
        <Button icon="feedback" onClick={() => setDialogOpen(true)} />
      </Tooltip>

      <Dialog fullWidth open={dialogOpen} onClose={closeDialog} aria-labelledby={dialogId}>
        <Dialog.Title id={dialogId}>Help us improve NxT</Dialog.Title>
        <Dialog.Content>
          <TextField
            autoFocus
            onChange={setFeedback}
            placeholder="Type your feedback here..."
            value={feedback}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button.Text onClick={closeDialog}>Cancel</Button.Text>
          <Button.Text color="primary" disabled={feedback.length === 0} onClick={submitFeedback}>
            Submit
          </Button.Text>
        </Dialog.Actions>
      </Dialog>
    </>
  );

  /** ========================== Callbacks ================================= */
  function closeDialog() {
    setDialogOpen(false);
  }

  function submitFeedback() {
    sendSupportEmail('NxT feedback', feedback);
    setFeedback('');
    snackbar.success('Thank you for your feedback!', null);
    closeDialog();
  }
};

const AccountMenu: React.FC = () => {
  const routeTo = usePushRouter();
  return (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      icon="account"
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Menu.Item onClick={routeTo.settings}>
        <Menu.Item.Text>Settings</Menu.Item.Text>
      </Menu.Item>
      <Menu.Item onClick={logout}>
        <Menu.Item.Text>Logout</Menu.Item.Text>
      </Menu.Item>
      <WorkspacePicker />
    </Menu>
  );

  /** ========================== Callbacks ================================= */
  function logout() {
    cookieManager.remove.authToken();
    models.polling.reset();
    api.logout().catch();

    // Use the Location API to navigate to the login screen instead of React Router so that page
    // state is reset. Using a redirect (as React Router does) would maintain the contents/history
    // of the redux store and leak data outside the user's session.
    window.location.href = routes.login;
  }
};

const Help: React.FC = () => {
  const classes = useHelpStyles();

  // If the configuration is missing the help page URI, render nothing
  if (!HELP_PAGE_URI) return null;

  return (
    <Tooltip title="Visit the User Manual">
      <Link.NewTab to={HELP_PAGE_URI} noUnderline>
        <Button.Text className={classes.helpButton}>Help</Button.Text>
      </Link.NewTab>
    </Tooltip>
  );
};

const WorkspacePicker: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useWorkspaceStyles();
  const [error, setError] = React.useState<string>();
  const [createDialogOpen, openCreateDialog] = React.useState(false);
  const [newWorkspaceName, setNewWorkspaceName] = React.useState('');

  const { profile } = useUserProfile();
  const { workspaces } = useWorkspaces();
  const activeWorkspaceId = profile?.active_workspace;
  return (
    <>
      <Menu.NestedItem label="Workspace" parentMenuOpen>
        {workspaces.map((ws) => (
          <Menu.Item
            className={classNames({
              [classes.activeWorkspace]: ws.id === activeWorkspaceId,
            })}
            key={ws.id}
            onClick={() => changeWorkspace(ws)}
          >
            <Menu.Item.Text>{ws.name}</Menu.Item.Text>
          </Menu.Item>
        ))}

        <Divider />

        <Menu.Item onClick={() => openCreateDialog(true)}>
          <Menu.Item.Text>Create New Workspace</Menu.Item.Text>
        </Menu.Item>
      </Menu.NestedItem>

      <Dialog fullWidth open={createDialogOpen} onClose={closeDialog}>
        <Dialog.Title>Create a new Workspace</Dialog.Title>
        <Dialog.Content>
          <TextField
            error={!!error}
            helperText={error}
            value={newWorkspaceName}
            onChange={setNewWorkspaceName}
            placeholder="Workspace Name"
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Button.Text onClick={closeDialog}>Cancel</Button.Text>
          <Button.Text
            color="primary"
            disabled={!newWorkspaceName.length}
            onClick={createWorkspace}
          >
            Submit
          </Button.Text>
        </Dialog.Actions>
      </Dialog>
    </>
  );

  /** ============================== Callbacks ============================= */
  /**
   * Makes an API request to update the user's active workspace. This can't be
   * accomplished unless we already have the user profile ID
   */
  async function changeWorkspace(newWorkspace: Workspace) {
    if (_.isNull(profile)) return;
    if (newWorkspace.id === activeWorkspaceId) return;

    // Send the request and reload the page
    await api.updateUserProfile(profile.id, { active_workspace: newWorkspace.id });
    window.location.reload();
  }

  /** Closes the "New Workspace" dialog */
  function closeDialog() {
    setError(undefined);
    openCreateDialog(false);
  }

  /**
   * Makes an API request to create a new workspace. After the workspace has been created it is
   * added to the store and then the user is switched into it. Adding it to the store isn't really
   * necessary as the page is about to be reloaded anyway.
   */
  async function createWorkspace() {
    const result = await Workspace.api.create({ name: newWorkspaceName });
    if (result.ok) {
      const newWorkspace = result.val;
      dispatch(slices.models.updateModel(newWorkspace));
      await changeWorkspace(newWorkspace);
    } else {
      setError(result.err);
    }
  }
};

export const AppBarActions: React.FC = () => {
  const classes = useStyles();
  return (
    <Flex.Container alignItems="center" className={classes.actions}>
      <Help />
      <Feedback />
      <AccountMenu />
    </Flex.Container>
  );
};
