import * as React from 'react';

import { Alert } from './Alert';

/** ============================ Types ===================================== */
type ErrorBoundaryProps = {
  render?: () => React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

/** ============================ Components ================================ */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { children, render } = this.props;
    if (!this.state.hasError) return children;
    return render ? render() : <Alert type="error">Something went wrong.</Alert>;
  }
}
