import * as React from 'react';

import * as api from 'navigader/api';
import { Button, Dialog, FileSelector, Grid, TextField } from 'navigader/components';
import { usePushRouter } from 'navigader/routes';
import { CAISORate } from 'navigader/types';
import { useSnackbar } from 'navigader/util/hooks';

import { CostFunctionFileUpload, DialogProps } from '../common/CreateDialog';
const { useFileSelectorState } = FileSelector;

/** ============================= Types ==================================== */
type UpdateCAISORateDialogProps = DialogProps & { caisoRate?: CAISORate };

/** ============================ Components ================================ */
export const CreateCAISORate: React.FC<DialogProps> = ({ open, onClose }) => {
  const snackbar = useSnackbar();
  const routeTo = usePushRouter();

  const url = (m: 15 | 60) => `/downloads/procurement/procurement_rate_${m}_min_template.csv`;

  // State
  const [state, setState] = useFileSelectorState();
  const { file, name } = state;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Dialog.Title>Create Procurement Rate</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <CostFunctionFileUpload url={url} setState={setState} state={state} />
          <Grid.Item span={12}>
            <TextField
              id="name"
              label="Name"
              onChange={(name) => setState({ name })}
              value={name || ''}
            />
          </Grid.Item>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!file} onClick={onSubmit}>
          Submit
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ============================== Callbacks =============================== */
  async function onSubmit() {
    if (!file) return;

    const response = await api.createCAISORate({ name: name || '', file });
    if (!response.ok) {
      snackbar.error();
      return;
    }

    // Navigate to the details page
    onClose();
    const caisoRate: CAISORate = (await response.json()).caiso_rate;
    routeTo.cost.procurement.caisoRate(caisoRate)();
  }
};

export const UpdateCAISORate: React.FC<UpdateCAISORateDialogProps> = ({
  caisoRate,
  open,
  onClose,
}) => {
  const snackbar = useSnackbar();

  const url = (m: 15 | 60) => `/downloads/procurement/procurement_rate_${m}_min_template.csv`;

  // State
  const [state, setState] = useFileSelectorState();
  const { file } = state;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Dialog.Title>Update {caisoRate?.name || ''}</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <CostFunctionFileUpload url={url} setState={setState} state={state} />
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!file} onClick={onSubmit}>
          Submit
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ============================== Callbacks =============================== */
  async function onSubmit() {
    if (!file || !caisoRate) return;

    const response = await api.updateCAISORate(caisoRate.id, { file });
    if (!response.ok) {
      snackbar.error();
      return;
    }

    // Navigate to the details page
    onClose();
  }
};
