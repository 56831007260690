import * as React from 'react';
import { useParams } from 'react-router-dom';

import * as api from 'navigader/api';
import { Button, Card, Grid, FileDisplay, FileSelector, Typography } from 'navigader/components';
import { makeStylesHook } from 'navigader/styles';
import { useDownloadCallback } from 'navigader/util/hooks';

const { useFileSelectorState } = FileSelector;

/** ============================ Types ===================================== */
export type FormErrorObject = {
  [field: string]: string[];
};

export type ErrorDetailProps = {
  errors: FormErrorObject;
};

export type CreateRateCollectionFormProps = {
  errors: FormErrorObject | undefined;
  onSubmit: (params: api.CreateRateCollectionParams) => void;
  open: boolean;
};

/** ============================ Styles ==================================== */
const useCreateRateCollectionStyles = makeStylesHook(
  () => ({ submitButton: { float: 'right' } }),
  'UploadCard'
);

/** ============================ Components ================================ */
const ErrorDetails: React.FC<ErrorDetailProps> = (params) => {
  const { errors } = params;
  return (
    <>
      {Object.entries(errors).map(([field, error]) => (
        <Grid.Item key={field} span={12}>
          <Grid>
            <Grid.Item span={6}>
              <Typography color="error" variant="body2">
                {field}:
              </Typography>
            </Grid.Item>
            <Grid.Item span={6}>
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      ))}
    </>
  );
};

export const CreateRateCollectionForm: React.FC<CreateRateCollectionFormProps> = (props) => {
  const { open, onSubmit, errors } = props;
  const classes = useCreateRateCollectionStyles();
  const { id } = useParams<{ id: string }>();

  const downloadUrl = '/downloads/rate_plan/rate_collection_template.csv';
  const downloadTemplate = useDownloadCallback(downloadUrl);

  const [state, setState] = useFileSelectorState();
  const { file } = state;

  return (
    <>
      <Card hidden={!open}>
        <Card.Content>
          <Grid>
            <Grid.Item span={12}>
              <FileSelector accept="csv" onChange={(file) => setState({ file })}>
                Select Rate File
              </FileSelector>
            </Grid.Item>
            <Grid.Item span={12}>
              <Button onClick={downloadTemplate}>Download Template</Button>
            </Grid.Item>
            <Grid.Item span={12}>
              <FileDisplay file={file} />
            </Grid.Item>
            {errors && <ErrorDetails errors={errors} />}
          </Grid>
        </Card.Content>
        <Card.Actions className={classes.submitButton}>
          <Button.Text
            disabled={!file}
            color="primary"
            onClick={() => {
              file &&
                onSubmit({
                  rate_data_csv: file,
                  rate_plan: id,
                });
            }}
          >
            Upload
          </Button.Text>
        </Card.Actions>
      </Card>
    </>
  );
};
