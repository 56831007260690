import { DateTime } from 'luxon';

import { GHGRate, SystemProfile } from 'navigader/models';
import { DateRangeObject, Nullable, RawDateRangeObject, StateChoice } from './common';
import { DataObject, RawDataObject } from './data';

/** ============================ CAISO Rates =============================== */
type CAISORateFilters = Partial<{
  DATA_ITEM: 'LMP_PRC';
}>;

type CAISORateCommon = {
  filters: CAISORateFilters;
  id: number;
  name: string;
  created_at: string;
  object_type: 'CAISORate';
};

export interface CAISORate extends CAISORateCommon, DataObject, DateRangeObject {}
export interface RawCAISORate
  extends CAISORateCommon,
    RawDataObject<'start', '$/kwh'>,
    RawDateRangeObject {}

/** ============================ Rate Plans ================================ */
export type LoadServingEntity = {
  id: number;
  name: string;
  short_name: string;
  state: StateChoice;
};

export type RateComponent = { rate: number; unit: string } & Partial<{
  max: number;
  adj: number;
  sell: number;
}>;

export type RateBucket = Partial<{
  energyRateTiers: RateComponent[];
  demandRateTiers: RateComponent[];
}>;

export type EnergyKeyVal = { key: string; val: number };
export type RateData = Partial<{
  approved: boolean;
  effectiveDate: { $date: string };
  rateName: string;
  sector: string;
  sourceReference: string;
  energyRateStrux: RateBucket[];
  demandRateStrux: RateBucket[];
  energyKeyVals: EnergyKeyVal[];
  demandRateUnits: string;
}>;

export type RateCollection = {
  effective_date: string;
  id: number;
  object_type: 'RateCollection';
  openei_url: Nullable<string>;
  rate_plan: RatePlan['id'];
  utility_url: string;
  rate_data: RateData;
};

type RatePlanCommon = {
  demand_max: Nullable<number>;
  demand_min: Nullable<number>;
  description: Nullable<string>;
  id: number;
  associated_rs_names: string[];
  load_serving_entity: LoadServingEntity;
  name: string;
  created_at: string;
  object_type: 'RatePlan';
  rate_collections?: RateCollection[];
  sector?: string;
};

export interface RawRatePlan extends RatePlanCommon {
  start_date: Nullable<string>;
}

export interface RatePlan extends RatePlanCommon {
  start_date: Nullable<DateTime>;
}

export type CostFunction = CostFunctions.Object[keyof CostFunctions.Object];
export namespace CostFunctions {
  type Shortened = Pick<CostFunction, 'id' | 'name'>;
  export type Key = 'ghg_rate' | 'procurement_rate' | 'rate_plan' | 'system_profile';
  export type ObjectShort = Record<Key, Nullable<Shortened>>;
  export type Object = {
    ratePlan: RatePlan;
    ghgRate: GHGRate;
    caisoRate: CAISORate;
    systemProfile: SystemProfile;
  };
}
