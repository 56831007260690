import _ from 'lodash';

import { Maybe, Nullable, PaginationSet, QueryParams, RawPaginationSet } from 'navigader/types';
import { appendQueryString, cookieManager, Err, Ok, omitFalsey, ResultAsync } from 'navigader/util';

import { makeURLCreator, URLCreator } from './urlCreator';

/** ============================ Types ===================================== */
// Needless to say, this is not a complete set of HTTP method types. It is the set of the ones used
// in the NxT application.
type HttpMethodType = 'DELETE' | 'GET' | 'PATCH' | 'POST';
type ContentType = 'application/json' | 'multipart/form-data';
type URL = URLCreator | string;
export type ProgressCallback = (receivedLength: number, contentLength: number) => void;

/*  ============================ Errors ==================================== */
type NonFieldErrorKey = '__all__' | 'non_field_errors';
type ErrorArray = string[];
export type DRFError = { details: string } | { detail: string };
export type ErrorObject<Fields = {}> = Partial<Record<keyof Fields | NonFieldErrorKey, string>>;
export type ErrorArrayObject<Fields = {}> = Partial<
  Record<keyof Fields | NonFieldErrorKey, ErrorArray>
>;

/** ============================ API ======================================= */
export class BaseAPI {
  static endpoints = makeURLCreator(process.env.REACT_APP_BEO_URI);

  /** ========================== Requests ================================== */
  /**
   * Makes a request using the fetch API
   *
   * @param {HttpMethodType} method: the HTTP method to use for the request (e.g. GET, POST, etc)
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {object} body: the body of the request. Typically this will be JSON.
   */
  private static makeJsonRequest<T>(method: HttpMethodType, url: URL, body?: string | FormData) {
    return this.formatResponse<T>(
      fetch(String(url), {
        body,
        headers: this.getRequestHeaders('application/json'),
        method,
      })
    );
  }

  /**
   * Emulates a form submission using the fetch API
   *
   * @param {HttpMethodType} method: the HTTP method to use for the request (e.g. GET, POST, etc)
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {object} formFields: an object mapping form data fields to their values,
   */
  protected static async makeFormRequest<T = any>(
    method: HttpMethodType,
    url: URL,
    formFields: object
  ): ResultAsync<T> {
    return this.formatResponse<T>(
      fetch(String(url), {
        body: this.objToFormData(formFields),
        headers: this.getRequestHeaders(),
        method,
      })
    );
  }

  /**
   * Returns the first error found in an ErrorArrayObject, or a generic error message if no errors
   * are found.
   *
   * @param {ErrorArrayObject|DRFError} errorObj: the error object returned from the API. Generic
   *   DRF errors simply contain a `details` key with an informative string; otherwise the object
   *   may be a mapping from field names to arrays of error strings
   */
  protected static extractError(errorObj: Maybe<ErrorArrayObject | ErrorArray | DRFError>): string {
    const defaultError = 'Something went wrong';
    if (!errorObj) return defaultError;

    // Handle generic DRF errors
    if ('details' in errorObj) return errorObj.details;
    if ('detail' in errorObj) return errorObj.detail;

    // Handle a basic list of strings
    if (_.isArray(errorObj)) return errorObj.length > 0 ? errorObj[0] : defaultError;

    // If the error object is a mapping from keys to key-specific errors...
    for (const [k, v] of Object.entries(errorObj)) {
      if (v && v.length > 0) {
        return `${k}: ${v[0]}`;
      }
    }

    // Couldn't figure it out. Return the generic default error
    return defaultError;
  }

  /**
   * Given a Promise that will resolve to a Response object (returned by the fetch API), returns a
   * Result object. If the initial promise resolves and the response has a 200 code, the Result will
   * be an `Ok` object; otherwise it will be an `Err` object containing details of the error.
   *
   * @param {Promise<Response>} promise: the promise returned by the fetch API
   */
  private static async formatResponse<T>(promise: Promise<Response>): ResultAsync<T> {
    let response: Response;
    try {
      response = await promise;
    } catch (e: any) {
      // If the request failed, yield the failure message
      return Err(e.message);
    }

    // If there's no content `response.json()` throws an error
    let data: any;
    try {
      data = await response.json();
    } catch (e: any) {
      data = undefined;
    }

    // If the request returned a non-200 code, yield the error
    return response.ok ? Ok(data) : Err(this.extractError(data));
  }

  /** Makes a JSON DELETE request */
  protected static delete<T = any>(url: URL) {
    return this.makeJsonRequest<T>('DELETE', url);
  }

  /** Makes a JSON GET request */
  protected static get<T = any>(url: URL, queryParams?: QueryParams) {
    return this.makeJsonRequest<T>('GET', appendQueryString(String(url), queryParams));
  }

  /** Makes a JSON PATCH request */
  protected static patch<T = any>(url: URL, body: object) {
    return this.makeJsonRequest<T>('PATCH', url, JSON.stringify(body));
  }

  /** Makes a JSON POST request */
  protected static post<T = any>(url: URL, body: object = {}) {
    return this.makeJsonRequest<T>('POST', url, JSON.stringify(body));
  }

  /** Emulates a form PATCH submission using the fetch API */
  protected static async patchForm<T = any>(url: URL, formFields: object) {
    return this.makeFormRequest<T>('PATCH', url, formFields);
  }

  /** Emulates a form POST submission using the fetch API */
  protected static postForm<T = any>(url: URL, formFields: object) {
    return this.makeFormRequest<T>('POST', url, formFields);
  }

  /** ========================== Parsing =================================== */
  /**
   * Parses a raw pagination set (the raw response from the back end for a paginated endpoint) into
   * a parsed pagination set. If there's no need to parse the data (e.g. if there are no sideload
   * data) then passing a string under which the results are nested is sufficient.
   *
   * @param {RawPaginationSet} paginationSet: the raw server response to parse
   * @param {string} resultsKey: they key under which the data array lies
   */
  protected static parsePaginationSet<ResultsKey extends string, Datum>(
    paginationSet: RawPaginationSet<Record<ResultsKey, Datum[]>>,
    resultsKey: ResultsKey
  ): PaginationSet<Datum>;

  /**
   * Parses a raw pagination set (the raw response from the back end for a paginated endpoint) into
   * a parsed pagination set.
   *
   * @param {RawPaginationSet} paginationSet: the raw server response to parse
   * @param {Function} [parseFn]: a function that parses an individual result from its raw version
   *   to its parsed version. Defaults to the identity function
   */
  protected static parsePaginationSet<RawSchema, Datum>(
    paginationSet: RawPaginationSet<RawSchema>,
    parseFn: (schema: RawSchema) => Datum[]
  ): PaginationSet<Datum>;

  protected static parsePaginationSet(paginationSet: any, parseFnOrResultsKey?: any): any {
    const { count, results } = paginationSet;

    // Parse the data in an IIFE
    const data = (() => {
      if (_.isArray(results)) {
        return typeof _.isFunction(parseFnOrResultsKey)
          ? results.map(parseFnOrResultsKey)
          : results;
      } else if (_.isFunction(parseFnOrResultsKey)) {
        return parseFnOrResultsKey(results);
      } else if (_.isString(parseFnOrResultsKey)) {
        return results[parseFnOrResultsKey];
      } else {
        throw Error('`parsePaginationSet` called incorrectly');
      }
    })();

    return { count, data };
  }

  protected static getRequestHeaders(contentType?: ContentType) {
    const authToken = cookieManager.authToken;
    return new Headers(
      omitFalsey({
        'Authorization': authToken && `Token ${authToken}`,
        'Content-Type': contentType,
        'X-CSRFToken': cookieManager.csrfToken,
      })
    );
  }

  /**
   * Given an object, creates a FormData object with the object's keys and corresponding values as
   * fields
   *
   * @param {object} formFields: the object to convert to a FormData object
   */
  private static objToFormData(formFields: object) {
    const formData = new FormData();
    Object.entries(formFields).forEach(([fieldName, value]) => {
      if (!_.isUndefined(value)) {
        formData.append(fieldName, serialize(value));
      }
    });
    return formData;

    /**
     * Helper function for serializing the form field values. Uploading JSON values (i.e. objects or
     * arrays) alongside file objects is complicated. It's simpler to simply JSON-serialize them and
     * deserialize them on the backend.
     */
    function serialize(value: any) {
      if (value instanceof File) return value;
      if (_.isObject(value) || _.isArray(value)) return JSON.stringify(value);
      return value;
    }
  }

  /** ========================== File Management =========================== */
  /**
   * Downloads a file and saves it to disk
   *
   * @param {URL} url: the URL to send the request to. May be either a string or a `URLCreator`
   * @param {string} defaultFileName: the name to give the file if no `Content-Disposition` header
   *   is returned with the response
   * @param {ProgressCallback} onProgress: a callback to run when a new chunk is downloaded
   */
  protected static async downloadFile(
    url: URL,
    defaultFileName: string,
    onProgress?: ProgressCallback
  ) {
    const { fileName, blob } = await this.fetchFile(String(url), onProgress);
    this.saveBlob(blob, fileName ?? defaultFileName);
  }

  /**
   * Inspects the `Content-Disposition` header of the response to determine the filename to use for
   * the download
   *
   * @param {Response} response: the `Response` object returned by fetch
   */
  private static getFileNameFromContentDispositionHeader(response: Response): Nullable<string> {
    const contentDisposition = response.headers.get('content-disposition');
    if (!contentDisposition) return null;

    const standardPattern = /filename=(["']?)(.+)\1/i;
    const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

    if (standardPattern.test(contentDisposition)) {
      return contentDisposition.match(standardPattern)![2];
    }

    if (wrongPattern.test(contentDisposition)) {
      return contentDisposition.match(wrongPattern)![1];
    }

    return null;
  }

  /**
   * Saves the Blob to a file
   *
   * @param {Blob} blob: the blob to save
   * @param {string} fileName: the name of the file
   */
  private static saveBlob(blob: Blob, fileName: string) {
    // For other browsers: create a link pointing to the ObjectURL containing the blob.
    const objUrl = window.URL.createObjectURL(blob);

    let link = document.createElement('a');
    link.href = objUrl;
    link.download = fileName;
    link.click();

    // For Firefox it is necessary to delay revoking the ObjectURL.
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }

  /**
   * Fetches the file to download. If provided, the `onProgress` callback is called with every
   * chunk loaded.
   *
   * @param {string} url: the URL of the file
   * @param {ProgressCallback} onProgress: a callback to run when a new chunk is downloaded
   */
  protected static async fetchFile(url: string, onProgress?: ProgressCallback) {
    let requestInit: RequestInit = {
      method: 'GET',
      headers: this.getRequestHeaders('application/json'),
    };

    // Fetch the file
    const response = await fetch(url, requestInit);
    if (!response.ok || !response.body) {
      const responseBody = await response.text();
      throw new Error(responseBody ?? 'Unable to fetch file');
    }

    const reader = response.body.getReader();
    const contentLength = Number(response.headers.get('content-length'));

    let receivedLength = 0;
    const chunks = [];
    while (true) {
      const stream = await reader.read();

      if (stream.done) break;

      chunks.push(stream.value);
      receivedLength += stream.value.length;

      if (typeof onProgress !== 'undefined') {
        onProgress(receivedLength, contentLength);
      }
    }

    const type = response.headers.get('content-type')?.split(';')[0];
    return {
      fileName: this.getFileNameFromContentDispositionHeader(response),
      blob: new Blob(chunks, { type }),
    };
  }
}
