import _ from 'lodash';
import * as React from 'react';

import * as api from 'navigader/api';
import { makeStylesHook } from 'navigader/styles';
import { Maybe, Nullable, OneOrMore } from 'navigader/types';
import { formatters, percentOf } from 'navigader/util';
import { useMergeState } from 'navigader/util/hooks';

import { AlertType } from './Alert';
import { Button, ButtonProps } from './Button';
import { ContactSupport } from './ContactSupport';
import { Flex } from './Flex';
import { AlertSnackbar } from './Snackbar';
import { Tooltip } from './Tooltip';
import { Typography } from './Typography';

/** ============================ Types ===================================== */
type DownloadStatus = 'downloading' | 'error' | 'success';
type FileDisplayProps = {
  hideSize?: boolean;
  file: Nullable<{
    name: string;
    size: number;
  }>;
};

type FileDownloadProps = {
  children?: React.ReactElement;
  disabled?: boolean;
  downloadFn: (cb: api.util.ProgressCallback) => Promise<unknown>;
  tooltipText?: Nullable<React.ReactNode>;
};

export type FileSelectorState = { file: Nullable<File>; name: Nullable<string> };
type FileType = 'csv' | 'xls' | 'xlsx';
type FileSelectorProps = Omit<ButtonProps, 'onChange'> & {
  accept: OneOrMore<FileType>;
  onChange: (f: Nullable<File>, name: Nullable<string>) => void;
  testId?: string;
};

/** ============================ Styles ==================================== */
const useFileSelectorStyles = makeStylesHook(
  () => ({ fileUpload: { display: 'none' } }),
  'FileButton'
);

const useFileDisplayStyles = makeStylesHook(
  (theme) => ({
    fileName: { overflow: 'hidden' },
    fileSize: {
      marginLeft: theme.spacing(2),
    },
  }),
  'FileDisplay'
);

/** ============================ Constants ================================= */
/**
 * Mapping from common file extensions to the appropriate MIME type string to include in the file
 * input's `accept` attribute. The W3C says that "Authors are encouraged to specify both any MIME
 * types and any corresponding extensions when looking for data in a specific format"[1]. MDN
 * maintains a list of important MIME types[2] and IANA maintains the official registry[3].
 *
 *   [1] https://html.spec.whatwg.org/multipage/input.html#attr-input-accept
 *   [2] https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 *   [3] https://www.iana.org/assignments/media-types/media-types.xhtml
 */
const MIME_TYPES: Record<FileType, string> = {
  csv: 'text/csv',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

/** ============================ Components ================================ */
export const FileDisplay: React.FC<FileDisplayProps> = ({ file, hideSize = false }) => {
  const classes = useFileDisplayStyles();

  if (!file) return null;
  const { name, size } = file;

  const fileName = (
    <Typography.LineLimit limit={1} useDiv variant="subtitle2">
      {name}
    </Typography.LineLimit>
  );

  if (hideSize) return fileName;
  return (
    <Flex.Container alignItems="center" justifyContent="flex-start">
      <Flex.Item className={classes.fileName}>{fileName}</Flex.Item>
      <Flex.Item className={classes.fileSize} noShrink>
        <Typography color="textSecondary" variant="body2">
          {formatters.fileSize(size)}
        </Typography>
      </Flex.Item>
    </Flex.Container>
  );
};

export const FileDownload = React.forwardRef<HTMLElement, FileDownloadProps>((props, ref) => {
  const { children, disabled = false, downloadFn, tooltipText = 'Download' } = props;

  // Component state
  const [status, setStatus] = React.useState<DownloadStatus>();
  const [progress, setProgress] = React.useState<number>(0);

  // Get the alert message and type in an IIFE
  const [type, message] = ((): [Maybe<AlertType>, React.ReactNode] => {
    switch (status) {
      case 'downloading':
        return ['info', `Downloading file... ${Math.round(progress)}% complete`];
      case 'error':
        return [
          'error',
          <Typography>
            Download failed! Please try again or <ContactSupport />.
          </Typography>,
        ];
      case 'success':
        return ['success', 'Download complete!'];
      default:
        return [undefined, null];
    }
  })();

  const snackbarProps = {
    msg: message,
    onClose: status === 'downloading' ? undefined : closeSnackbar,
    open: Boolean(status),
    type,
  };

  const anchorProps = { disabled: disabled || Boolean(status), onClick: downloadData };
  const anchor = children ? (
    React.cloneElement(children, { ...children.props, ...anchorProps, ref })
  ) : (
    <Button icon="download" {...anchorProps} ref={ref as React.Ref<HTMLButtonElement>} />
  );

  return (
    <>
      <Tooltip title={tooltipText}>{anchor}</Tooltip>
      <AlertSnackbar {...snackbarProps} />
    </>
  );

  /** ========================== Callbacks ================================= */
  async function downloadData() {
    try {
      setStatus('downloading');
      await downloadFn((progress, total) => setProgress(percentOf(progress, total)));
      setStatus('success');
    } catch (e) {
      setStatus('error');
    }
  }

  function closeSnackbar() {
    setStatus(undefined);
    setProgress(0);
  }
});

export type FileSelectorRef = {
  openFileSelector: () => void;
};

export const FileSelector = Object.assign(
  React.forwardRef(
    (
      { accept, children, onChange, testId, ...rest }: React.PropsWithChildren<FileSelectorProps>,
      ref: React.Ref<FileSelectorRef>
    ) => {
      const classes = useFileSelectorStyles();
      const fileRef = React.useRef<HTMLInputElement>(null);
      const acceptValid = React.useMemo(() => {
        const acceptList = _.isArray(accept) ? accept : [accept];
        return acceptList.flatMap((fileType) => ['.' + fileType, MIME_TYPES[fileType]]).join(',');
      }, [accept]);

      // Enables parent components to pass a ref object and access the `openFileSelector` method
      React.useImperativeHandle(ref, () => ({ openFileSelector }));

      return (
        <>
          <Button color="secondary" {...rest} onClick={openFileSelector}>
            {children}
          </Button>

          {/** Deliberately hidden input. This is controlled programmatically */}
          <input
            accept={acceptValid}
            data-testid={testId}
            className={classes.fileUpload}
            onChange={handleChange}
            ref={fileRef}
            type="file"
          />
        </>
      );

      /** ============================== Callbacks =============================== */
      function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target?.files?.item(0) || null;
        const name = file?.name.split('.csv')[0] || null;
        onChange(file, name);
      }

      function openFileSelector() {
        fileRef.current?.click();
      }
    }
  ),
  {
    useFileSelectorState: () => useMergeState<FileSelectorState>({ file: null, name: null }),
  }
);
