import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { Button, Dialog, Link, List, Menu, StandardDate, TableFactory } from 'navigader/components';
import { SystemProfile } from 'navigader/models';
import { routes, usePushRouter } from 'navigader/routes';
import { slices } from 'navigader/store';
import { formatters } from 'navigader/util';
import { useSnackbar } from 'navigader/util/hooks';

import { CreateSystemProfile, UpdateSystemProfile } from './SystemProfileDialogs';
import { RatesTable, SystemProfileDetails } from './SystemProfileDetails';

/** ============================ Components ================================ */
const Table = TableFactory<SystemProfile>();
export const SystemProfileList = () => {
  const routeTo = usePushRouter();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  // State
  const [systemProfileToDelete, setSystemProfileToDelete] = React.useState<SystemProfile>();
  const [systemProfileToUpdate, setSystemProfileToUpdate] = React.useState<SystemProfile>();
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  return (
    <>
      <Table
        aria-label="system profile table"
        dataFn={(params) => SystemProfile.api.list({ ...params, data_types: 'default' })}
        dataSelector={slices.models.selectSystemProfiles}
        initialSorting={{ dir: 'desc', key: 'created_at' }}
        onFabClick={() => setCreateDialogOpen(true)}
        raised
        title="System Profiles"
      >
        {(systemProfiles, EmptyRow) => (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell sortBy="name">Name</Table.Cell>
                <Table.Cell sortBy="created_at">Created</Table.Cell>
                <Table.Cell>RA Rates</Table.Cell>
                <Table.Cell>Min kW</Table.Cell>
                <Table.Cell>Max kW</Table.Cell>
                <Table.Cell>Average kW</Table.Cell>
                <Table.Cell>Years</Table.Cell>
                <Table.Cell align="right">Menu</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {/** Only renders if there's no data */}
              <EmptyRow>
                None created.
                <Button.Text
                  color="primary"
                  icon="plus"
                  onClick={() => setCreateDialogOpen(true)}
                  size="small"
                >
                  Upload system profile
                </Button.Text>
              </EmptyRow>

              {systemProfiles.map((systemProfile) => (
                <Table.Row key={systemProfile.id}>
                  <Table.Cell>
                    <Link to={routes.cost.system_profiles.profile(systemProfile.id)}>
                      {systemProfile.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <StandardDate date={systemProfile.created_at} />
                  </Table.Cell>
                  <Table.Cell>
                    <RatesTable showHeader={false} systemProfile={systemProfile} />
                  </Table.Cell>
                  <Table.Cell>
                    {formatters.commas(
                      formatters.maxDecimals(systemProfile.data.default?.valueDomain[0], 2)
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatters.commas(
                      formatters.maxDecimals(systemProfile.data.default?.valueDomain[1], 2)
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatters.commas(
                      formatters.maxDecimals(systemProfile.data.default?.average, 2)
                    )}
                  </Table.Cell>
                  <Table.Cell>{systemProfile.data.default?.years.join(', ')}</Table.Cell>
                  <Table.Cell align="right">
                    <Menu
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      icon="verticalDots"
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <List.Item onClick={routeTo.cost.system_profiles.profile(systemProfile)}>
                        <List.Item.Icon icon="launch" />
                        <List.Item.Text>View</List.Item.Text>
                      </List.Item>
                      <List.Item
                        onClick={() => {
                          setSystemProfileToUpdate(systemProfile);
                        }}
                      >
                        <List.Item.Icon icon="upload" />
                        <List.Item.Text>Update</List.Item.Text>
                      </List.Item>
                      <List.Item onClick={() => setSystemProfileToDelete(systemProfile)}>
                        <List.Item.Icon icon="trash" />
                        <List.Item.Text>Delete</List.Item.Text>
                      </List.Item>
                    </Menu>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </>
        )}
      </Table>

      <CreateSystemProfile open={createDialogOpen} onClose={() => setCreateDialogOpen(false)} />
      <UpdateSystemProfile
        systemProfile={systemProfileToUpdate}
        open={!!systemProfileToUpdate}
        onClose={() => setSystemProfileToUpdate(undefined)}
      />
      <Dialog.Delete
        onClose={() => setSystemProfileToDelete(undefined)}
        onClickDelete={deleteSystemProfile}
        title="Delete System Profile"
        message="This will permanently delete the System Profile and cannot be undone."
        open={systemProfileToDelete !== undefined}
      />
    </>
  );

  /** ================================ Callbacks =========================== */
  async function deleteSystemProfile() {
    if (systemProfileToDelete) {
      const result = await SystemProfile.api.destroy(systemProfileToDelete.id);
      if (result.ok) {
        dispatch(slices.models.removeModel(systemProfileToDelete));
        snackbar.success('System profile deleted.');
      } else {
        snackbar.error(result.err);
      }
    }
  }
};

export const SystemProfiles = () => (
  <Switch>
    <Route exact path={routes.cost.system_profiles.base} component={SystemProfileList} />
    <Route path={routes.cost.system_profiles.profile(':id')} component={SystemProfileDetails} />
  </Switch>
);
