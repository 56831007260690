import * as React from 'react';

import {
  Button,
  Dialog,
  FileDisplay,
  FileSelector,
  Grid,
  Progress,
  Select,
  TableRef,
  TextField,
} from 'navigader/components';
import { SystemProfile, RFPPortfolio } from 'navigader/models';
import { makeStylesHook } from 'navigader/styles';
import { CAISORate, Maybe, Nullable } from 'navigader/types';
import { Err, Ok, Result } from 'navigader/util';
import { useCostFunctions, useMergeState, useSnackbar } from 'navigader/util/hooks';

const { useFileSelectorState } = FileSelector;

/** ============================ Types ===================================== */
type DeleteDialogProps = { onClose: () => void; portfolio: Maybe<RFPPortfolio> };
type NewRFPPortfolioDialogProps = {
  onClose: () => void;
  open: boolean;
  tableRef: TableRef<RFPPortfolio>;
};

type CostSelectionsProps = CostFunctionSelections & {
  onChange: (x: Partial<CostFunctionSelections>) => void;
};

type CostFunctionSelections = {
  caisoRate: Nullable<CAISORate>;
  systemProfile: Nullable<SystemProfile>;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  (theme) => ({ fileData: { marginTop: theme.spacing(2) } }),
  'NewRFPPortfolioDialog'
);

/** ============================ Components ================================ */
const CostSelections: React.FC<CostSelectionsProps> = (props) => {
  const { caisoRate, onChange, systemProfile } = props;
  const costFunctions = useCostFunctions();
  const { caisoRates, systemProfiles } = React.useMemo(() => costFunctions, [costFunctions]);
  if (caisoRates.loading || systemProfiles.loading)
    return (
      <Grid.Item span={12}>
        <Progress />
      </Grid.Item>
    );

  return (
    <>
      <Grid.Item span={6}>
        <Select
          label="Procurement Rate"
          minWidth="100%"
          onChange={(selection) => onChange({ caisoRate: selection })}
          options={caisoRates}
          renderOption="name"
          sorted
          value={caisoRate}
        />
      </Grid.Item>
      <Grid.Item span={6}>
        <Select
          label="System Profile"
          minWidth="100%"
          onChange={(selection) => onChange({ systemProfile: selection })}
          options={systemProfiles}
          renderOption="name"
          sorted
          value={systemProfile}
        />
      </Grid.Item>
    </>
  );
};

export const NewRFPPortfolioDialog: React.FC<NewRFPPortfolioDialogProps> = (props) => {
  const { onClose, open, tableRef } = props;
  const classes = useStyles();
  const snackbar = useSnackbar();

  // State variables
  const [{ file, name }, setFileState] = useFileSelectorState();
  const [uploading, setUploading] = React.useState(false);
  const [costFunctionsState, setCostFunctionsState] = useMergeState<CostFunctionSelections>({
    caisoRate: null,
    systemProfile: null,
  });

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="md">
      <Dialog.Title>Upload RFP Response</Dialog.Title>
      <Dialog.Content>
        <Grid>
          <Grid.Item span={6}>
            <FileSelector accept={['xls', 'xlsx']} onChange={handleFileChange}>
              Upload RFP Workbook
            </FileSelector>
            <div className={classes.fileData}>
              <FileDisplay file={file} />
            </div>
          </Grid.Item>
          <Grid.Item span={6}>
            <TextField
              label="Name"
              onChange={(name) => setFileState({ name })}
              value={name || ''}
            />
          </Grid.Item>
          <CostSelections {...costFunctionsState} onChange={setCostFunctionsState} />
        </Grid>
      </Dialog.Content>
      {uploading && <Progress />}
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text
          color="primary"
          disabled={uploading || !validate().ok}
          onClick={uploadRFPResponse}
        >
          Upload
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ========================== Callbacks ================================= */
  function handleFileChange(file: Nullable<File>, name: Nullable<string>) {
    const strippedName = name?.replace(/\.xlsx?$/, '');
    setFileState({ file, name: strippedName });
  }

  async function uploadRFPResponse() {
    const result = validate();
    if (!result.ok) return;

    setUploading(true);
    const apiResult = await RFPPortfolio.api.create(result.val);
    setUploading(false);

    // If the request succeeded, close the dialog and refresh the table. Otherwise show the error
    if (apiResult.ok) {
      onClose();
      tableRef.current?.fetch();
    } else {
      snackbar.error(apiResult.err);
    }
  }

  function validate(): Result<RFPPortfolio.API.CreateParams> {
    if (!file) return Err('file is required');
    if (!name) return Err('name is required');
    const { caisoRate, systemProfile } = costFunctionsState;
    return Ok({
      caisoRate: caisoRate?.id,
      name,
      rfp_response: file,
      systemProfile: systemProfile?.id,
    });
  }
};

export const DeleteRFPPortfolioDialog: React.FC<DeleteDialogProps> = (props) => {
  const { onClose, portfolio } = props;
  const snackbar = useSnackbar();

  return (
    <Dialog open={Boolean(portfolio)} onClose={onClose} aria-labelledby="delete-dialog-title">
      <Dialog.Title id="delete-dialog-title">Delete {portfolio?.name}?</Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          This will permanently delete the portfolio and its results. This action can't be undone.
        </Dialog.ContentText>
      </Dialog.Content>
      <Dialog.Actions>
        <Button.Text onClick={onClose}>Cancel</Button.Text>
        <Button.Text color="primary" onClick={deletePortfolio}>
          Delete
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ========================== Callbacks ================================= */
  async function deletePortfolio() {
    onClose();

    const result = await RFPPortfolio.api.destroy(portfolio!);
    if (result.ok) {
      snackbar.success('Portfolio deleted');
    } else {
      snackbar.error(`Delete failed: ${result.err}`);
    }
  }
};
