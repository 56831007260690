import _ from 'lodash';
import * as React from 'react';
import classNames from 'classnames';

import logo from 'navigader/images/logo.png';
import { makeStylesHook } from 'navigader/styles';

/** ============================ Types ===================================== */
type LogoProps = { width?: number | string };

type GradientProps = {
  className?: string;
  finishPercent?: number;
  invert?: boolean;
  length?: number | string;
  orientation?: 'horizontal' | 'vertical';
  startPercent?: number;
};

const defaultProps: GradientProps = {
  finishPercent: 100,
  invert: false,
  orientation: 'vertical',
  startPercent: 0,
};

/** ============================ Styles ==================================== */
// Styles are exported so they can be used as a mixin elsewhere
const useGradientStyles = makeStylesHook<GradientProps>(
  (theme) => ({
    // The props are default-initialized to `defaultProps` because, when used as a mixin elsewhere in
    // the application, the `Gradient` component's `defaultProps` attribute is not present
    root: (props = defaultProps) => {
      const combinedProps = { ...defaultProps, ...props };
      const startColor = combinedProps.invert ? theme.palette.secondary : theme.palette.primary;
      const endColor = combinedProps.invert ? theme.palette.primary : theme.palette.secondary;
      const lengthDimension = combinedProps.orientation === 'vertical' ? 'height' : 'width';
      const orientation = combinedProps.orientation === 'vertical' ? '0deg' : '90deg';
      return {
        backgroundColor: theme.palette.secondary.main,
        background: `linear-gradient(
        ${orientation},
        ${startColor.main} ${combinedProps.startPercent}%,
        ${endColor.main} ${combinedProps.finishPercent}%
      )`,
        [lengthDimension]: combinedProps.length,
      };
    },
  }),
  'Gradient'
);

const useLogoStyles = makeStylesHook<LogoProps>(
  () => ({
    logo: (props) => ({
      width: props.width,
    }),
  }),
  'Logo'
);

/** ============================ Components ================================ */
const Gradient: React.FC<GradientProps> = ({ className, ...rest }) => {
  const classes = useGradientStyles(rest);
  const childProps = _.omit(rest, 'finishPercent', 'invert', 'orientation', 'startPercent');
  return <div className={classNames(classes.root, className)} {...childProps} />;
};

Gradient.defaultProps = defaultProps;

const Logo: React.FC<LogoProps> = (props) => {
  const classes = useLogoStyles(props);
  return <img src={logo} className={classes.logo} alt="NxT Logo" />;
};

Logo.defaultProps = {
  width: '100%',
};

// Exports are nested under a Branding namespace
export const Branding = { Gradient, Logo, useGradientStyles };
