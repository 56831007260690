import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import * as api from 'navigader/api';
import { GHGRate, SystemProfile } from 'navigader/models';
import { slices, useMemoizedSelector } from 'navigader/store';
import { CAISORate, DataTypeFilters, Loader, Maybe, RatePlan } from 'navigader/types';
import { omitFalsey } from 'navigader/util/omitFalsey';
import { useAsync } from './util';

/** ============================ Types ===================================== */
type UseCostFunctionsParams = Partial<{
  ratePlans: Partial<api.GetRatePlansQueryOptions>;
  caisoRates: DataTypeFilters;
}>;

/** ============================ Rate plans ================================ */
/**
 * Loads the rate plans if they haven't been loaded already. Once loaded they will be added to the
 * store
 */
export function useRatePlans(params?: api.GetRatePlansQueryOptions): Loader<RatePlan[]> {
  const ratePlans = useSelector(slices.models.selectRatePlans);
  const loading = useAsync(async () => api.getRatePlans(params));
  return Object.assign([...ratePlans], { loading });
}

export function useRatePlan(ratePlanId: RatePlan['id'], params?: api.GetRatePlanQueryOptions) {
  const dispatch = useDispatch();

  const storedRatePlans = useSelector(slices.models.selectRatePlans);
  const ratePlan = _.find(storedRatePlans, { id: ratePlanId });

  const loading = useAsync(
    async () => {
      return api.getRatePlan(ratePlanId, params);
    },
    (ratePlan) => dispatch(slices.models.updateModel(ratePlan)),
    [ratePlanId]
  );

  return { loading, ratePlan };
}

/** ============================ GHG Rates ================================= */
/**
 * Loads the GHG rates if they haven't been loaded already. Once loaded they will be added to
 * the store
 */
export function useGhgRates(): Loader<GHGRate[]> {
  const ghgRates = useSelector(slices.models.selectGHGRates);
  const loading = useAsync(async () =>
    GHGRate.api.list({
      data_format: '288',
      include: ['data'],
      page: 0,
      pageSize: 100,
    })
  );

  return Object.assign([...ghgRates], { loading });
}

/** Loads a single GHG rate, by ID or year, if it hasn't been loaded already */
export function useGhgRate(params: { id: GHGRate['id'] } | { year: GHGRate.CSPYear }) {
  const rates = useSelector(slices.models.selectGHGRates);
  const filter: Partial<GHGRate> = 'id' in params ? params : { name: GHGRate.cspName(params.year) };
  const rate = _.find(rates, filter);

  useAsync(async () => {
    // If we've already loaded the rate, we don't need to do so again
    if (rate) return;
    const retrievalParams: GHGRate.API.RetrieveParams = { data_format: '288', include: ['data'] };

    // The API method called depends on whether the `params` argument contains an ID or a name
    if ('id' in params) {
      return GHGRate.api.retrieve(params.id, retrievalParams);
    } else {
      return GHGRate.api.retrieveByYear(params.year, retrievalParams);
    }
  });

  return rate;
}

/** ============================ CAISO Rates =============================== */
/**
 * Loads the CAISO rates if they haven't been loaded already. Once loaded they will be added to
 * the store
 */
export function useCAISORates(filters: DataTypeFilters = {}): Loader<CAISORate[]> {
  const dispatch = useDispatch();

  // Check the store for CAISO rates that match the provided filters
  const caisoRates = useMemoizedSelector(slices.models.selectCAISORatesWithFilters, filters);
  const loading = useAsync(
    async () => {
      // If we've already loaded the rates, we don't need to do so again
      if (caisoRates.length) return;
      return api.getCAISORates({
        ...omitFalsey({
          data_types: filters.data_types,
          period: filters.period,
        }),
        page: 0,
        pageSize: 100,
      });
    },
    ({ data }) => dispatch(slices.models.updateModels(data))
  );

  return Object.assign([...caisoRates], { loading });
}

export function useCAISORate(
  caisoRateId: CAISORate['id'],
  filters: DataTypeFilters = {}
): Maybe<CAISORate> {
  const dispatch = useDispatch();

  // Check the store for CAISO rates that match the provided filters
  const caisoRates = useMemoizedSelector(slices.models.selectCAISORatesWithFilters, filters);
  const caisoRate = _.find(caisoRates, { id: caisoRateId });

  useAsync(
    async () => {
      // If we've already loaded the rates, we don't need to do so again
      if (caisoRate) return;
      return api.getCAISORate(caisoRateId, {
        ...omitFalsey({
          data_types: filters.data_types,
          period: filters.period,
        }),
        page: 0,
        pageSize: 100,
      });
    },
    (data) => dispatch(slices.models.updateModel(data))
  );

  return caisoRate;
}

/** ============================ System profiles =========================== */
export function useSystemProfiles(filters?: DataTypeFilters): Loader<SystemProfile[]> {
  const systemProfiles = useSelector(slices.models.selectSystemProfiles);

  const loading = useAsync(async () =>
    SystemProfile.api.list({
      ...filters,
      page: 0,
      pageSize: 100,
    })
  );

  return Object.assign([...systemProfiles], { loading });
}

export function useSystemProfile(systemProfileId: SystemProfile['id'], filters?: DataTypeFilters) {
  const storedSystemProfiles = useSelector(slices.models.selectSystemProfiles);
  const systemProfile = _.find(storedSystemProfiles, { id: systemProfileId });

  const loading = useAsync(async () => {
    if (systemProfile) return;
    return SystemProfile.api.retrieve(systemProfileId, { ...filters });
  });
  return { loading, systemProfile };
}

/** ============================ Everything ================================ */
/**
 * This is a convenience hook for accessing all kinds of cost functions. In an ideal world there
 * would be a single endpoint for loading them all. In the actual world there isn't, so this call
 * will make multiple API calls, one per cost function category.
 */
export function useCostFunctions(params?: UseCostFunctionsParams) {
  return {
    ghgRates: useGhgRates(),
    caisoRates: useCAISORates(params?.caisoRates),
    ratePlans: useRatePlans({ ...params?.ratePlans, page: 0, pageSize: 100 }),
    systemProfiles: useSystemProfiles(),
  };
}
