import _ from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Card, Dialog, Grid, Progress, Select } from 'navigader/components';
import { makeStylesHook } from 'navigader/styles';
import {
  Maybe,
  SolarArrayType,
  SolarModuleConfigParams,
  SolarConfiguration,
} from 'navigader/types';
import { models, omitFalsey } from 'navigader/util';
import { useMergeState } from 'navigader/util/hooks';

import {
  createDERConfiguration,
  DialogContext,
  DialogProps,
  DialogState,
  NameField,
  NonFieldError,
  RangeField,
  TextField,
} from '../common';

/** ============================ Types ===================================== */
type SolarConfigurationFields = SolarConfiguration['data'] & { name: SolarConfiguration['name'] };
type SolarConfigurationDialogProps = DialogProps<SolarConfiguration>;
type SolarConfigurationDialogState = Omit<DialogState<SolarConfigurationFields>, 'parameters'> & {
  parameters: Array<SolarModuleConfigDialogParams>;
};

type SolarModuleConfigCardProps = DialogState<SolarModuleConfigParams> & {
  setParameter: (parameters: any) => void;
  onDelete?: () => void;
};

type SolarModuleConfigDialogParams = Partial<SolarModuleConfigParams> & {
  errors: DialogState<any>['errors'];
};

// Type representing a fully specified state
type ValidState = Omit<Required<SolarConfigurationDialogState>, 'parameters'> & {
  parameters: Array<Required<SolarModuleConfigDialogParams>>;
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook(
  () => ({ deleteButton: { marginLeft: '-50%' } }),
  'SolarModuleConfigurationCard'
);

/** ============================ Components ================================ */
const SolarModuleConfigurationCard: React.FC<SolarModuleConfigCardProps> = (props) => {
  const { setParameter, onDelete } = props;
  const classes = useStyles();

  return (
    <DialogContext.Provider value={{ setState: setParameter, state: props }}>
      <Grid.Item>
        <Card>
          <Grid>
            <Grid.Item span={5}>
              <TextField field="address" label="ZIP Code" required />
            </Grid.Item>
            <Grid.Item span={6}>
              <Select
                label="Array Type"
                onChange={updateArrayType}
                options={[1, 0, 2]}
                renderOption={models.der.renderSolarArrayType}
                value={props.array_type}
              />
            </Grid.Item>
            <Grid.Item span={1}>
              {onDelete && (
                <Button
                  className={classes.deleteButton}
                  icon="trash"
                  size="small"
                  onClick={onDelete}
                />
              )}
            </Grid.Item>

            <Grid.Item span={5}>
              <RangeField
                range="[0, 360)"
                field="azimuth"
                label={{ text: 'Azimuth Angle', units: 'degrees' }}
                required
                disabled={isSingleAxisTracker(props.array_type)}
              />
            </Grid.Item>
            <Grid.Item span={4}>
              <RangeField
                range="[0, 90]"
                field="tilt"
                label={{ text: 'Tilt Angle', units: 'degrees' }}
                required
                disabled={isSingleAxisTracker(props.array_type)}
              />
            </Grid.Item>
            <Grid.Item span={3}>
              <RangeField
                range="[1, 100]"
                field="weight"
                label={{ text: 'Weight', units: '%' }}
                required
              />
            </Grid.Item>
          </Grid>
        </Card>
      </Grid.Item>
    </DialogContext.Provider>
  );

  /** ========================== Callbacks ================================= */
  /**
   * Updates the `array_type` state variable. Single-axis trackers must have 0 tilt and 180 (or 0)
   * azimuth, so when switching to that array type those changes are forced as well.
   */
  function updateArrayType(arrayType: SolarArrayType) {
    const extraStateUpdates = isSingleAxisTracker(arrayType) ? { tilt: 0, azimuth: 180 } : {};
    setParameter({ array_type: arrayType, ...extraStateUpdates });
  }

  function isSingleAxisTracker(arrayType: Maybe<SolarArrayType>) {
    return arrayType === 2;
  }
};

export const SolarConfigurationDialog: React.FC<SolarConfigurationDialogProps> = (props) => {
  const { closeDialog, open, tableRef } = props;
  const dispatch = useDispatch();

  const initialParams: SolarModuleConfigDialogParams = {
    address: undefined,
    array_type: 1,
    azimuth: 180,
    tilt: 7,
    weight: 100,
    errors: {},
  };

  // State
  const initialState: SolarConfigurationDialogState = {
    parameters: [initialParams],
    creating: false,
    errors: {},
    name: undefined,
  };

  const [state, setState] = useMergeState(initialState);
  const { parameters, creating } = state;
  const canSubmit = stateIsValid(state) && !creating;

  return (
    <Dialog fullWidth open={open} onClose={closeDialog}>
      <Dialog.Title>Create Solar Configuration</Dialog.Title>
      <Dialog.Content>
        <DialogContext.Provider value={{ setState, state }}>
          <Grid>
            <Grid.Item span={12}>
              <NameField />
            </Grid.Item>

            {parameters.map((param, i) => (
              <SolarModuleConfigurationCard
                creating={creating}
                key={i}
                setParameter={setParameter(i)}
                onDelete={i > 0 ? onDelete(i) : undefined}
                {...param}
              />
            ))}
            <NonFieldError />
            <Grid.Item>
              <Button.Fab color="primary" name="plus" onClick={addModule} />
            </Grid.Item>
          </Grid>
        </DialogContext.Provider>
      </Dialog.Content>
      {creating && <Progress />}
      <Dialog.Actions>
        <Button.Text onClick={cancel}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={create}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ========================== Callbacks ================================= */
  function getParameterErrors(params: SolarModuleConfigDialogParams[]) {
    let sum = _.sumBy(params, 'weight');
    return sum !== 100 ? 'All weights must sum up to 100' : undefined;
  }

  function setParameter(index: number) {
    return (newState: any) => {
      const newModule = { ...parameters[index], ...newState };
      const newParams = [...parameters];
      newParams.splice(index, 1, newModule);
      setParameters(newParams);
    };
  }

  function cancel() {
    closeDialog();

    // Reset errors for empty fields so the dialog is cleaner when re-opened
    const emptyFields = getEmptyFields(state);
    setState({
      errors: {
        ...state.errors,
        ...Object.fromEntries(emptyFields.map((field) => [field, undefined])),
      },
    });
  }

  function addModule() {
    setParameters([...parameters, initialParams]);
  }

  function onDelete(index: number) {
    return () => {
      const newParams = state.parameters.filter((_, i) => i !== index);
      setParameters(newParams);
    };
  }

  function setParameters(parameters: SolarModuleConfigDialogParams[]) {
    const errors = { __all__: getParameterErrors(parameters) };
    setState({ parameters, errors: errors });
  }

  async function create() {
    // The "Create" button should not allow submitting unless the state is valid, so this validation
    // is redundant but also solves type-checking issues.
    if (!stateIsValid(state)) return;

    // Attempt to create the configuration
    const success = await createDERConfiguration(
      {
        name: state.name,
        parameters: state.parameters.map((module) => _.omit(module, 'errors')),
        der_type: 'solarpv',
      },
      setState,
      dispatch
    );

    // If the request failed, return
    if (!success) return;

    // Otherwise close the dialog, re-fetch the table and reset the state
    closeDialog();
    tableRef.current?.fetch();
    setState(initialState);
  }

  /** ========================== Helpers =================================== */
  function getEmptyFields(state: SolarConfigurationDialogState) {
    const requiredFields: Array<keyof SolarConfigurationDialogState> = ['name', 'parameters'];
    const requiredParameters: Array<keyof SolarModuleConfigParams> = [
      'address',
      'array_type',
      'azimuth',
      'tilt',
      'weight',
    ];

    const missing = requiredFields.filter((field) => _.isUndefined(state[field]));
    const missingParams = _.uniq(
      _.flatMap(parameters, (param) =>
        requiredParameters.filter((field) => _.isUndefined(param[field]))
      )
    );
    return [...missingParams, ...missing];
  }

  function stateIsValid(state: SolarConfigurationDialogState): state is ValidState {
    const noErrors = _.isEmpty(omitFalsey(state.errors));
    const noParamErrors = _.every(state.parameters, ({ errors }) => _.isEmpty(omitFalsey(errors)));
    const hasRequiredProps = _.isEmpty(getEmptyFields(state));
    return noErrors && noParamErrors && hasRequiredProps;
  }
};
