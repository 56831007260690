import { SystemProfile } from 'navigader/models';
import {
  BatteryConfiguration,
  BatteryStrategy,
  ChargeSource,
  CostFunction,
  DERConfiguration,
  DERStrategy,
  DERStrategyObjective,
  DERType,
  DynamicRestParams,
  EVSEConfiguration,
  EVSEStrategy,
  FuelSwitchingStrategy,
  IdType,
  MonthSeries,
  PaginationQueryParams,
  RawPaginationSet,
  SolarConfiguration,
  SolarStrategy,
} from 'navigader/types';
import {
  appendId,
  beoRoute,
  deleteRequest,
  getRequest,
  parsePaginationSet,
  postRequest,
} from './util';

/** ============================ Types ===================================== */
export type DERQueryParams = PaginationQueryParams &
  DynamicRestParams<'data'> & { der_type?: DERType };

type CreateDERCommonParams<DERObject extends DERConfiguration | DERStrategy> = {
  name: string;
  der_type: DERObject['der_type'];
};

// Configurations
type CreateDERConfigurationCommonParams<Configuration extends DERConfiguration> =
  CreateDERCommonParams<Configuration> & Configuration['data'];

type CreateBatteryConfigurationParams = CreateDERConfigurationCommonParams<BatteryConfiguration>;
type CreateEVSEConfigurationParams = CreateDERConfigurationCommonParams<EVSEConfiguration>;
type CreateSolarConfigurationParams = CreateDERConfigurationCommonParams<SolarConfiguration>;
export type CreateDERConfigurationParams =
  | CreateBatteryConfigurationParams
  | CreateEVSEConfigurationParams
  | CreateSolarConfigurationParams;

// Strategies
type CreateBatteryStrategyParametersParams = CreateDERCommonParams<BatteryStrategy> & {
  charge_source: ChargeSource;
  cost_function: Pick<CostFunction, 'id' | 'object_type'>;
  description?: string;
  discharge_to_grid: boolean;
};
type CreateBatteryStrategy288Params = CreateDERCommonParams<BatteryStrategy> & {
  charge_schedule: File;
  discharge_schedule: File;
  charge_source: ChargeSource;
  description?: string;
  objective?: DERStrategyObjective;
  ra_discharge_schedule?: File;
  ra_discharge_system_profile?: SystemProfile['id'];
  reserve_percentages?: MonthSeries<number>;
};
type CreateBatteryStrategyParams =
  | CreateBatteryStrategyParametersParams
  | CreateBatteryStrategy288Params;
type CreateSolarStrategyParams = CreateDERCommonParams<SolarStrategy> & {
  serviceable_load_ratio: number;
};
type CreateFuelSwitchingStrategyParams = CreateDERCommonParams<FuelSwitchingStrategy> & {
  file: File;
};
type CreateEVSEStrategyParams = CreateDERCommonParams<EVSEStrategy>;
export type CreateDERStrategyParams =
  | CreateBatteryStrategyParams
  | CreateEVSEStrategyParams
  | CreateSolarStrategyParams
  | CreateFuelSwitchingStrategyParams;

/** ============================ API Methods =============================== */
export async function createDERConfiguration(params: CreateDERConfigurationParams) {
  return await postRequest(routes.configuration(), params);
}

export async function createDERStrategy(params: CreateDERStrategyParams) {
  return await postRequest(routes.strategy(), params);
}

export async function deleteDERConfiguration(objectId: IdType) {
  return await deleteRequest(routes.configuration(objectId));
}

export async function deleteDERStrategy(objectId: IdType) {
  return await deleteRequest(routes.strategy(objectId));
}

export async function getDerConfigurations<T extends DERConfiguration>(
  queryParams: DERQueryParams
) {
  const response: RawPaginationSet<{ der_configurations: T[] }> = await getRequest(
    routes.configuration(),
    queryParams
  ).then((res) => res.json());

  return parsePaginationSet(response, 'der_configurations');
}

export async function getDerStrategies<T extends DERStrategy>(queryParams: DERQueryParams) {
  const response: RawPaginationSet<{ der_strategies: T[] }> = await getRequest(
    routes.strategy(),
    queryParams
  ).then((res) => res.json());

  return parsePaginationSet(response, 'der_strategies');
}

/** ============================ Helpers =================================== */
const baseRoute = (rest: string) => beoRoute.v1(`der/${rest}`);
const routes = {
  configuration: appendId(baseRoute('configuration')),
  strategy: appendId(baseRoute('strategy')),
};
