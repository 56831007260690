import deepEqual from 'fast-deep-equal';
import _ from 'lodash';

import * as api from 'navigader/api';
import { RFPPortfolio } from 'navigader/models';
import store, { slices } from 'navigader/store';
import {
  IdType,
  isOriginFile,
  isRFPPortfolio,
  isScenario,
  OriginFile,
  PaginationQueryParams,
  Scenario,
  Without,
} from 'navigader/types';
import { filterClause } from '../../api';

/** ============================ Types ===================================== */
type OriginFileQueryParams = api.OriginFilesQueryParams;
type RFPPortfolioQueryParams = RFPPortfolio.API.ListParams;

type Cleaned<T extends Partial<PaginationQueryParams>> = Without<T, PaginationQueryParams> | null;
type IdSet = Set<IdType>;
type QueryableObject = OriginFile | RFPPortfolio | Scenario;

/** ============================ Map Utils ================================= */
/**
 * Wrapper around the `Map` class. This provides customized equality checking for the object keys
 */
class MeterGroupQueryMap<T extends Partial<PaginationQueryParams>> extends Map<Cleaned<T>, IdSet> {
  /**
   * Removes pagination fields from the query params (if present). These fields are not relevant
   * for polling, and will be provided by the `Poller` class.
   *
   * @param {any} queryParams: the parameters that were used to fetch the meters from the server
   *   initially, possibly including pagination fields.
   */
  clean(queryParams: T): Cleaned<T> {
    const cleaned = _.omit(queryParams, 'page', 'pageSize', 'sortDir', 'sortKey');
    return _.isEmpty(cleaned) ? null : cleaned;
  }

  /**
   * Returns the `IdSet` associated with a group of query parameters, or `undefined` if not found
   *
   * @param {any} queryParams: the group of parameters to index the Map with
   */
  get(queryParams: T): IdSet | undefined {
    for (let [params, idSet] of this.entries()) {
      if (deepEqual(this.clean(queryParams), params)) {
        return idSet;
      }
    }
  }

  /**
   * Returns `true` if there is an existing entry in the map with the exact same query parameters
   *
   * @param {any} queryParams: object of origin file query parameters
   */
  has(queryParams: T): boolean {
    for (let params of this.keys()) {
      if (deepEqual(this.clean(queryParams), params)) {
        return true;
      }
    }

    return false;
  }

  /**
   * Adds a set of IDs to the map. If there is already an entry in the map for the given query
   * params, the IDs will be added to that set; otherwise, a new set will be made
   */
  add(queryParams: T, ids: IdType[]) {
    if (ids.length === 0) return;

    const existingSet = this.get(queryParams);
    if (existingSet) {
      ids.forEach((id) => existingSet.add(id));
    } else {
      this.set(this.clean(queryParams), new Set([...ids]));
    }
  }

  /**
   * Removes an ID from any query parameter sets that include it. If the set is subsequently empty,
   * it too is deleted
   *
   * @param {IdType} id: the ID of the origin file that we wish to stop querying for
   */
  remove(id: IdType) {
    for (let [params, idSet] of this.entries()) {
      if (!idSet.has(id)) continue;

      // Remove the ID from the set. If there are no IDs left in the set, remove it
      idSet.delete(id);
      if (idSet.size === 0) {
        this.delete(params);
      }
    }
  }
}

/** ============================ Polling =================================== */
class Poller {
  private pollInterval: number;
  private pollingIds = {
    originFiles: new MeterGroupQueryMap<OriginFileQueryParams>(),
    rfpPortfolios: new MeterGroupQueryMap<RFPPortfolioQueryParams>(),
    scenarios: new Set<IdType>(),
  };

  public constructor(interval: number) {
    this.pollInterval = window.setInterval(this.poll, interval);
  }

  /**
   * The poller may optionally include query parameters for OriginFiles or RFPPortfolios. Any of the
   * QueryableObjects may be polled for without any query parameters.
   */
  public addMeterGroups(models: OriginFile[], queryParams: OriginFileQueryParams): void;
  public addMeterGroups(models: RFPPortfolio[], queryParams: RFPPortfolioQueryParams): void;
  public addMeterGroups(models: QueryableObject[]): void;

  public addMeterGroups(models: QueryableObject[], queryParams?: any) {
    // Filter for unfinished meter groups
    const unfinished = _.filter(models, (s) => !s.progress.is_complete);
    if (unfinished.length === 0) return;

    // Filter the groups into their specific types
    const originFiles = unfinished.filter(isOriginFile);
    const rfpPortfolios = unfinished.filter(isRFPPortfolio);
    const scenarios = unfinished.filter(isScenario);

    // Add the IDs to the appropriate set
    scenarios.forEach(({ id }) => this.pollingIds.scenarios.add(id));
    this.pollingIds.originFiles.add(queryParams ?? {}, _.map(originFiles, 'id'));
    this.pollingIds.rfpPortfolios.add(queryParams ?? {}, _.map(rfpPortfolios, 'id'));
  }

  /**
   * Clears out any IDs that are currently being polled for. This is useful when the user logs out
   * and is no longer permitted to access the resources
   */
  public reset() {
    this.pollingIds = {
      originFiles: new MeterGroupQueryMap(),
      rfpPortfolios: new MeterGroupQueryMap(),
      scenarios: new Set(),
    };
  }

  /**
   * The actual polling method. This method is called on each polling interval, and will make a
   * request for each of the possible model types that have IDs to poll for.
   */
  private poll = async () => {
    this.pollOriginFiles();
    this.pollRFPPortfolios();
    await this.pollScenarios();
  };

  private pollOriginFiles() {
    const queries = [...this.pollingIds.originFiles.entries()];
    if (queries.length === 0) return;

    queries.forEach(async ([queryOptions, originFileIdSet]) => {
      const originFiles = (
        await api.getOriginFiles({
          ...queryOptions,
          filter: {
            ...queryOptions?.filter,
            id: filterClause.in([...originFileIdSet.values()]),
          },
          page: 0,
          pageSize: 100,
        })
      ).data;

      // Remove finished origin files
      originFiles.forEach((originFile) => {
        if (originFile.progress.is_complete) {
          this.pollingIds.originFiles.remove(originFile.id);
        }
      });

      // Update the store
      store.dispatch(slices.models.updateModels(originFiles));
    });
  }

  private pollRFPPortfolios() {
    const queries = [...this.pollingIds.rfpPortfolios.entries()];
    if (queries.length === 0) return;

    queries.forEach(async ([queryOptions, portfolioIdSet]) => {
      const result = await RFPPortfolio.api.list({
        ...queryOptions,
        filter: {
          ...queryOptions?.filter,
          id: filterClause.in([...portfolioIdSet.values()]),
        },
        page: 0,
        pageSize: 100,
      });

      // Nothing to do if the request failed
      if (!result.ok) return;

      // Remove finished portfolios
      const portfolios = result.val.data;
      portfolios.forEach((portfolio) => {
        if (portfolio.progress.is_complete) {
          this.pollingIds.rfpPortfolios.remove(portfolio.id);
        }
      });

      // Update the store
      store.dispatch(slices.models.updateModels(portfolios));
    });
  }

  private async pollScenarios() {
    const scenarioIds = [...this.pollingIds.scenarios.values()] as IdType[];
    if (scenarioIds.length === 0) return;

    const scenarios = (
      await api.getScenarios({
        filter: { id: filterClause.in(scenarioIds) },
        include: 'report_summary',
        page: 0,
        pageSize: 100,
      })
    ).data;

    // Remove finished scenarios
    scenarios.forEach((scenario) => {
      if (scenario.progress.is_complete) {
        this.pollingIds.scenarios.delete(scenario.id);
      }
    });

    // Update the store
    store.dispatch(slices.models.updateModels(scenarios));
  }
}

export const polling = new Poller(10000);
