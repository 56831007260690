import { RFPPortfolio } from 'navigader/models';
import { slices, useMemoizedSelector } from 'navigader/store';
import { DataTypeFilters, Loader, Maybe } from 'navigader/types';
import { useAsync } from './util';

/**
 * Hook for fetching an RFPPortfolio object
 *
 * @param {string} id: the ID of the portfolio object
 * @param {DataTypeFilters} params: filters to apply
 */
export function useRFPPortfolio(
  id: RFPPortfolio['id'],
  params?: DataTypeFilters
): Loader<{ portfolio: Maybe<RFPPortfolio> }> {
  const portfolio = useMemoizedSelector(slices.models.selectRFPPortfolio, [id, params] as const);
  const loading = useAsync(async () => {
    if (portfolio) return;
    return RFPPortfolio.api.retrieve(id, params);
  });

  return { loading, portfolio };
}
