import _ from 'lodash';
import { DateTime } from 'luxon';

import {
  AbstractMeterGroup,
  AbstractRawMeterGroup,
  CAISORate,
  DataTypeMap,
  DateRange,
  isRawScenarioReportSummary,
  Meter,
  MeterGroup,
  Nullable,
  OriginFile,
  PandasFrame,
  RatePlan,
  RawCAISORate,
  RawDataTypeMap,
  RawDateRange,
  RawMeter,
  RawMeterGroup,
  RawOriginFile,
  RawPandasFrame,
  RawRatePlan,
  RawScenario,
  RawUserProfile,
  Scenario,
  ScenarioReport,
  UserProfile,
} from 'navigader/types';
import { IntervalData, percentOf } from '../data';

/** ============================ Meters ==================================== */
export function parseMeter(meter: RawMeter): Meter {
  return {
    ...meter,
    data: parseDataField(meter.data, meter.metadata.sa_id, 'index', 'kw'),
  };
}

export function serializeMeter(meter: Meter): RawMeter {
  return {
    ...meter,
    data: serializeDataField(meter.data, 'index', 'kw'),
  };
}

/** ============================ Meter Groups ============================== */
// Python string representation of an invalid date
const NOT_A_TIME = 'NaT';

/**
 * Basic parsing function for meter groups. This is leveraged by `parseOriginFile` and
 * `parseScenario`
 *
 * @param {AbstractRawMeterGroup} meterGroup: the raw meter group object to parse
 */
function parseAbstractMeterGroup(meterGroup: AbstractRawMeterGroup): AbstractMeterGroup {
  return {
    ...meterGroup,
    data: parseDataField(meterGroup.data, meterGroup.name, 'index', 'kw'),
    date_range: parseDateRange(meterGroup.date_range),
  };
}

/**
 * Parses a raw meter group into a meter group. This discerns what type of meter group the input
 * object is and calls the appropriate parsing method.
 *
 * @param {RawMeterGroup} rawMeterGroup: the `OriginFile` or `Scenario` object to parse
 */
export function parseMeterGroup(rawMeterGroup: RawMeterGroup): MeterGroup {
  switch (rawMeterGroup.object_type) {
    case 'OriginFile':
      return parseOriginFile(rawMeterGroup);
    case 'Scenario':
      return parseScenario(rawMeterGroup);
  }
}

export function serializeMeterGroup(meterGroup: AbstractMeterGroup): AbstractRawMeterGroup {
  return {
    ...meterGroup,
    data: serializeDataField(meterGroup.data, 'index', 'kw'),
    date_range: serializeDateRange(meterGroup.date_range),
  };
}

/** ============================ Origin Files ============================== */
export function parseOriginFile(rawOriginFile: RawOriginFile): OriginFile {
  const percentComplete =
    rawOriginFile.metadata.expected_meter_count === null
      ? 0
      : percentOf(rawOriginFile.meter_count, rawOriginFile.metadata.expected_meter_count);

  const unchangedFields = _.pick(
    rawOriginFile,
    'has_gas',
    'metadata',
    'object_type',
    'total_therms'
  );

  return {
    ...parseAbstractMeterGroup(rawOriginFile),
    ...unchangedFields,
    progress: {
      is_complete: percentComplete === 100,
      percent_complete: parseFloat(percentComplete.toFixed(1)),
    },
  };
}

export function serializeOriginFile(originFile: OriginFile): RawOriginFile {
  const unchangedFields = _.pick(originFile, 'has_gas', 'metadata', 'object_type', 'total_therms');
  return {
    // Serialize the fields inherited from `MeterGroup`
    ...serializeMeterGroup(originFile),
    ...unchangedFields,
  };
}

/** ============================ Scenarios ================================= */
/**
 * Basic parsing function for converting a RawScenario into a Scenario
 *
 * @param {RawScenario} rawScenario: The raw scenario object to parse
 * @param {RawMeterGroup[]} [rawMeterGroups]: set of raw meter groups from which to draw the one
 *   associated with the scenario
 */
export function parseScenario(
  rawScenario: RawScenario,
  rawMeterGroups?: RawMeterGroup[]
): Scenario {
  const { der_simulation_count, expected_der_simulation_count } = rawScenario;
  const percentComplete =
    expected_der_simulation_count === 0
      ? 0
      : percentOf(der_simulation_count, expected_der_simulation_count);

  const unchangedFields = _.pick(
    rawScenario,
    'cost_functions',
    'der_simulation_count',
    'der_simulations',
    'expected_der_simulation_count',
    'has_intervals_file',
    'metadata',
    'der_stack',
    'object_type'
  );

  // Mix in the meter group
  let meterGroup;
  if (rawScenario.meter_group) {
    const scenarioMeterGroup = _.find(rawMeterGroups, { id: rawScenario.meter_group });
    if (scenarioMeterGroup) {
      meterGroup = parseMeterGroup(scenarioMeterGroup);
    }
  }

  return {
    // Parse the fields inherited from `MeterGroup`
    ...parseAbstractMeterGroup(rawScenario),
    ...unchangedFields,
    meter_group: meterGroup,
    meter_group_id: rawScenario.meter_group,
    progress: {
      is_complete: rawScenario.metadata.is_complete,
      percent_complete: parseFloat(percentComplete.toFixed(1)),
    },
    report: parseReport(rawScenario.report),
    report_summary: parseReportSummary(rawScenario.report_summary),
  };
}

export function parseReport(report: RawScenario['report']): ScenarioReport | undefined {
  if (!report || _.isEmpty(report)) return;
  return _.mapValues(report, (fields) => {
    return {
      ...fields,
      GHGDelta: fields.CSP2022Delta,
      GHGPostDER: fields.CSP2022PostDER,
      GHGPreDER: fields.CSP2022PreDER,
    };
  });
}

/** Adds the generic GHG fields (which are just the Clean System Power 2022 values) */
function parseReportSummary(summary: RawScenario['report_summary']): Scenario['report_summary'] {
  if (!isRawScenarioReportSummary(summary)) return undefined;
  return {
    ...summary,
    GHGDelta: summary.CSP2022Delta,
    GHGPostDER: summary.CSP2022PostDER,
    GHGPreDER: summary.CSP2022PreDER,
  };
}

export function serializeScenario(scenario: Scenario): RawScenario {
  const unchangedFields = _.pick(
    scenario,
    'cost_functions',
    'der_simulation_count',
    'der_simulations',
    'expected_der_simulation_count',
    'has_intervals_file',
    'metadata',
    'der_stack',
    'object_type',
    'report',
    'report_summary'
  );

  return {
    // Serialize the fields inherited from `MeterGroup`
    ...serializeMeterGroup(scenario),
    ...unchangedFields,
    meter_group: scenario.meter_group_id,
  };
}

/** ============================ CAISO Rates =============================== */
export function parseCAISORate(rate: RawCAISORate): CAISORate {
  return {
    ...rate,
    data: parseDataField(rate.data || {}, rate.name, 'start', '$/kwh'),
    date_range: parseDateRange(rate.date_range),

    // This is declared as part of the `RawCAISORate` type but it isn't
    // provided by the backend
    object_type: 'CAISORate',
  };
}

export function serializeCAISORate(rate: CAISORate): RawCAISORate {
  return {
    ...rate,
    data: serializeDataField(rate.data, 'start', '$/kwh'),
    date_range: serializeDateRange(rate.date_range),
  };
}

/** ============================ Rate plans ================================ */
export function parseRatePlan(ratePlan: RawRatePlan): RatePlan {
  return {
    ...ratePlan,
    start_date: parseDateTime(ratePlan.start_date),

    // This field is included in the `RatePlan` type but not provided by the backend
    object_type: 'RatePlan',
  };
}

export function serializeRatePlan(ratePlan: RatePlan): RawRatePlan {
  return { ...ratePlan, start_date: serializeDateTime(ratePlan.start_date) };
}

/** ============================ User items ================================ */
export function parseUserProfile(profile: RawUserProfile): UserProfile {
  return { ...profile, object_type: 'UserProfile' };
}

/** ============================ Pandas ==================================== */
/**
 * Parses a `RawPandasFrame` into a `PandasFrame`, which involves converting the indexed objects
 * into arrays
 *
 * @param {RawPandasFrame} frame: the pandas frame to parse
 */
export function parsePandasFrame<T extends Record<string, any>>(
  frame: RawPandasFrame<T>
): PandasFrame<T> {
  const frameKeys = Object.keys(frame);
  const frameProps = frameKeys.map((key: keyof T) => {
    // Sort the keys numerically, not alphabetically
    const orderedIndices = Object.keys(frame[key]).sort((index1, index2) => {
      const numeric1 = +index1;
      const numeric2 = +index2;

      if (numeric1 < numeric2) return -1;
      if (numeric2 < numeric1) return 1;
      return 0;
    });

    return [key, orderedIndices.map((index) => frame[key][+index])];
  });

  return _.fromPairs(frameProps) as PandasFrame<T>;
}

/**
 * Serializes a `PandasFrame` into a `RawPandasFrame`, typically to save to the store
 *
 * @param {PandasFrame} frame: the parsed Pandas frame to serialize
 */
export function serializePandasFrame<T extends Record<string, any>>(
  frame: PandasFrame<T>
): RawPandasFrame<T> {
  const frameKeys = Object.keys(frame);
  const frameProps = frameKeys.map((key: keyof T) => {
    return [key, frame[key].reduce((frameField, a, i) => ({ ...frameField, [i]: a }), {})];
  });

  return _.fromPairs(frameProps) as RawPandasFrame<T>;
}

/** ============================ Data fields =============================== */
export function parseDataField<T extends string, V extends string>(
  obj: RawDataTypeMap<T, V>,
  name: string,
  timeKey: T,
  valueKey: V
): DataTypeMap {
  return {
    ...obj,
    default:
      obj && obj.default
        ? IntervalData.fromObject({ data: obj.default, timeKey, valueKey, name })
        : undefined,
  };
}

export function serializeDataField<V extends string, T extends string>(
  obj: DataTypeMap,
  timeKey: T,
  valueKey: V
): RawDataTypeMap<T, V> {
  return { ...obj, default: obj.default?.serialize(timeKey, valueKey) };
}

/** ============================ Data fields =============================== */
/**
 * Parses a date string into a luxon DateTime object. Note that using the `Date` constructor is
 * unreliable across browsers: ambiguous date strings that omit timezone info will be interpreted
 * differently by different browsers.
 *
 * @param {Nullable<string>} dateString: the string to parse.
 */
export function parseDateTime(dateString: string): DateTime;
export function parseDateTime(dateString: Nullable<string>): Nullable<DateTime>;
export function parseDateTime(dateString: Nullable<string>): Nullable<DateTime> {
  if (_.isNull(dateString)) return null;
  return DateTime.fromISO(dateString);
}

/**
 * Parses a date string into a `Date` object, using Luxon. Note that using the `Date` constructor is
 * unreliable across browsers: ambiguous date strings that omit timezone info will be interpreted
 * differently by different browsers.
 *
 * TODO: replace this completely with parseLuxonDate-- will require editing a lot of types
 *
 * @param {Nullable<string>} dateString: the string to parse.
 */
export function parseDate(dateString: string): Date;
export function parseDate(dateString: null): null;
export function parseDate(dateString: Nullable<string>): Nullable<Date>;
export function parseDate(dateString: Nullable<string>) {
  return parseDateTime(dateString)?.toJSDate() ?? null;
}

/**
 * Serializes a luxon DateTime object into a string
 *
 * @param {Nullable<DateTime>} date: the luxon DateTime object to serialize
 */
export function serializeDateTime(date: DateTime): string;
export function serializeDateTime(date: Nullable<DateTime>): Nullable<string>;
export function serializeDateTime(date: Nullable<DateTime>) {
  if (_.isNull(date)) return null;
  return serializeDate(date.toJSDate());
}

/**
 * Serializes a date object into a string. This simply wraps the `Date` prototype's `toISOString`
 * method. This method exists for the sake of consistency across the application. Note that we use
 * `toISOString` rather than `toString`, as the former is standardized and the latter varies across
 * browsers
 *
 * @param {Date} date: the date object to serialize
 */
export function serializeDate(date: Date) {
  return date.toISOString();
}

/**
 * Helper function for parsing the `date_range` fields. If either the start or the end of the range
 * is "NaT", returns `null`.
 *
 * @param {RawDateRange} range: a tuple of strings representing the start and end of the range
 */
export function parseDateRange(range: RawDateRange): DateRange {
  if (range === null) return null;
  return range.includes(NOT_A_TIME) ? null : [parseDateTime(range[0]), parseDateTime(range[1])];
}

/**
 * Serializes a `date_range` field. If the range field is `null`, that implies that either the start
 * or the end of the raw date range contained "NaT". We can't know which at this point, so we will
 * serialize both the start and end as "NaT".
 *
 * @param {DateRange} range: the parsed date range object to serialize
 */
export function serializeDateRange(range: DateRange): RawDateRange {
  return range === null
    ? [NOT_A_TIME, NOT_A_TIME]
    : [serializeDateTime(range[0]), serializeDateTime(range[1])];
}
