import { DateTime } from 'luxon';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import * as api from 'navigader/api';
import {
  Grid,
  Typography,
  IntervalGraph,
  MonthSelector,
  Centered,
  Button,
  YearSelectorExclusive,
} from 'navigader/components';
import { makeStylesHook } from 'navigader/styles';
import { CAISORate, MonthIndex } from 'navigader/types';
import { useCAISORate } from 'navigader/util/hooks';

import { UpdateCAISORate } from './CAISORateDialogs';

/** ============================ Styles ===================================== */
const useStyles = makeStylesHook(
  () => ({
    chart: { padding: '1rem' },
    upload_btn: { float: 'right', marginRight: 10 },
    download_btn: { float: 'right' },
  }),
  'CAISORateDetails'
);

/** ============================ Components ================================ */
export const CAISORateDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const caisoRate = useCAISORate(+id, { data_types: ['default'] });
  const [selectedMonth, setSelectedMonth] = React.useState<MonthIndex>(
    DateTime.local().month as MonthIndex
  );
  const [selectedYear, setSelectedYear] = React.useState(caisoRate?.data.default?.years[0]);
  const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);

  // Set selected year if the caisoRate hadn't been loaded yet
  React.useEffect(() => {
    setSelectedYear(caisoRate?.data.default?.years[0]);
  }, [caisoRate]);

  if (!caisoRate) return null;

  return (
    <Grid>
      <Grid.Item span={6}>
        <Typography variant="h6">{caisoRate?.name || ''}</Typography>
      </Grid.Item>
      <Grid.Item span={6}>
        <Button
          icon="download"
          color="primary"
          className={classes.download_btn}
          onClick={() => clickDownload(caisoRate)}
        >
          Download
        </Button>
        <Button
          color="primary"
          icon="upload"
          className={classes.upload_btn}
          onClick={() => setUpdateDialogOpen(true)}
        >
          Update
        </Button>
      </Grid.Item>
      <Grid.Item span={12}>
        {caisoRate.data.default && (
          <Grid>
            <Grid.Item span={12}>
              <Centered>
                <YearSelectorExclusive
                  years={caisoRate.data.default.years}
                  selected={selectedYear}
                  onChange={setSelectedYear}
                />
              </Centered>
            </Grid.Item>
            <Grid.Item span={12}>
              <Centered>
                <MonthSelector exclusive selected={selectedMonth} onChange={setSelectedMonth} />
              </Centered>
            </Grid.Item>
            <Grid.Item span={12}>
              <IntervalGraph
                axisLabel="Procurement Cost"
                className={classes.chart}
                data={caisoRate.data.default}
                month={selectedMonth}
                year={selectedYear}
                units="$"
              />
            </Grid.Item>
          </Grid>
        )}
      </Grid.Item>
      <UpdateCAISORate
        caisoRate={caisoRate}
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      />
    </Grid>
  );

  /* =============================== Callbacks ==============================*/
  async function clickDownload(caisoRate: CAISORate) {
    await api.downloadCAISORate(caisoRate.id);
  }
};
