import * as React from 'react';

import * as api from 'navigader/api';
import { Table } from 'navigader/components';
import { formatters } from 'navigader/util';

import { ConfigurationsTable } from '../common';
import { BatteryConfigurationDialog } from './ConfigurationDialog';

/** ============================ Components ================================ */
export const BatteryConfigurationsTable: React.FC = () => {
  return (
    <ConfigurationsTable
      dataFn={(params) =>
        api.getDerConfigurations({ ...params, der_type: 'battery', include: 'data' })
      }
      Dialog={BatteryConfigurationDialog}
      configurationHeaders={
        <>
          <Table.Cell align="right">Rating (kW)</Table.Cell>
          <Table.Cell align="right">Max Demand Ratio</Table.Cell>
          <Table.Cell align="right">Discharge Duration (Hours)</Table.Cell>
          <Table.Cell align="right">Efficiency</Table.Cell>
        </>
      }
      configurationData={(configuration) => (
        <>
          <Table.Cell align="right">{configuration.data!.rating ?? '--'}</Table.Cell>
          <Table.Cell align="right">
            {formatters.percentage(configuration.data!.max_demand_ratio, 1) ?? '--'}
          </Table.Cell>
          <Table.Cell align="right">{configuration.data!.discharge_duration_hours}</Table.Cell>
          <Table.Cell align="right">
            {formatters.percentage(configuration.data!.efficiency, 1)}
          </Table.Cell>
        </>
      )}
    />
  );
};
