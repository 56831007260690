import _ from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Button, Dialog, Grid, Progress } from 'navigader/components';
import { BatteryConfiguration } from 'navigader/types';
import { omitFalsey } from 'navigader/util';
import { useMergeState } from 'navigader/util/hooks';

import {
  BooleanField,
  createDERConfiguration,
  DialogContext,
  DialogProps,
  DialogState,
  IntegerField,
  NameField,
  NonFieldError,
  PercentageField,
  RangeField,
} from '../common';

/** ============================ Types ===================================== */
type BatteryConfigurationFields = BatteryConfiguration['data'] & { name: string };
type BatteryConfigurationDialogProps = DialogProps<BatteryConfiguration>;
type BatteryConfigurationDialogState = DialogState<BatteryConfigurationFields> & {
  use_rating_configuration: boolean;
};

/** ============================ Components ================================ */
export const BatteryConfigurationDialog: React.FC<BatteryConfigurationDialogProps> = (props) => {
  const { closeDialog, open, tableRef } = props;
  const dispatch = useDispatch();

  const initialState = {
    use_rating_configuration: true,
    creating: false,
    discharge_duration_hours: undefined,
    efficiency: undefined,
    errors: {},
    name: undefined,
    rating: undefined,
  } as BatteryConfigurationDialogState;

  const [state, setState] = useMergeState(initialState);
  const { creating } = state;
  const canSubmit = stateIsValid(state) && !creating;

  return (
    <Dialog fullWidth open={open} onClose={closeDialog}>
      <Dialog.Title>Create Battery Configuration</Dialog.Title>
      <Dialog.Content>
        <DialogContext.Provider value={{ setState, state }}>
          <Grid>
            <Grid.Item span={12}>
              <NameField />
            </Grid.Item>

            <Grid.Item span={6}>
              <IntegerField
                field="discharge_duration_hours"
                label={{ text: 'Discharge Duration', units: 'hours' }}
                range="[0, Infinity]"
                required
              />
            </Grid.Item>

            <Grid.Item span={6}>
              <PercentageField field="efficiency" label="Efficiency" range="(0, 100]" required />
            </Grid.Item>

            <Grid.Item span={6}>
              <BooleanField
                field="use_rating_configuration"
                label="Battery configuration type"
                infoText={`A battery can be configured with a rating, or its rating can be
                  calculated on a meter-by-meter basis as a percentage of the maximum demand.`}
                options={{ n: 'Max Demand Ratio', y: 'Rating' }}
              />
            </Grid.Item>

            <Grid.Item span={6}>
              {state.use_rating_configuration ? (
                <RangeField
                  field="rating"
                  label={{ text: 'Rating', units: 'kW' }}
                  range="[0, Infinity)"
                />
              ) : (
                <PercentageField
                  field="max_demand_ratio"
                  label="Max Demand Ratio"
                  range="(0, 100]"
                />
              )}
            </Grid.Item>

            <NonFieldError />
          </Grid>
        </DialogContext.Provider>
      </Dialog.Content>
      {creating && <Progress />}
      <Dialog.Actions>
        <Button.Text onClick={cancel}>Cancel</Button.Text>
        <Button.Text color="primary" disabled={!canSubmit} onClick={create}>
          Create
        </Button.Text>
      </Dialog.Actions>
    </Dialog>
  );

  /** ========================== Callbacks ================================= */
  function cancel() {
    closeDialog();

    // Reset errors for empty fields so the dialog is cleaner when re-opened
    const emptyFields = getEmptyFields(state);
    setState({
      errors: {
        ...state.errors,
        ...Object.fromEntries(emptyFields.map((field) => [field, undefined])),
      },
    });
  }

  async function create() {
    // The "Create" button should not allow submitting unless the state is valid, so this validation
    // is redundant but also solves type-checking issues.
    if (!stateIsValid(state)) return;

    // Attempt to create the configuration
    const success = await createDERConfiguration(
      {
        ..._.pick(state, 'discharge_duration_hours', 'name'),
        ...getTypeParameters(state),
        der_type: 'battery',
        efficiency: state.efficiency / 100,
      },
      setState,
      dispatch
    );

    // If the request failed, return
    if (!success) return;

    // Otherwise close the dialog, re-fetch the table and reset the state
    closeDialog();
    tableRef.current?.fetch();
    setState(initialState);
  }

  /** ========================== Helpers =================================== */
  function getEmptyFields(state: BatteryConfigurationDialogState) {
    const requiredFields: Array<keyof BatteryConfigurationDialogState> = [
      'discharge_duration_hours',
      'efficiency',
      'name',
    ];

    return requiredFields.filter((field) => _.isUndefined(state[field]));
  }

  /**
   * Compiles the fields for the battery configuration's type parameters. If the
   * `use_rating_configuration` state parameter is true, the value for the maximum demand ratio
   * will be set to undefined, and vice versa.
   *
   * @param {BatteryConfigurationDialogState} state: dialog state
   */
  function getTypeParameters(
    state: BatteryConfigurationDialogState
  ): Pick<BatteryConfigurationFields, 'max_demand_ratio' | 'rating'> {
    const typeFields = ['rating', 'max_demand_ratio'] as const;
    const [active, inactive] = state.use_rating_configuration ? typeFields : _.reverse(typeFields);
    return {
      [active]: currentTypeParameter(state) ?? null,
      [inactive]: null,
    };
  }

  /**
   * Returns the `rating` or `max_demand_ratio` state parameter, depending on the current value of
   * the `use_rating_configuration` parameter. This method also converts the user-provided
   * percentage value for the max demand ratio to our preferred format in the range (0, 1]
   *
   * @param {BatteryConfigurationDialogState} state: dialog state
   */
  function currentTypeParameter(state: BatteryConfigurationDialogState) {
    const { max_demand_ratio, rating, use_rating_configuration } = state;
    if (use_rating_configuration) return rating;
    else if (max_demand_ratio) return max_demand_ratio / 100;
  }

  function stateIsValid(
    state: BatteryConfigurationDialogState
  ): state is Required<BatteryConfigurationDialogState> {
    const noErrors = _.isEmpty(omitFalsey(state.errors));
    const hasRequiredProps = _.isEmpty(getEmptyFields(state));
    const hasTypeParameter = !_.isUndefined(currentTypeParameter(state));
    return noErrors && hasRequiredProps && hasTypeParameter;
  }
};
