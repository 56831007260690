import _ from 'lodash';

import { RFPPortfolio } from 'navigader/models';
import { Falsey, NavigaderObject } from './common';
import { OriginFile, RawOriginFile } from './meter';
import { RawScenario, RawScenarioReportSummary, Scenario } from './scenario';

/** ============================ Navigader Objects ========================= */
function navigaderObjectGuardFactory<T extends NavigaderObject<any>>(objectType: string) {
  return function (obj?: NavigaderObject<any>): obj is T {
    return obj?.object_type === objectType;
  };
}

/** ============================ Meter Groups ============================== */
type Portfolio = RFPPortfolio & RFPPortfolio.Portfolio.Raw;
export const isOriginFile = navigaderObjectGuardFactory<OriginFile & RawOriginFile>('OriginFile');
export const isRFPPortfolio = navigaderObjectGuardFactory<Portfolio>('RFPPortfolio');
export const isScenario = navigaderObjectGuardFactory<Scenario & RawScenario>('Scenario');

/** ============================ Scenarios ================================= */
export function isRawScenarioReportSummary(
  summary: RawScenario['report_summary']
): summary is RawScenarioReportSummary {
  return Boolean(summary && !_.isEmpty(summary));
}

/** ============================ Miscellaneous ============================= */
export function isTruthy<T>(x: T | Falsey): x is T {
  return Boolean(x);
}
