import { useDispatch, useSelector } from 'react-redux';

import * as api from 'navigader/api';
import { Workspace } from 'navigader/models';
import { slices } from 'navigader/store';
import { Loader, Nullable, UserProfile } from 'navigader/types';
import { useAsync } from './util';

/** ============================ Rate plans ================================ */
/** Loads the user profile if it hasn't been loaded already */
export function useUserProfile(): Loader<{ profile: Nullable<UserProfile> }> {
  const dispatch = useDispatch();
  const profile = useSelector(slices.models.selectUserProfile);
  const loading = useAsync(
    async () => {
      // If we've already loaded the profile, we don't need to do so again
      if (profile) return;
      return api.getUserProfile();
    },
    (profile) => dispatch(slices.models.setProfile(profile))
  );

  return { profile, loading };
}

/** Loads the user's workspaces if they haven't been loaded already */
export function useWorkspaces(): Loader<{ workspaces: Workspace[] }> {
  const workspaces = useSelector(slices.models.selectWorkspaces);
  const loading = useAsync(async () => {
    // If we've already loaded the workspaces, we don't need to do so again
    if (workspaces.length) return;
    return Workspace.api.list();
  });

  return { loading, workspaces };
}
