/**
 * Taken with modifications from the `ts-results` package (https://github.com/vultix/ts-results),
 * which does not appear to be actively maintained. See https://github.com/vultix/ts-results/blob/b051dd00d255695dfcc85dd66cdf0e99ea6751f8/src/result.ts
 * for the original code.
 */

interface BaseResult<T, E> {
  // `true` when the result is Ok
  readonly ok: boolean;

  /**
   * Returns the contained `Ok` value, if exists.  Throws an error if not.
   * @param msg the message to throw if no Ok value.
   */
  expect(msg: string): T;

  /**
   * Calls `mapper` if the result is `Ok`, otherwise returns the `Err` value of self. This function
   * can be used for control flow based on `Result` values.
   */
  andThen<T2>(mapper: (val: T) => Ok<T2>): Result<T2, E>;
  andThen<E2>(mapper: (val: T) => Err<E2>): Result<T, E | E2>;
  andThen<T2, E2>(mapper: (val: T) => Result<T2, E2>): Result<T2, E | E2>;
  andThen<T2, E2>(mapper: (val: T) => Result<T2, E2>): Result<T2, E | E2>;

  /**
   * Maps a `Result<T, E>` to `Result<U, E>` by applying a function to a contained `Ok` value,
   * leaving an `Err` value untouched. This function can be used to compose the results of two
   * functions.
   */
  map<U>(mapper: (val: T) => U): Result<U, E>;

  /**
   * Maps a `Result<T, E>` to `Result<T, F>` by applying a function to a contained `Err` value,
   * leaving an `Ok` value untouched. This function can be used to pass through a successful result
   * while handling an error.
   */
  mapErr<F>(mapper: (val: E) => F): Result<T, F>;
}

/** Contains the error value */
class Err<E> implements BaseResult<never, E> {
  readonly ok = false;
  readonly err: E;

  // Contains a stacktrace for the Err
  private readonly _stack: string;

  constructor(err: E) {
    this.err = err;

    const stackLines = new Error().stack!.split('\n').slice(2);
    if (stackLines.length > 0 && stackLines[0].includes('Err')) {
      stackLines.shift();
    }

    this._stack = stackLines.join('\n');
  }

  andThen(op: unknown): Err<E> {
    return this;
  }

  expect(msg: string): never {
    throw new Error(`${msg} - Error: ${toString(this.err)}\n${this._stack}`);
  }

  map(mapper: unknown): Err<E> {
    return this;
  }

  mapErr<E2>(mapper: (err: E) => E2): Err<E2> {
    return new Err(mapper(this.err));
  }

  get stack(): string | undefined {
    return `${this}\n${this._stack}`;
  }

  toString(): string {
    return `Err(${toString(this.err)})`;
  }
}

/** Contains the success value */
class Ok<T> implements BaseResult<T, never>, Iterable<T extends Iterable<infer U> ? U : never> {
  readonly ok = true;
  readonly val: T;

  /** Helper function if you know you have an Ok<T> and T is iterable */
  [Symbol.iterator](): Iterator<T extends Iterable<infer U> ? U : never> {
    const obj = Object(this.val) as Iterable<any>;

    return Symbol.iterator in obj
      ? obj[Symbol.iterator]()
      : {
          next(): IteratorResult<never, never> {
            return { done: true, value: undefined! };
          },
        };
  }

  constructor(val: T) {
    this.val = val;
  }

  andThen<T2>(mapper: (val: T) => Ok<T2>): Ok<T2>;
  andThen<E2>(mapper: (val: T) => Err<E2>): Result<T, E2>;
  andThen<T2, E2>(mapper: (val: T) => Result<T2, E2>): Result<T2, E2>;
  andThen<T2, E2>(mapper: (val: T) => Result<T2, E2>): Result<T2, E2> {
    return mapper(this.val);
  }

  expect(msg: string): T {
    return this.val;
  }

  map<T2>(mapper: (val: T) => T2): Ok<T2> {
    return new Ok(mapper(this.val));
  }

  mapErr(mapper: unknown): Ok<T> {
    return this;
  }

  toString(): string {
    return `Ok(${toString(this.val)})`;
  }
}

export type Result<T, E = string> = Ok<T> | Err<E>;
export type ResultAsync<T, E = string> = Promise<Result<T, E>>;

/** ============================ Helpers ==================================== */
function toString(val: unknown): string {
  let value = String(val);
  if (value === '[object Object]') {
    try {
      value = JSON.stringify(val);
    } catch {}
  }
  return value;
}

/** ============================ Exports ==================================== */
function ErrFn<E>(err: E) {
  return new Err(err);
}

function OkFn<T>(val: T) {
  return new Ok(val);
}

export { ErrFn as Err, OkFn as Ok };
