import * as React from 'react';

import { makeStylesHook } from 'navigader/styles';
import { DERConfiguration, DERStrategy } from 'navigader/types';
import { formatters, models } from 'navigader/util';
import { Card, CardProps } from '../Card';
import { Flex } from '../Flex';
import { Statistic } from '../Statistic';
import { Tooltip } from '../Tooltip';
import { DERIcon } from './DERIcon';

/** ============================ Types ===================================== */
type DERCardProps = {
  CardProps?: CardProps;
  className?: string;
  configurations?: DERConfiguration[];
  strategies?: DERStrategy[];
};

/** ============================ Styles ==================================== */
const useStyles = makeStylesHook<DERCardProps>(
  (theme) => ({
    flexContainer: {
      '& > *': {
        marginRight: theme.spacing(2),
      },
    },
  }),
  'DERCard'
);

/** ============================ Components ================================ */
export const DERCard: React.FC<DERCardProps> = (props) => {
  const { CardProps, className, configurations, strategies } = props;
  const classes = useStyles(props);
  const derTypes = configurations?.map((configuration) => configuration.der_type);

  return (
    <Card {...CardProps} className={className}>
      <Flex.Container className={classes.flexContainer}>
        <Flex.Item>
          <Statistic
            title={formatters.pluralize('Type', derTypes?.length)}
            value={
              derTypes
                ? derTypes.map((derType, idx) => (
                    <Flex.Container key={idx}>
                      <DERIcon type={derType} />
                      {derType}
                    </Flex.Container>
                  ))
                : 'None'
            }
          />
        </Flex.Item>

        <Flex.Item>
          <Statistic
            title={formatters.pluralize('Configuration', configurations?.length)}
            value={configurations?.map((c) => c.name) || 'None'}
          />
        </Flex.Item>

        <Flex.Item>
          <Statistic
            title={formatters.pluralize('Strategy', strategies?.length)}
            value={
              strategies
                ? strategies.map((strategy) => (
                    <Tooltip key={strategy.id} title={models.der.getStrategyDescription(strategy)}>
                      <div>{strategy.name}</div>
                    </Tooltip>
                  ))
                : 'None'
            }
          />
        </Flex.Item>
      </Flex.Container>
    </Card>
  );
};
