import * as React from 'react';

import * as api from 'navigader/api';
import { BooleanIcon, Table } from 'navigader/components';
import { FuelSwitchingConfiguration } from 'navigader/types';

import { ConfigurationsTable } from '../common';

/** ============================ Components ================================ */

export const FuelSwitchingConfigurationsTable: React.FC = () => (
  <ConfigurationsTable
    dataFn={(params) =>
      api.getDerConfigurations({ ...params, der_type: 'fuelswitching', include: 'data' })
    }
    configurationHeaders={
      <>
        <Table.Cell>Water Heating?</Table.Cell>
        <Table.Cell>Space Heating?</Table.Cell>
      </>
    }
    configurationData={(configuration: FuelSwitchingConfiguration) => (
      <>
        <Table.Cell>
          <BooleanIcon value={configuration.data!.water_heating} />
        </Table.Cell>
        <Table.Cell>
          <BooleanIcon value={configuration.data!.space_heating} />
        </Table.Cell>
      </>
    )}
  />
);
