import _ from 'lodash';
import * as React from 'react';

import * as api from 'navigader/api';
import { ColorMap } from 'navigader/styles';
import { Scenario, ScenarioReportFields } from 'navigader/types';
import { formatters } from 'navigader/util';
import { Avatar } from '../Avatar';
import { FileDownload } from '../File';
import { HoverText } from '../HoverText';
import { List } from '../List';
import { Menu } from '../Menu';
import { PrefetchedTable, Table } from '../Table';
import { Tooltip } from '../Tooltip';
import { ImpactColumn } from './ImpactColumn';

/** ============================ Types ===================================== */
type SimulationProp = { simulation: ScenarioReportFields };
type CommonCustomerProps = { colorMap?: ColorMap; updateHover?: (id?: string) => void };
type CustomerRowProps = CommonCustomerProps & {
  scenario: Scenario;
  simulation: ScenarioReportFields;
};

type CustomersTableProps = CommonCustomerProps & {
  className?: string;
  scenarios: Scenario[];
  simulations: ScenarioReportFields[];
};

/** ============================ Components ================================ */
const CustomerRow: React.FC<CustomerRowProps> = ({
  colorMap,
  scenario,
  simulation,
  updateHover = () => {},
}) => (
  <Table.Row
    onMouseEnter={() => updateHover([simulation.ScenarioID, simulation.MeterID].join('__'))}
    onMouseLeave={() => updateHover()}
  >
    {colorMap && (
      <Table.Cell>
        <Tooltip title={scenario.name}>
          <Avatar color={colorMap.getColor(simulation.ScenarioID)} size="small">
            &nbsp;
          </Avatar>
        </Tooltip>
      </Table.Cell>
    )}
    <Table.Cell>{simulation.SAID}</Table.Cell>
    <Table.Cell>{simulation.RatePlan?.at(0)}</Table.Cell>
    <Table.Cell align="right">
      {formatters.commas(formatters.maxDecimals(simulation.UsageDelta, 2)) ?? '-'}
    </Table.Cell>
    <Table.Cell align="right">
      {formatters.commas(formatters.maxDecimals(simulation.GHGDelta, 2)) ?? '-'}
    </Table.Cell>
    <Table.Cell align="right">
      {formatters.dollars(simulation.RASavingsAttributed) ?? '-'}
    </Table.Cell>
    <Table.Cell align="right">
      {formatters.dollars(simulation.ProcurementCostDelta) ?? '-'}
    </Table.Cell>
    <Table.Cell align="right">
      <BillRevenueDelta simulation={simulation} />
    </Table.Cell>
    <Table.Cell align="right">{formatters.dollars(simulation.ExpenseDelta) ?? '-'}</Table.Cell>
    <Table.Cell align="right">
      {formatters.dollars(simulation.ProfitDelta?.at(0)) ?? '-'}
    </Table.Cell>
    <Table.Cell>
      <FileDownload
        disabled={!scenario.has_intervals_file}
        downloadFn={(f) => api.downloadIntervals(scenario, simulation.MeterID, f)}
        tooltipText="Download pre, post and delta intervals"
      />
    </Table.Cell>
  </Table.Row>
);

const HeaderActions: React.FC<{ scenarios: Scenario[] }> = ({ scenarios }) => {
  if (scenarios.length !== 1) return null;
  const scenario = scenarios[0];
  return (
    <Menu
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      icon="download"
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <FileDownload downloadFn={(cb) => api.downloadReport(scenario, cb)} tooltipText={null}>
        <List.Item>
          <List.Item.Text>Download report</List.Item.Text>
        </List.Item>
      </FileDownload>
      <FileDownload
        disabled={!scenario.has_intervals_file}
        downloadFn={(cb) => api.downloadIntervals(scenario, null, cb)}
        tooltipText={null}
      >
        <List.Item>
          <List.Item.Text>Download intervals</List.Item.Text>
        </List.Item>
      </FileDownload>
    </Menu>
  );
};

export const CustomersTable: React.FC<CustomersTableProps> = (props) => {
  const { className, colorMap, scenarios, simulations, updateHover } = props;
  const scenarioMap = _.groupBy(scenarios, 'id');
  return (
    <PrefetchedTable
      aria-label="customers table"
      containerClassName={className}
      // Give the simulation an ID that is a combination of the meter ID and the scenario ID
      data={simulations.map((s) => ({ ...s, id: s.MeterID + s.ScenarioID }))}
      headerActions={<HeaderActions scenarios={scenarios} />}
      initialSorting={{
        dir: 'desc',
        key: 'UsageDelta',
      }}
      raised
      title="Customers"
    >
      {(simulations) => (
        <>
          <Table.Head>
            <Table.Row>
              {colorMap && <Table.Cell />}
              <Table.Cell sortBy="SA_ID">SA ID</Table.Cell>
              <Table.Cell>Rate Plan</Table.Cell>
              <ImpactColumn.Header
                column="Usage Impact"
                info={{
                  measuresImpact: 'in customer electricity usage',
                  negativeMeans: 'electricity consumption from the grid has gone down',
                  positiveMeans: 'electricity consumption from the grid has gone up',
                }}
                sortBy="UsageDelta"
                sortDir="desc"
                units="kWh"
              />
              <ImpactColumn.Header
                column="GHG Impact"
                info={{
                  measuresImpact: 'in GHG emissions',
                  negativeMeans: 'GHG emissions have gone down',
                  positiveMeans: 'GHG emissions have gone up',
                }}
                sortBy="GHGDelta"
                units={
                  <>
                    tCO<sub>2</sub>
                  </>
                }
              />
              <ImpactColumn.Header
                column="RA Impact"
                info={{
                  measuresImpact: 'to resource adequacy requirements',
                  negativeMeans: 'resource adequacy costs have gone down',
                  positiveMeans: 'resource adequacy costs have gone up',
                }}
                sortBy="RASavingsAttributed"
                units="$"
              />
              <ImpactColumn.Header
                column="Procurement Cost"
                info={{
                  measuresImpact: 'to expenses incurred procuring electricity',
                  negativeMeans: 'CCA procurement expenses have gone down',
                  positiveMeans: 'CCA procurement expenses have gone up',
                }}
                sortBy="ProcurementCostDelta"
                units="$"
              />
              <ImpactColumn.Header
                column="Revenue Impact"
                info={{
                  measuresImpact: "to CCA's electricity sales",
                  negativeMeans: 'revenues from electricity sales have gone down',
                  positiveMeans: 'revenues from electricity sales have gone up',
                }}
                sortBy="BillRevenueDelta"
                units="$"
              />
              <ImpactColumn.Header
                column="Expenses Impact"
                info={{
                  measuresImpact:
                    'to overall expenses. Calculated as procurement expenses plus $/kW RA impacts',
                  negativeMeans: 'overall expenses have gone down',
                  positiveMeans: 'overall expenses have gone up',
                }}
                sortBy="ExpenseDelta"
                units="$"
              />
              <ImpactColumn.Header
                column="Profits Impact"
                info={{
                  measuresImpact: 'to overall profits. Calculated as revenues minus expenses',
                  negativeMeans: 'overall profits have gone down',
                  positiveMeans: 'overall profits have gone up',
                }}
                sortBy="ProfitDelta"
                units="$"
              />
              <Table.Cell />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {simulations.map((simulation) => (
              <CustomerRow
                key={simulation.id}
                colorMap={colorMap}
                scenario={getScenarioForSimulation(simulation)}
                simulation={simulation}
                updateHover={updateHover}
              />
            ))}
          </Table.Body>
        </>
      )}
    </PrefetchedTable>
  );

  /** ========================== Helpers =================================== */
  function getScenarioForSimulation(simulation: ScenarioReportFields) {
    return scenarioMap[simulation.ScenarioID][0];
  }
};

const BillRevenueDelta: React.FC<SimulationProp> = ({ simulation }) => {
  const { BillRevenueDelta, RatePlan } = simulation;
  if (!BillRevenueDelta || _.isNull(BillRevenueDelta[0])) return <span>-</span>;

  const hoverText = RatePlan?.length
    ? `Calculated with ${RatePlan[0]}`
    : 'Cost function has been deleted';

  return <HoverText text={hoverText}>{formatters.dollars(BillRevenueDelta[0])}</HoverText>;
};
