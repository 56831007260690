import React from 'react';

import {
  FileDisplay,
  FileSelector,
  FileSelectorState,
  Grid,
  List,
  Menu,
} from 'navigader/components';
import { PartialSetStateFn, useDownloadCallback } from 'navigader/util/hooks';

/** ================================ Types ================================= */
export type DialogProps = {
  open: boolean;
  onClose: () => void;
};

type CostFunctionFileUploadProps = {
  url: (m: 15 | 60) => string;
  setState: PartialSetStateFn<FileSelectorState>;
  state: FileSelectorState;
};

/** ============================== Components ============================== */
export const CostFunctionFileUpload: React.FC<CostFunctionFileUploadProps> = ({
  url,
  setState,
  state,
}) => {
  const downloadTemplate15 = useDownloadCallback(url(15));
  const downloadTemplate60 = useDownloadCallback(url(60));

  const { file } = state;
  return (
    <>
      <Grid.Item span={6}>
        <FileSelector accept="csv" onChange={(file, name) => setState({ file, name })}>
          Select File
        </FileSelector>
      </Grid.Item>
      <Grid.Item span={6}>
        <Menu
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          label="Download Template"
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <List.Item onClick={downloadTemplate15}>
            <List.Item.Text>15 Minute Template</List.Item.Text>
          </List.Item>
          <List.Item onClick={downloadTemplate60}>
            <List.Item.Text>60 Minute Template</List.Item.Text>
          </List.Item>
        </Menu>
      </Grid.Item>

      {file && (
        <Grid.Item span={12}>
          <FileDisplay file={file} />
        </Grid.Item>
      )}
    </>
  );
};
