import _ from 'lodash';

import { CostFunctionSelections } from 'navigader/api';
import store, { slices } from 'navigader/store';
import {
  AbstractRawMeterGroup,
  CostFunctions,
  DataTypeParams,
  DateRange,
  DynamicRestParams,
  PaginationQueryParams,
  ProgressFields,
  RawDataObject,
  RawDateRangeObject,
  RawPaginationSet,
} from 'navigader/types';
import { IntervalData, models, RawIntervalData, ResultAsync, serializers } from 'navigader/util';

import { BaseAPI, ProgressCallback } from './base';
import { GHGRate } from './cost';

/** ============================ Types ===================================== */
export declare namespace RFPPortfolio {
  namespace Reporting {
    type Suffix = 'Delta' | 'PostDER' | 'PreDER';

    // Simple numeric fields
    type NumericReportField = 'Expense' | 'ProcurementCost' | 'RA' | 'RACost';
    type NumericReport = Record<`${NumericReportField}${Suffix}`, number>;

    // Reports with slightly different structures
    type GHGReport = Record<`CSP${GHGRate.CSPYear}${Suffix}`, number>;
    type UsageSuffix = 'Delta' | 'PostBESS' | 'PostSolar' | 'PreSolar';
    type UsageReport = Record<`Usage${UsageSuffix}`, number>;

    // Rate plan-dependent reports
    type ChargeType = 'Demand' | 'Energy' | 'Fixed';
    type NumericArrayField = 'BillRevenue' | `${ChargeType}Charge` | 'Profit';
    type NumericArrayReport = Record<`${NumericArrayField}${Suffix}`, number[]>;
    type RatePlanReport = { RatePlan: string[] };

    // Put them all together
    export type Report = Partial<
      GHGReport & NumericReport & UsageReport & NumericArrayReport & RatePlanReport
    >;
  }

  type IntervalDataColumns = 'post_bess' | 'post_solar' | 'pre_solar';
  type ParsedColumnNames = 'default' | 'preSolar' | 'postSolar';
  type RFPDataObject =
    | { [c in ParsedColumnNames]: undefined }
    | { [c in ParsedColumnNames]: IntervalData };

  export namespace Portfolio {
    export type Raw = Omit<AbstractRawMeterGroup, 'data'> &
      RawDataObject<'timestamp', IntervalDataColumns> & {
        cost_functions: CostFunctions.ObjectShort;
        creator: null;
        object_type: 'RFPPortfolio';
        progress: ProgressFields;
        report: Reporting.Report;
      };
  }

  export namespace Meter {
    export type Raw = RawDateRangeObject & {
      der_specification: null;
      id: string;
      object_type: 'RFPMeter';
      rate_plan_name: string;
      report: Reporting.Report;
      said: string;
      site_name: string;
      total_kwh: number;
    };
  }

  namespace API {
    // There are no deferred fields for RFPPortfolios, but this allows including the `filter` param
    type DeferredParams = DynamicRestParams<never>;

    type CreateResponse = { rfp_portfolio: Portfolio.Raw };
    type CreateParams = Pick<RFPPortfolio, 'name'> &
      Omit<CostFunctionSelections, 'ratePlan'> & { rfp_response: File };

    type ListMetersParams = PaginationQueryParams & { portfolio_id: RFPPortfolio['id'] };
    type ListMetersResponse = RawPaginationSet<{ rfp_meters: Meter.Raw[] }>;
    type ListParams = RetrieveParams & PaginationQueryParams;
    type ListResponse = RawPaginationSet<{ rfp_portfolios: Portfolio.Raw[] }>;
    type RetrieveParams = DataTypeParams & DeferredParams;
    type RetrieveResponse = { rfp_portfolio: Portfolio.Raw };
  }
}

/** ============================ API ======================================= */
class RFPPortfolioAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.rfp.portfolio;

  /** Helper method for handling responses containing a single RFPPortfolio object */
  private static async handleRetrieval(result: ResultAsync<RFPPortfolio.API.RetrieveResponse>) {
    return (await result).map((json) => {
      const portfolio = RFPPortfolio.fromObject(json.rfp_portfolio);
      store.dispatch(slices.models.updateModel(portfolio));
      models.polling.addMeterGroups([portfolio]);
      return portfolio;
    });
  }

  /** ========================== Profile endpoints ========================= */
  static create = (params: RFPPortfolio.API.CreateParams) => {
    const costFunctions = {
      procurement_rate: params.caisoRate,
      system_profile: params.systemProfile,
    };

    delete params.caisoRate;
    delete params.systemProfile;

    return this.handleRetrieval(
      this.postForm<RFPPortfolio.API.CreateResponse>(this.route, {
        ...params,
        cost_functions: costFunctions,
      })
    );
  };

  static destroy = async (portfolio: RFPPortfolio) => {
    // Optimistically delete the portfolio. This will be reverted if the request fails.
    store.dispatch(slices.models.removeModel(portfolio));
    const result = await this.delete(this.route(portfolio.id));

    // Revert the optimistic delete if the request failed
    if (!result.ok) {
      store.dispatch(slices.models.updateModel(portfolio));
    }

    return result;
  };

  static downloadReport = (portfolio: RFPPortfolio, onProgress?: ProgressCallback) => {
    return this.downloadFile(
      this.route(portfolio.id).download,
      `${portfolio.name}-report.csv`,
      onProgress
    );
  };

  static list = async (params: RFPPortfolio.API.ListParams) => {
    const result = await this.get<RFPPortfolio.API.ListResponse>(this.route, params);
    return result.map((json) => {
      // Parse the results
      const paginationSet = this.parsePaginationSet(json, ({ rfp_portfolios: portfolios }) =>
        portfolios.map(RFPPortfolio.fromObject)
      );

      // Add models to the store and return
      store.dispatch(slices.models.updateModels(paginationSet.data));
      models.polling.addMeterGroups(paginationSet.data, params);
      return paginationSet;
    });
  };

  static retrieve = (id: string, params?: RFPPortfolio.API.RetrieveParams) => {
    return this.handleRetrieval(
      this.get<RFPPortfolio.API.RetrieveResponse>(this.route(id), params)
    );
  };
}

class RFPMeterAPI extends BaseAPI {
  private static route = BaseAPI.endpoints.v1.rfp.meter;

  /** ========================== Endpoints ================================== */
  static list = async (params: RFPPortfolio.API.ListMetersParams) => {
    const result = await this.get<RFPPortfolio.API.ListMetersResponse>(this.route, params);
    return result.map((json) => {
      // Parse the results
      const paginationSet = this.parsePaginationSet(json, ({ rfp_meters: meters }) =>
        meters.map(RFPMeter.fromObject)
      );

      // Add models to the store and return
      store.dispatch(slices.models.updateModels(paginationSet.data));
      return paginationSet;
    });
  };
}

/** ============================ Model ===================================== */
export class RFPPortfolio {
  readonly cost_functions: CostFunctions.ObjectShort;
  readonly created_at: string;
  readonly creator: null;
  readonly data: RFPPortfolio.RFPDataObject;
  readonly date_range: DateRange;
  readonly id: string;
  readonly meter_count: number;
  readonly name: string;
  readonly object_type = 'RFPPortfolio';
  readonly progress: ProgressFields;
  readonly report: RFPPortfolio.Reporting.Report;

  static api = RFPPortfolioAPI;
  static fromObject = (raw: RFPPortfolio.Portfolio.Raw) => new RFPPortfolio(raw);

  constructor(raw: RFPPortfolio.Portfolio.Raw) {
    this.cost_functions = raw.cost_functions;
    this.created_at = raw.created_at;
    this.creator = raw.creator;
    this.date_range = serializers.parseDateRange(raw.date_range);
    this.id = raw.id;
    this.meter_count = raw.meter_count;
    this.name = raw.name;
    this.progress = raw.progress;
    this.report = raw.report;

    // TODO: Reimplement IntervalData so it can handle multi-column dataframes out of the box
    const { data } = raw;
    if (data.default) {
      const t = 'timestamp';
      this.data = {
        default: serializers.parseDataField(data, 'Post BESS', t, 'post_bess').default!,
        preSolar: serializers.parseDataField(data, 'Pre Solar', t, 'pre_solar').default!,
        postSolar: serializers.parseDataField(data, 'Post Solar', t, 'post_solar').default!,
      };
    } else {
      this.data = {
        default: undefined,
        preSolar: undefined,
        postSolar: undefined,
      };
    }
  }

  serialize(): RFPPortfolio.Portfolio.Raw {
    const fields = _.pick(this, [
      'cost_functions',
      'created_at',
      'creator',
      'id',
      'meter_count',
      'name',
      'object_type',
      'progress',
      'report',
    ]);

    const data = (() => {
      const { default: postBess, postSolar, preSolar } = this.data;
      if (!postBess) return;

      const intervals = [postBess, postSolar, preSolar];
      const names = ['post_bess', 'post_solar', 'pre_solar'] as const;

      return intervals.reduce((dataObj, interval, index) => {
        const name = names[index];
        return { ...dataObj, ...interval.serialize('timestamp', name) };
      }, {} as RawIntervalData<'timestamp', RFPPortfolio.IntervalDataColumns>);
    })();

    return {
      ...fields,
      data: { default: data },
      date_range: serializers.serializeDateRange(this.date_range),
    };
  }
}

export class RFPMeter {
  readonly date_range: DateRange;
  readonly der_specification: null;
  readonly id: string;
  readonly object_type = 'RFPMeter';
  readonly rate_plan_name: string;
  readonly report: RFPPortfolio.Reporting.Report;
  readonly said: string;
  readonly site_name: string;
  readonly total_kwh: number;

  static api = RFPMeterAPI;
  static fromObject = (raw: RFPPortfolio.Meter.Raw) => new RFPMeter(raw);

  constructor(raw: RFPPortfolio.Meter.Raw) {
    this.date_range = serializers.parseDateRange(raw.date_range);
    this.der_specification = raw.der_specification;
    this.id = raw.id;
    this.rate_plan_name = raw.rate_plan_name;
    this.report = raw.report;
    this.said = raw.said;
    this.site_name = raw.site_name;
    this.total_kwh = raw.total_kwh;
  }

  serialize(): RFPPortfolio.Meter.Raw {
    const fields = _.pick(this, [
      'der_specification',
      'id',
      'object_type',
      'rate_plan_name',
      'report',
      'said',
      'site_name',
      'total_kwh',
    ]);

    return { ...fields, date_range: serializers.serializeDateRange(this.date_range) };
  }
}
