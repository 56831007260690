import * as React from 'react';

import * as api from 'navigader/api';

import { StrategiesTable } from '../common';
import { BatteryStrategyDialog } from './StrategyDialog';
import { Table, BooleanIcon } from 'navigader/components';

/** ============================ Components ================================ */
export const BatteryStrategiesTable: React.FC<{ width: number }> = (props) => (
  <StrategiesTable
    dataFn={(params) => api.getDerStrategies({ ...params, der_type: 'battery', include: 'data' })}
    Dialog={BatteryStrategyDialog}
    strategyData={(strategy) => (
      <Table.Cell align="right">
        <BooleanIcon value={!!strategy.data?.is_dynamic} />
      </Table.Cell>
    )}
    strategyHeaders={<Table.Cell>Dynamic RA?</Table.Cell>}
    width={props.width}
  />
);
